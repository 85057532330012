<template>
  <span>
    <span v-if="!editSelectedWords" class="words-selector font-weight-bold">
      <span
        v-for="(word, index) of phraseWordsDisplay"
        :key="index"
        class="words-selector__word"
      >
        <span
          :class="
            !selectedWordsIndexes.includes(index) &&
            'words-selector__word--unselected'
          "
        >
          {{ word }}
        </span>
      </span>

      <v-btn small icon class="pb-1" @click="editSelectedWords = true">
        <v-icon small>mdi-pen</v-icon>
      </v-btn>
    </span>

    <template v-else>
      <v-btn-toggle
        multiple
        dense
        v-model="selectedWordsIndexes"
        class="words-selector-parts"
      >
        <v-btn
          small
          v-for="(word, index) of phraseWords"
          :key="index"
          class="words-selector-parts__word"
          text
        >
          {{ word }}
        </v-btn>
      </v-btn-toggle>
      <v-btn x-small icon class="ml-2" @click="editSelectedWords = false">
        <v-icon>mdi-check</v-icon>
      </v-btn>
    </template>
  </span>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, Ref, watch } from "vue";

export default defineComponent({
  name: "WordsSelector",
  props: {
    phrase: {
      type: String,
      required: true,
    },
    initialSelection: {
      type: Array as PropType<string[]>,
    },
  },
  setup(props, { emit }) {
    /**
     * Determines if showing the edit values view.
     * @type {Ref<UnwrapRef<boolean>>}
     */
    const editSelectedWords: Ref<boolean> = ref(false);

    /**
     * An array containing the single words contained in the cell value in lowercase format
     * for string matching.
     * @type {ComputedRef<any>}
     */
    const phraseWords = computed(() => {
      return props.phrase.split(" ");
    });

  /**
     * An array containing the single words contained in the cell value in original form.
     * @type {ComputedRef<any>}
     */
    const phraseWordsDisplay = computed(() => {
      return props.phrase.split(" ");
    });

    /**
     * Numeric index of the cell value selected parts.
     * @type {Ref<number[]>}
     */
    const selectedWordsIndexes: Ref<number[]> = ref(
      phraseWords.value.map((word, index) => index)
    );

    if (props.initialSelection !== undefined) {
      selectedWordsIndexes.value = props.initialSelection.map(
        (selectedWord) => {
          return phraseWords.value.indexOf(selectedWord);
        }
      );
    }

    /**
     * Checks if a given word has been selected.
     * @param {string} word
     * @return {boolean}
     */
    const isWordSelected = (word: string) => {
      return selectedWordsIndexes.value.some((selectedWordIndex) => {
        return phraseWords.value[selectedWordIndex] === word;
      });
    };

    /**
     * Array containing the words selected from the current cell value.
     * @type {ComputedRef<string[]>}
     */
    const selectedWords = computed(() => {
      const sortedSelectedWords: string[] = [];

      phraseWords.value.forEach((word) => {
        isWordSelected(word) && sortedSelectedWords.push(word);
      });

      return sortedSelectedWords;
    });

    /**
     * A string containing the words selected from the current cell value.
     * @type {ComputedRef<string>}
     */
    const selectedWordsJoined = computed(() => {
      return selectedWords.value.join(" ");
    });

    watch(selectedWords, (selectedWordsUpdated) => {
      emit("change", selectedWordsUpdated);
    });

    return {
      phraseWords,
      phraseWordsDisplay,
      selectedWordsIndexes,
      selectedWords,
      selectedWordsJoined,
      editSelectedWords,
    };
  },
});
</script>

<style lang="scss">
.words-selector {
  &__word {
    .words-selector__word--unselected {
      text-decoration: line-through;
      color: var(--v-secondary-lighten4);
    }
  }
}
</style>
