import Axios from "axios";
import { API_TOKEN_NAME, AUTH_HEADER_PREFIX } from "@/globals";
import { intercept } from "@/interceptors/intercept";
import { handleErrors } from "@/interceptors/handleErrors";

const token = localStorage.getItem(API_TOKEN_NAME);
if (token)
  Axios.defaults.headers.common["Authorization"] = AUTH_HEADER_PREFIX + token;

Axios.interceptors.request.use(
  function (config) {
    if (config.url?.includes("api/config"))
      config.headers["Authorization"] = "";
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (axiosResponse) => {
    intercept(axiosResponse);

    return axiosResponse;
  },
  (error) => {
    handleErrors(error);

    return true;
  }
);

//#region Annotation
export { autoAnnotateWithTermite } from "./annotation/autoAnnotateWithTermite";
export { getAnnotationSummaries } from "./annotation/getAnnotationSummaries";
export { getAnnotated } from "./annotation/getAnnotated";
export { getAnnotationsForValue } from "./annotation/getAnnotationsForValue";
export { annotateByFilter } from "./annotation/annotateByFilter";
export { verifyAnnotations } from "./annotation/verifyAnnotations";
export { deleteAnnotationByFilter } from "./annotation/deleteAnnotationByFilter";
export { deleteAnnotationById } from "./annotation/deleteAnnotationById";
export { propagateAnnotation } from "./annotation/propagateAnnotation";
export { verifyAndDeleteAnnotations } from "./annotation/verifyAndDeleteAnnotations";
export { getUnannotated } from "./annotation/getUnannotated";
export { verifyAnnotationById } from "./annotation/verifyAnnotationById";
export { annotationAttributesLookup } from "./annotation/annotationAttributesLookup";
export { getVocabLookup } from "./annotation/getVocabLookup";
export { getSourcesLookup } from "./annotation/getSourcesLookup";
export { propagateAndDeleteAnnotation } from "./annotation/propagateAndDeleteAnnotation";
export { verifyAnnotationAndDeleteById } from "./annotation/verifyAnnotationAndDeleteById";
export { updateAnnotationById } from "./annotation/updateAnnotationById";
//#endregion Annotation

//#region Unannotated
export { unannotatedAttributeLookup } from "./annotation/unannotatedAttributeLookup";
//#endregion Unannotated

//#region Datasets
export { getAttributesByDatasetId } from "./datasets/getAttributesByDatasetId";
export { getDatasets, getDatasetsAndPages } from "./datasets/getDatasets";
export { getDatasetById } from "./datasets/getDatasetById";
export { deleteDataset } from "./datasets/deleteDataset";
export { createDataset } from "./datasets/createDataset";
export { uploadDatasetFile } from "./datasets/uploadDatasetFile";
export { updateAttribute } from "./datasets/updateAttribute";
export { deleteAttribute } from "./datasets/deleteAttribute";
export { splitAttribute } from "./datasets/splitAttribute";
export { joinAttribute } from "./datasets/joinAttribute";
export { moveAttribute } from "./datasets/moveAttribute";
export { findAndReplaceValues } from "./datasets/findAndReplaceValues";
export { transformValues } from "./datasets/transformValues";
export { loadExampleDataset } from "./datasets/loadExampleDataset";
export { findDatasetsByName } from "./datasets/findDatasetsByName";
export { findDatasetsByValue } from "./datasets/findDatasetsByValue";
export { getDatasetSummary } from "./datasets/getDatasetSummary";
export { undoLastChange } from "./datasets/undoLastChange";
export { uploadParsedText } from "./datasets/uploadParsedText";
export { changeDatasetGroupOwner } from "./datasets/changeDatasetGroupOwner";
export { changeDatasetOwner } from "./datasets/changeDatasetOwner";
//#endregion Datasets

//#region Notification
export { streamNotifications } from "./notification/notificationsStream";
//#endregion Notification

//#region Table
export { getTable } from "./table-view/getTable";
export { getTableHeaders } from "./table-view/getTableHeaders";
//#endregion Table

//#region User
export { getCurrentUserRole } from "./user/getCurrentUserRole";
export { getUserInfo } from "./user/getUserInfo";
export { login } from "./user/login";
export { verifyToken } from "./user/verifyToken";
export { register } from "./user/register";
export { validateResetToken } from "./user/validateResetToken";
//#endregion User

//#region Value Controller
export { updateValue } from "./value/updateValue";
export { createValue } from "./value/createValue";
//#endregion Value Controller

//#region Usergroups
export { createUserGroup } from "./groups/createUserGroup";
export { getAllGroups } from "./userManagement/getAllGroups";
export { removeGroupMembers } from "./userManagement/removeGroupMembers";
export { addGroupMembers } from "./userManagement/addGroupMembers";
export { getAllUsers } from "./userManagement/getAllUsers";
export { deleteUser } from "./userManagement/deleteUser";
export { deleteGroup } from "./groups/deleteGroup";
export { getGroupInfo } from "./groups/getGroupInfo";
export { getAllGroupMembers } from "./groups/getAllGroupMembers";
export { changeUserPermission } from "./groups/changeUserPermission";
export { getMembersById } from "./groups/getMembersById";
////#endregion Usergroups

//#region Vocabs
export { getAllVocabs } from "./vocabs/getAllVocabs";
export { getAllActiveVocabs } from "./vocabs/getAllActiveVocabs";
export { activateVocab } from "./vocabs/activateVocab";
export { deactivateVocab } from "./vocabs/deactivateVocab";
export { activateAllVocabs } from "./vocabs/activateAllVocabs";
export { deactivateAllVocabs } from "./vocabs/deactivateAllVocabs";
export { lookup } from "./vocabs/lookup";
export { setAnnotationForValue } from "./vocabs/setAnnotationForValue";
//#endregion Vocabs

//#region Exports
export { exportDataset } from "./export/exportDataset";
export { downloadDataset } from "./export/downloadDataset";
export { exportMapping } from "./export/exportMapping";
export { downloadMapping } from "./export/downloadMapping";
//#endregion Exports


//#region mappings
export { createMapping } from "./mappings/createMapping";
export { startMappingJob } from "./mappings/startMappingJob";
export { deleteMapping } from "./mappings/deleteMapping";
export { getMappings } from "./mappings/getMappings";
export { getMappingById } from "./mappings/getMappingById";
export { getMappingResults } from "./mappings/getMappingResults";
export { deleteMappingResultById } from "./mappings/deleteMappingResultById";
export { verifyMappings } from "./mappings/verifyMappings";
export { generateLinkedTerms } from "./mappings/generateLinkedTerms";
export { updateMappingConfiguration } from "./mappings/updateMappingConfiguration";
export { recommendMediatingOntology } from "./mappings/recommendMediatingOntology";
//#endregion mappings

export { getProjectInfo } from "./getProjectInfo";

//#region valuesets
export { findAnnotationExportFieldValueset } from "./valuesets/findAnnotationExportFieldValueset";
//#region valuesets

//#region historySets
export { getHistorySet } from "./history/getHistorySet";
//#region historySets