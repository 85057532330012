import Axios from "axios";
import { Dataset, DatasetParams } from "@/ts/interfaces";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const datasetURL = `${baseUrl}/datasets`;

type DatasetResponse = Promise<Dataset[] | never[]>;

type DatasetPagesReponse = Promise<
  | { number: number; size: number; totalElements: number; totalPages: number }
  | never
>;

/**
 * Retrieves datasets from the API dataset controller.
 * @param {Number} page Page you want to retrieve
 * @param {Number} size Number of records per page
 * @param {('asc' | 'desc')} sort Sorting criteria in the format
 * @returns Promise<(Array<Dataset> | undefined)>
 */
const getDatasets = async ({
  sharing_mode,
  page,
  sort,
  size,
  group_id,
}: DatasetParams): DatasetResponse => {
  const response = await Axios.get(datasetURL, {
    params: { sharing_mode, page, size, sort, group_id },
  });
  if (!response.data._embedded || !response.data._embedded.datasets) return [];

  return response.data._embedded.datasets;
};

const getDatasetsAndPages = async ({
  sharing_mode,
  page,
  sort,
  size,
  group_id,
}: DatasetParams) => {
  const response = await Axios.get(datasetURL, {
    params: { sharing_mode, page, size, sort, group_id },
  });
  if (!response.data._embedded || !response.data._embedded.datasets) return [];

  return response.data;
};

/**
 * [getDatasetsPages description]
 *
 * @param {Number} page Page you want to retrieve
 * @param {Number} size Number of records per page
 * @param {('asc' | 'desc')} sort Sorting criteria in the format
 *
 * @return  {[type]}                [return description]
 */
const getDatasetsPages = async ({
  sharing_mode,
  page,
  sort,
  size,
}: DatasetParams): DatasetPagesReponse => {
  const response = await Axios.get(datasetURL, {
    params: { sharing_mode, page, size, sort },
  });

  return response.data.page;
};

/**
 * [deleteDataset description]
 *
 * @param   {number}  id  [id description]
 *
 * @return  {[type]}      [return description]
 */
const deleteDataset = async (id: number): Promise<Response> => {
  const response = await Axios.delete(`${datasetURL}/${id}`);
  return response.data;
};

export { getDatasets, getDatasetsPages, deleteDataset, getDatasetsAndPages };
