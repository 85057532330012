import { Observer } from "@/ts/types";
import { MessageEventEncoded } from "@/ts/interfaces";
import { Ref } from "vue";

/**
 * Marks a mapping as loaded.
 * @param {Record<string, unknown>} args
 * @param {MessageEventEncoded} args.event
 * @param {number} args.mappingId
 * @param {Ref<boolean>} args.loaded
 * @return {boolean} true in order to execute the next action
 */
export const markMappingLoaded: Observer = (args) => {
  const event = args.event as MessageEventEncoded;
  const mappingId = args.mappingId as number;
  const loaded = args.loaded as Ref<boolean>;
  const eventMatasetId =
    event.data.job && event.data.job.dataset && event.data.job.dataset.id;

  if (eventMatasetId === mappingId) loaded.value = true;

  return true;
};
