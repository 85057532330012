import Axios from "axios";
import qs from "qs";
import { components, operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const annotationsUrl = `${baseUrl}/datasets`;

/**
 * Get annotated values for a specified dataset.
 * @param {operations["getAnnotated"]["parameters"]["path"]["id"]} datasetId
 * @param {operations["getAnnotated"]["parameters"]["query"]} params
 * @return {Promise<components["schemas"]["CollectionModelAnnotationByValue"]>}
 */
const getAnnotated = async (
  datasetId: operations["getAnnotated"]["parameters"]["path"]["id"],
  params: operations["getAnnotated"]["parameters"]["query"] | undefined
): Promise<components["schemas"]["CollectionModelAnnotationByValue"]> => {
  const queryString = qs.stringify(params, { arrayFormat: "comma" });
  const url = `${annotationsUrl}/${datasetId}/annotated?${queryString}`;

  const response = await Axios.get(url);

  return response.data || [];
};

export { getAnnotated };
