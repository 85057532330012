import { Observer } from "@/ts/types";
import { useNotifications } from "@/compositions";
import { downloadDataset } from "@/api";
import { MessageEventEncoded } from "@/ts/interfaces";
import { components } from "@/ts/interfaces/ApiSchemas";

/**
 * Pushes a notification to notify that dataset export has been completed.
 * @param {Record<string, unknown>} args
 * @param {MessageEventEncoded} args.event
 * @return {boolean}
 */
export const notifyDataExportComplete: Observer = (args) => {
  const event = args.event as MessageEventEncoded;
  const { pushNotification } = useNotifications();
  const data = event.data as Required<MessageEventEncoded["data"]>;
  const dataset = data.job.dataset as Required<
    components["schemas"]["Dataset"]
  >;

  pushNotification({
    title: data.title,
    text: `${dataset.title} exporting is complete and will begin downloading...`,
    type: "success",
    timeout: true,
  });

  void downloadDataset(
    { id: dataset.id, job_id: data.job.jobId as number }
  );

  return true;
};
