import Axios from "axios";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");
const endpoint = `${baseUrl}/datasets/loadexample`;

/**
 * Requests the server to load an example dataset.
 * @return {Promise<void>}
 */
const loadExampleDataset = async () => {
  await Axios.post(endpoint);
};

export { loadExampleDataset };
