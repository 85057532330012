import { MiddlewareContext } from "@/ts/interfaces";

/**
 * Runs subsequent middlewares contained in the route stack.
 * @param {Object} context The context object created in the router though `beforeEach` method.
 * @param {Array} middleware The array defined on the meta field of the route containing all middlewares.
 * @param {Number} index The index of the current ideration through the `middleware` array.
 *
 * @see https://blog.logrocket.com/vue-middleware-pipelines/
 * @returns Route|Function
 */
export const middlewarePipeline = (
  context: MiddlewareContext,
  middleware: Array<(context: MiddlewareContext) => void>,
  index: number
) => {
  const nextMiddleware = middleware[index];

  if (!nextMiddleware) {
    return context.next;
  }

  return () => {
    const nextPipeline = middlewarePipeline(context, middleware, index + 1);

    nextMiddleware({ ...context, next: nextPipeline });
  };
};
