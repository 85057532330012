import Axios from "axios";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const annotationsUrl = `${baseUrl}/datasets`;

type VerifyAnnotationByIdPathParams =
  operations["verifyAllByFilterDeletingOthers"]["parameters"]["path"];
type VerifyAnnotationByIdQueryParams =
  operations["verifyAllByFilterDeletingOthers"]["parameters"]["query"];
type VerifyAnnotationResponse =
  operations["verifyAllByFilterDeletingOthers"]["responses"]["200"]["content"]["application/hal+json"];

/**
 * Retrieves annotations for a single value.
 * @param {number} datasetId - The id of the dataset that the change needs to occur on
 * @param {verifyAnnotationParams} params - The params needed for the request
 * @return {verifyAnnotationResponse}
 */
export const verifyAndDeleteAnnotations = async (
  pathParams: VerifyAnnotationByIdPathParams,
  queryParams: VerifyAnnotationByIdQueryParams
): Promise<VerifyAnnotationResponse> => {
  const { id: dataset_id } = pathParams;

  const url = `${annotationsUrl}/${dataset_id}/verifyAndDeleteAnnotations`;

  const response = await Axios.post(url, null, {
    params: queryParams,
  });

  return response.data as VerifyAnnotationResponse;
};
