import Axios from "axios";
import { components } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");

type SplitAttributeRequest = components["schemas"]["SplitAttributeRequest"];

/**
 * Split an attribute/column into multiple attributes/columns
 * @param {number} datasetId
 * @param {number | undefined} attributeId
 * @param {number} delimiter
 * @param {boolean} regex
 * @param {number} maxColumns
 * @param {boolean} removeOriginalColumn
 * @return {Promise<void>}
 */
const splitAttribute = async (
  datasetId: number,
  { attributeId, delimiter, regex, maxColumns, removeOriginalColumn }: SplitAttributeRequest
): Promise<boolean> => {
  const endpoint = `${baseUrl}/datasets/${datasetId}/attributes/split`;

  const response = await Axios.put(endpoint, {
    attributeId,
    delimiter,
    regex,
    maxColumns,
    removeOriginalColumn,
  });

  return response.status == 200;
};

export { splitAttribute };
