<template>
  <div v-if="showToolbox" ref="toolboxRef" class="main-toolbox">
    <v-navigation-drawer
      permanent
      app
      right
      mini-variant
      mini-variant-width="56"
      :clipped="$vuetify.breakpoint.lgAndUp"
      class="main-toolbox-minimized overflow-visible"
    >
      <v-list dense>
        <template v-if="!selectionIsEmpty">
          <template v-for="(panel, index) in panels">
            <v-list-item
              link
              class="toolbox-minimized-item"
              :key="index"
              v-if="panelHasContent(panel.selectionType)"
            >
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="toolbox-minimized-item__button"
                    @click="toggleToolboxCollapsed"
                  >
                    <v-icon v-bind="attrs" v-on="on">{{ panel.icon }}</v-icon>
                  </div>
                </template>
                {{ panel.title }}
              </v-tooltip>
            </v-list-item>
          </template>
        </template>

        <v-list-item v-else link>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="toggleToolboxCollapsed" v-bind="attrs" v-on="on"
                >{ mdi-set-none }</v-icon
              >
            </template>
            Nothing selected
          </v-tooltip>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer
      :value="!toolboxCollapsed"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      right
      class="main-toolbox-expanded overflow-visible"
    >
      <div
        class="main-toolbox-toggle"
        v-if="!toolboxCollapsed"
        @click="toggleToolboxCollapsed"
      >
        <v-icon small>{ mdi-arrow-collapse-right }</v-icon>
      </div>
      <template v-if="!selectionIsEmpty">
        <template v-for="(panel, index) in panels">
          <toolbox-panel
            :title="panel.title"
            :key="index"
            v-if="panelHasContent(panel.selectionType)"
          >
            <template v-slot:content>
              <component
                :is="panel.component.name"
                :panel-data="panel.data"
                :type="panel.selectionType"
              />
            </template>
          </toolbox-panel>
        </template>
      </template>

      <div v-else class="main-toolbox-empty-message">
        <v-icon large>mdi-set-none</v-icon><br />
        Select a cell or a column to start using data annotation tools
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, watch } from "vue";

import { sortBy } from "lodash";
import ToolboxPanel from "@/components/layout/MainToolbox/ToolboxPanel.vue";
import AnnotationsPanel from "@/components/layout/MainToolbox/AnnotationsPanel.vue";
import OntologySelectionPanel from "@/components/layout/MainToolbox/OntologySelectionPanel.vue";
import OntologyConfigurationPanel from "@/components/layout/MainToolbox/OntologyConfigurationPanel.vue";
import { TableSelection } from "@/ts/interfaces";
import {
  useAttributes,
  useObservers,
  useSelection,
  useToolbox,
  useToolboxPanels,
  useVocabs,
} from "@/compositions";
import { getAnnotationsForValue } from "@/api";
import { SelectionTypes } from "@/ts/types";
import { getVocabsLabelsByIds } from "@/utils";

export default defineComponent({
  name: "MainToolbox",
  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    ToolboxPanel,
    AnnotationsPanel,
    OntologySelectionPanel,
    OntologyConfigurationPanel,
  },
  setup() {
    const selector = useSelection();
    const selectionIsEmpty = selector.isSelectionEmpty;
    const { toolboxCollapsed, showToolbox, toggleToolboxCollapsed } =
      useToolbox();

    const panels = useToolboxPanels().getPanels.value;

    /**
     * Builds the panel data for a cell selection.
     * @param {TableSelection} selection
     * @return {Promise<void|undefined>}
     */
    const buildCellPanelData = async (
      selection: TableSelection
    ): Promise<void | undefined> => {
      if (!selection.data.selectedCell?.id || !selection.data.selectedCell?.label) {
        useToolboxPanels().resetAnnotationsPanelData();
        return;
      }

      const annotations = await getAnnotationsForValue({
        id: selection.datasetId,
        value_id: selection.data.selectedCell.id,
      });

      const allAnnotationIds: number[] = [];

      const unsortedLabels = annotations.map((annotation) => {
        const id = annotation.id as number;
        allAnnotationIds.push(id);

        return annotation.hit?.label
          ? {
              annotation,
              allAnnotationIds,
              annotationType: annotation.type,
              annotationId: annotation.id,
              label: annotation.hit.label,
              primaryId: annotation.hit.primaryId,
              type: annotation.type,
              vocabId: annotation.hit.termiteVocabId,
              matchingName: annotation.hit.matchingName
            }
          : {};
      });

      const labels = sortBy(unsortedLabels, [(o) => o.annotationId]);

      useToolboxPanels().setAnnotationPanelData([
        {
          label: selection.data.selectedCell!.label!,
          selectionId: selection.data.selectedCell.id,
          labels,
        },
      ]);
    };

    /**
     * Builds the data to populate the attributes/columns panel.
     */
    const buildOntologySelectionPanelsData = () => {
      const attributesIds = selector.columnIds?.value;
      const attributes = useAttributes().datasetAttributes.value;

      if (!attributesIds.length) useToolboxPanels().resetAnnotationsPanelData();

      useToolboxPanels().resetAttributesPanelData();

      attributes.filter((attribute) => {
        if (
          !attribute.id ||
          !attributesIds ||
          !attributesIds.includes(attribute.id)
        )
          return;

        const activeVocabs = useVocabs().getActiveVocabs();
        const vocabsLabels = attribute.termiteConfig?.vocabIds
          ? getVocabsLabelsByIds(activeVocabs, attribute.termiteConfig.vocabIds)
          : [];

        useToolboxPanels().addAttributeToPanel({
          value: attribute.name!,
          attributeId: attribute.id,
          labels: vocabsLabels,
          sortOrder: attribute.columnIndex,
        });
      });
    };

    /**
     * Builds the panels data depending on the panel type.
     * @param {TableSelection | undefined} selection
     * @return {Promise<void>}
     */
    const buildPanelsData = async (
      selection: TableSelection | undefined
    ): Promise<void> => {
      if (!selection) {
        useToolboxPanels().resetAttributesPanelData();
        useToolboxPanels().resetAnnotationsPanelData();
        return;
      }

      switch (selection.type) {
        case "column":
          buildOntologySelectionPanelsData();
          break;
        case "cell":
          await buildCellPanelData(selection);
          break;
      }
    };

    /**
     * Checks if the current panel has content to display.
     * @param {SelectionTypes} selectionType
     * @return {boolean}
     */
    const panelHasContent = (selectionType: SelectionTypes) => {
      return selectionType === selector.selectionType.value;
    };

    /**
     * Registers an observer against the 'ANNOTATION_VERIFIED' event.
     * The event is triggered when an annotation has been verified.
     * @link /src/observers/rebuildPanelData.ts - The observer method
     * @link /src/components/layout/MainToolbox/AnnotationsPanelActions.vue - The ANNOTATION_VERIFIED event
     */
    const listenForVerifiedAnnotations = () => {
      useObservers().registerObserver("ANNOTATION_VERIFIED", {
        observer: "REBUILD_PANEL_DATA",
        args: { buildPanelsData },
      });
    };

    watch(selector.getSelection, () => {
      selector.getSelection.value &&
        buildPanelsData(selector.getSelection.value);
    });

    onBeforeMount(() => {
      listenForVerifiedAnnotations();

      useObservers().registerObserverMultiple(
        ["CONTEXT_CHANGED", "SELECTION_CHANGED", "ROUTE_CHANGED"],
        {
          observer: "SHOW_HIDE_TOOLBOX",
          args: { showToolbox },
        }
      );
    });

    return {
      panels,
      toolboxCollapsed,
      toggleToolboxCollapsed,
      panelHasContent,
      showToolbox,
      selectionIsEmpty,
    };
  },
});
</script>

<style scoped lang="scss">
.toolbox-minimized-item {
  padding: 0;

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;

    button {
      &:before {
        width: 0 !important;
        height: 0 !important;
      }
    }
  }
}
.main-toolbox-toggle {
  position: absolute;
  left: -40px;
  top: 0;
  width: 40px;
  height: 40px;
  padding: 5px 10px;
  display: block;
  background-color: var(--v-background-base);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 0 0 10px;
  cursor: pointer;
}
.main-toolbox-expanded {
  background-color: var(--v-background-base);
}

.main-toolbox-empty-message {
  padding: 5px 10px;
  text-align: center;
}
</style>
