import Axios from "axios";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");
const registerUrl = `${baseUrl}/users/sign-up`;

interface RegisteredUser {
  title?: string;
  status?: number;
  detail?: string;
  id?: number;
  username?: string;
}
/**
 * Sends a request to the server to create a user, conflicts currently are an error
 * @param firstName
 * @param lastName
 * @param {string} username
 * @param {string} password
 * @param {string} email
 * @returns {Promise<RegisteredUser>}
 */
export const register = async (
  firstName: string,
  lastName: string,
  username: string,
  password: string,
  email: string
): Promise<RegisteredUser> => {
  const { data, statusText } = await Axios.post(registerUrl, {
    firstName,
    lastName,
    username,
    email,
    password,
  });

  return data;
};
