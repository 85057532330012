import Axios from "axios";
import { serverUrl } from "@/utils";

const apiUrl = serverUrl("api");
const registerUrl = `${apiUrl}/password/verify`;

export const validateResetToken = async (
  token: string | (string | null)[]
): Promise<unknown> => {
  try {
    const url = `${registerUrl}?token=${token}`;
    const response = await Axios.get(url);

    return response.status === 200;
  } catch (error) {
    return error;
  }
};
