import Axios from "axios";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const endpoint = `${baseUrl}/datasets`;

type UpdateValueParams = {
  datasetId: number;
  valueId: number;
  value: string;
};

type UpdateValueResponse = {
  id: number;
  value: string;
  rowId: number;
};

/**
 * Requests to update a single cell value.
 * @param {number} datasetId The dataset id
 * @param {number} attributeId The cell id
 * @param {string} value The new value
 * @returns {Promise<UpdateValueResponse>}
 */
export const updateValue = async ({
  datasetId,
  valueId,
  value,
}: UpdateValueParams): Promise<UpdateValueResponse> => {
  const url = `${endpoint}/${datasetId}/value/${valueId}`;
  const response = await Axios.put(url, { value });

  return response.data;
};
