import { Observer } from "@/ts/types";
import { useNotifications } from "@/compositions";
import { MessageEventEncoded } from "@/ts/interfaces";

/**
 * Pushes a notification to notify that dataset export has started.
 * @param {Record<string, unknown>} args
 * @param {MessageEventEncoded} args.event
 * @return {boolean}
 */
export const notifyDataExportStarted: Observer = (args) => {
  const event = args.event as MessageEventEncoded;
  const { pushNotification } = useNotifications();

  pushNotification({
    title: event.data.title,
    text: "Data export is now in progress...",
    type: "info",
    timeout: true,
  });

  return true;
};
