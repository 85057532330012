import Axios from "axios";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");
const userGroupURL = `${baseUrl}/groups`;

/**
 * Creates a user group by calling the creat group api
 * @param {String} userGroupName The name of the group you want to create
 */
const createUserGroup = async (name: string) => {
  const response = await Axios.post(userGroupURL, { name });

  if (!response.data) return;

  return response.data;
};

export { createUserGroup };
