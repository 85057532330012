import Axios from "axios";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const mappingURL = `${baseUrl}/mappings`;

/**
 * [deleteMapping description]
 *
 * @param   {number}  id  [id description]
 *
 * @return  {[type]}      [return description]
 */
const deleteMapping = async (id: number): Promise<boolean> => {
  const response = await Axios.delete(`${mappingURL}/${id}`);
  return response.status === 200;
};

export { deleteMapping };
