import Axios from "axios";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

type VocabsLookupQueryParams = operations["lookup"]["parameters"]["query"];
type VocabsLookupResponse =
  operations["lookup"]["responses"]["200"]["content"]["application/hal+json"];

const baseUrl: string = serverUrl("api");

/**
 * Lookup terms using the Termite vocab API.
 * @param {VocabsLookupQueryParams} queryParams
 * @return {Promise<VocabsLookupResponse>}
 */
export const lookup = async (
  queryParams: VocabsLookupQueryParams
): Promise<VocabsLookupResponse> => {
  const url = `${baseUrl}/vocabs/lookup`;

  const response = await Axios.get(url, { params: queryParams });

  return (response.data as VocabsLookupResponse) || [];
};
