import Axios from "axios";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";
import qs from "qs";

const baseUrl = serverUrl("api");

type datasetSummaryResponse =
  operations["getDatasetSummary"]["responses"]["200"]["content"]["application/hal+json"];
type datasetSummaryParams =
  operations["getDatasetSummary"]["parameters"]["query"];

const getDatasetSummary = async (
  id: number,
  params: datasetSummaryParams
): Promise<datasetSummaryResponse | undefined> => {
  const queryString = "?" + qs.stringify(params, { arrayFormat: "comma" });
  const url = `${baseUrl}/datasets/${id}/datasetSummary${queryString}`;

  const res = await Axios.get(url);

  return res.data || undefined;
};

export { getDatasetSummary };
