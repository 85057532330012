import Axios from "axios";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");

/**
 * Retrieves datasets from the API dataset controller.
 * @param {Number} page Page you want to retrieve
 * @param {Number} size Number of records per page
 * @param {('asc' | 'desc')} sort Sorting criteria in the format
 * @returns Promise<(Array<Dataset> | undefined)>
 */

const getTableHeaders = async (datasetId: number) => {
  const url = `${baseUrl}/datasets/${datasetId}/attributes`;
  const response = await Axios.get(url);

  return response.data || [];
};

export { getTableHeaders };
