import { Observer } from "@/ts/types";
import { MessageEventEncoded } from "@/ts/interfaces";
import { Ref } from "vue";

/**
 * Marks a dataset as loaded.
 * @param {Record<string, unknown>} args
 * @param {MessageEventEncoded} args.event
 * @param {number} args.datasetId
 * @param {Ref<boolean>} args.loaded
 * @return {boolean} true in order to execute the next action
 */
export const markDatasetLoaded: Observer = (args) => {
  const event = args.event as MessageEventEncoded;
  const datasetId = args.datasetId as number;
  const loaded = args.loaded as Ref<boolean>;
  const eventDatasetId =
    event.data.job && event.data.job.dataset && event.data.job.dataset.id;

  if (eventDatasetId === datasetId) loaded.value = true;

  return true;
};
