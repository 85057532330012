import { useNotifications } from "@/compositions";
import { ContextualTypes, NotificationStatus, Observer } from "@/ts/types";
import { MessageEventEncoded } from "@/ts/interfaces";

/**
 * Registers an action to show a notification when an mapping has started and when is ended.
 * @param {Record<string, unknown>} args
 * @return {boolean}
 */
export const displayMappingNotifications: Observer = (args) => {
  const event = args.event as MessageEventEncoded;

  const { pushNotification } = useNotifications();

  const datasetStatus: NotificationStatus | undefined =
    event.data.datasetStatus || undefined;

  if (!datasetStatus) return true;

  if (
    ["ONTOLOGY_MAPPING_STARTED", "ONTOLOGY_MAPPING_COMPLETE", "ONTOLOGY_MAPPING_ERROR"].includes(
      datasetStatus
    )
  ) {
    let notificationType: ContextualTypes;

    switch (datasetStatus) {
      case "ONTOLOGY_MAPPING_STARTED":
        notificationType = "info";
        break;
      case "ONTOLOGY_MAPPING_COMPLETE":
        notificationType = "success";
        break;
      default:
        notificationType = "error";
    }

    pushNotification({
      title: event.data.title,
      text: event.data.message || "",
      type: notificationType,
      timeout: true,
    });
  }

  return true;
};

export default displayMappingNotifications;
