import { Ref, ref, reactive } from "vue";
import { Dataset, DatasetParams, Pages } from "@/ts/interfaces";
import {
  getDatasetsAndPages,
} from "@/api/datasets/getDatasets";
import { deleteDataset } from "@/api/datasets/deleteDataset";
interface DatasetComposition {
  datasets: Ref<Dataset[] | never[]>;
  updateDatasets: (params: DatasetParams) => Promise<Dataset[] | never[]>;
  deleteDatasetCard: (id: number) => Promise<boolean>;
  pages: Pages;
}

/**
 * Requests datasets from the API and returns a reactive reference to it.
 * @returns {DatasetComposition}
 */
const pages: Pages = reactive({
  number: 0,
  totalPages: 0,
  size: 9,
  totalElements: 0,
});

export const useDatasets = (): DatasetComposition => {
  const datasets: Ref<Dataset[] | never[]> = ref([]);

  const updateDatasets = async (params: DatasetParams) => {
    const res = await getDatasetsAndPages(params);
    
    if (!res.page) {
      datasets.value = [];
      return [];
    }
    // set the page object with the pages recieved from api
    pages.number = res.page.number;
    pages.size = res.page.size;
    pages.totalElements = res.page.totalElements;
    pages.totalPages = res.page.totalPages;

    datasets.value = res._embedded.datasets;

    return res._embedded.datasets;
  };

  const deleteDatasetCard = (datasetId: number): Promise<boolean> => {
    return deleteDataset(datasetId);
  };

  return {
    datasets,
    updateDatasets,
    deleteDatasetCard,
    pages,
  };
};
