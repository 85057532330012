import { components } from "@/ts/interfaces/ApiSchemas";

const useMappingStyles = () => {
  type MappingType = components["schemas"]["MappingResult"]["type"];

  /**
   * Used for identifying the icon needed for the mapping method
   * @param type - The mapping type that needs the corresponding icon
   */
  function decorateMappingType(type: MappingType): string {
    let icon: string;
    switch (type) {
      case "AUTOMATED":
        icon = "mdi-robot";
        break;
      case "MANUAL_VERIFIED":
        icon = "mdi-account-check";
        break;
      default:
        icon = "";
        break;
    }
    return icon;
  }

  /**
   * Used for identifying the colour needed for the mapping method
   * @param mappingType - The mapping type that needs the corresponding icon
   * @returns the color used for the cell
   */
  function changeMappingTypeColour(mappingType: MappingType): string {
    let colour: string;
    switch (mappingType) {
      case "AUTOMATED":
        colour = "teal";
        break;
      case "MANUAL_VERIFIED":
        colour = "green darken-2";
        break;
      default:
        colour = "";
        break;
    }
    return colour;
  }

  return { decorateMappingType, changeMappingTypeColour };
};

export { useMappingStyles };
