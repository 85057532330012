import { computed, Ref, ref } from "vue";
import { Dataset } from "@/ts/interfaces";
import { operations } from "@/ts/interfaces/ApiSchemas";

type DatasetSummary =
  operations["getDatasetSummary"]["responses"]["200"]["content"]["application/hal+json"];

/**
 * The dataset summary
 * @type {Ref<UnwrapRef<DatasetSummary>>}
 */
const datasetSummary: Ref<DatasetSummary> = ref({});

const dataset: Ref<Dataset | undefined> = ref({} as Dataset);

export const useDataset = () => {
  const getDatasetId = computed(() => {
    return dataset.value?.id;
  });

  /**
   * Getter for the dataset variable.
   * @type {ComputedRef<Dataset>}
   */
  const getDataset = computed(() => {
    return dataset.value;
  });

  /**
   * Setter for the dataset variable.
   * @param {Dataset} datasetData
   * @returns {Dataset}
   */
  const setDataset = (datasetData: Dataset) => {
    dataset.value = datasetData;
  };

  /**
   * Updates the title property of current dataset object.
   * @param {string} datasetTitle
   */
  const setDatasetTitle = (datasetTitle: string) => {
    if (!dataset.value) return;

    dataset.value.title = datasetTitle;
  };

  const getDatasetSummary = computed(() => {
    return datasetSummary.value;
  });

  const setDatasetSummary = (summary: DatasetSummary) => {
    datasetSummary.value = summary;
  };

  return { setDataset, setDatasetTitle, getDataset, getDatasetId, getDatasetSummary, setDatasetSummary };
};
