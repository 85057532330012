import { API_TOKEN_NAME, AUTH_HEADER_PREFIX } from "@/globals";
import Axios from "axios";
import { useLoginState, useObservers } from "@/compositions";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const endpoint = `${baseUrl}/datasets`;

/**
 * Performs a request to make sure that the token is still valid.
 * @returns {Promise<boolean>}
 */
const verifyToken = async (): Promise<boolean> => {
  const token = localStorage.getItem(API_TOKEN_NAME);

  if (!token) return false;

  const { notifyObservers } = useObservers();

  try {
    await Axios.get(endpoint, {
      headers: { Authorization: AUTH_HEADER_PREFIX + token },
      params: { size: 1 },
    });

    useLoginState().setTokenVerified(true);

    await notifyObservers("TOKEN_VERIFICATION_SUCCEEDED", { token });
    return true;
  } catch (e) {
    useLoginState().setTokenVerified(false);

    await notifyObservers("TOKEN_VERIFICATION_FAILED", { token });
    return false;
  }
};

export { verifyToken };
