import { useNotifications, useUser } from "@/compositions";
import { MSG_LOGIN_UNSUCCESSFUL } from "@/globals";

/**
 * Handles an unsuccessful login request and notifies the user that login was unsuccessful.
 * @param {AxiosError} error
 */
export const handleLoginUnsuccessful = {
  endpoint: "login",
  status: [401, 402],
  handle: () => {
    useUser().setLoggedIn(false);

    useNotifications().pushNotification({
      type: "error",
      text: MSG_LOGIN_UNSUCCESSFUL,
      timeout: true,
    });
  },
};
