import Axios from "axios";
import qs from "qs";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");

/**
 * finds and replace cell values for specified dataset and attributes
 * @param {number} datasetId
 * @param params
 * @return {Promise<void>}
 */
const findAndReplaceValues = async (
  datasetId: number,
  params: { find: string, replace: string, exact?: boolean, attributeIds?: number[] }): Promise<boolean> => {

  const queryString = qs.stringify(params, { arrayFormat: "repeat" });
  const endpoint = `${baseUrl}/datasets/${datasetId}/value/findAndReplace?${queryString}`;
  const response = await Axios.put(endpoint);

  return response.status == 200;
};

export { findAndReplaceValues };
