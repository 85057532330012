import Axios from "axios";
import qs from "qs";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const historyUrl = `${baseUrl}/datasets`;

type getHistorySetQuery = operations["getHistorySet"]["parameters"]["query"];
type getHistorySetResponse = Promise<
  operations["getHistorySet"]["responses"]["200"]["content"]["application/hal+json"]
>;

const getHistorySet = async (
  datasetId: number,
  query: getHistorySetQuery
): getHistorySetResponse => {
  const queryString = qs.stringify(query, {
    arrayFormat: "comma",
    encode: false,
  });
  const url = `${historyUrl}/${datasetId}/historyset?${queryString}`;

  const response = await Axios.get(url);

  return response.data || [];
};

export { getHistorySet };
