import { components, operations } from "@/ts/interfaces/ApiSchemas";
import { computed, ref, Ref } from "vue";
import { debounce, findKey } from "lodash";
import { Pages } from "@/ts/interfaces";

type tableQuery = operations["getUnAnnotated"]["parameters"]["query"];

interface VuetifyDatasetTableOptions {
  groupBy: Array<string | undefined>;
  length: number;
  groupDesc: Array<boolean | undefined>;
  itemsPerPage: number;
  multiSort: boolean;
  page: number;
  sortBy: Array<number | string>;
  sortDesc: Array<boolean>;
}

interface TableResponse {
  _embedded: Record<string, []>;
  links: components["schemas"]["Links"];
  page: Pages;
}

type AttributeColumn = components["schemas"]["Attribute"];

interface AttributeColumnWithHide extends AttributeColumn {
  shown?: boolean;
}

/**
 * Sets up the table using the generic endpoints we use for getting table data.
 * Use destructuring to access the variables to plug into your v-data-table.
 * @param {function} requestCallBack - The api request that will fetch your rows
 * @param {number} id - The dataset id needed for creating the api request
 * @param {Row} row - A generic data type for your row. This is used to inform the TS intellisense.
 * @param {string} dataProperty - The property in _embedded that the api request returns that has the rows in
 * @param {tableQuery} reqParams - Optional queries if you what to overwrite the default
 * @param rowFormatter - Optional args you can use to format your rows
 */
const useSimpleTable = <Row>(
  requestCallBack: (datasetId: number, params: tableQuery) => TableResponse,
  id: number,
  row: Row,
  dataProperty: string,
  reqParams?: tableQuery,
  rowFormatter?: (v: []) => Row[]
) => {
  const tableRows: Ref<Array<Row>> = ref([]);
  const itemsPerPage: Ref<number> = ref(25);
  const currentPage: Ref<number> = ref(1);
  const search: Ref<string> = ref("");
  const sortQuery: Ref<Array<string>> = ref([]);
  const totalItems: Ref<number> = ref(0);
  const selectedColumns: Ref<Array<number>> = ref([]);
  const loading: Ref<boolean> = ref(true);
  const excludedColumns: Ref<Array<AttributeColumnWithHide>> = ref([]);
  const tableAttributeColumns: Ref<Array<AttributeColumnWithHide>> = ref([]);
  const exactSearch: Ref<boolean> = ref(false);
  const selected: Ref<Array<Row>> = ref([]);

  const filteredAttribute: Ref<AttributeColumn | undefined> = ref(undefined);
  let sortBy: Array<number | string> | undefined = undefined;
  let sortDesc: Array<boolean> | undefined = undefined;

  const requestTableRows = async (
    apiRequest: (datasetId: number, params: tableQuery) => TableResponse,
    datasetId: number,
    params?: tableQuery
  ) => {
    loading.value = true;

    const res = await apiRequest(
      datasetId,
      params || {
        page: currentPage.value - 1,
        size: itemsPerPage.value,
        filter: (search.value && search.value !== "") ? search.value.trim() : undefined,
        sort: sortQuery.value,
        exclude_attributes: excludedColumns.value.map(
          (col) => col?.id as number
        ),
        exact: exactSearch.value,
        filter_attributes: [filteredAttribute.value?.id as number],
      }
    );

    loading.value = false;

    return res;
  };

  /**
   * Uses the requestTableRows to get the request then adds it to the table rows object
   */
  const requestToRows = async (): Promise<void> => {
    const rowResponse = await requestTableRows(requestCallBack, id, reqParams);

    if (typeof rowResponse._embedded === "undefined") {
      tableRows.value = [] as Array<Row>;
      totalItems.value = 0;
      return;
    }

    if (!findKey(rowResponse, dataProperty)) {
      console.error(`${dataProperty} cannot be found in api request`);
    }

    if (typeof rowFormatter !== "undefined") {
      tableRows.value = rowFormatter(rowResponse._embedded[dataProperty]);
    } else {
      tableRows.value = rowResponse._embedded[dataProperty] as Array<Row>;
    }

    totalItems.value = rowResponse.page?.totalElements || 0;
  };

  const length = computed(() => {
    return Math.ceil(totalItems.value / itemsPerPage.value);
  });

  /**
   * Use this to change the table to do a different page.
   * @param {number} nextPage - The page number you want to go to
   */
  const updateCurrentPage = async (nextPage: number): Promise<void> => {
    currentPage.value = nextPage;
    await requestToRows();
  };

  /**
   * Updates the amount of items displayed on each page
   * @param {number} pageSize - Updates how many rows to display on each table page
   */
  const updateItemsPerPage = async (pageSize: number): Promise<void> => {
    itemsPerPage.value = pageSize;
    await requestToRows();
  };

  /**
   * Builds the sort query array used to query the API.
   * @returns {Array<string>}
   */
  const buildSortArray = (): Array<string> => {
    if (!sortBy) return [];

    return sortBy.map((columnValue, index) => {
      const direction = sortDesc && sortDesc[index] ? "desc" : "asc";
      return `${columnValue},${direction}`;
    });
  };

  /**
   * Updates the table with the new sorting options you provide it
   * @param {VuetifyDatasetTableOptions} options - The table options for sorting etc
   */
  const updateOptions = async (
    options: VuetifyDatasetTableOptions
  ): Promise<void> => {
    if (!options.sortBy.length && !options.sortDesc.length) return;

    if (options.sortBy !== sortBy || options.sortDesc !== sortDesc) {
      sortBy = options.sortBy;
      sortDesc = options.sortDesc;

      sortQuery.value = buildSortArray();
      await requestToRows();
    }
  };

  /**
   * Sends a request with an updated search parameter. Will fire every 300ms once stopped typing.
   */
  const debouncedSearch = debounce(
    async (searchString: string): Promise<void> => {
      if (searchString && searchString !== "") {
        search.value = searchString;
      }

      //Resets the page, so it doesn't get included in the search
      currentPage.value = 1;
      await requestToRows();
    },
    500
  );

  async function updateAttributeNameFilter(
    attributeColumn: AttributeColumn | undefined
  ) {
    filteredAttribute.value = attributeColumn;
    await requestToRows();
  }

  return {
    requestTableRows,
    length,
    tableRows,
    loading,
    search,
    currentPage,
    sortQuery,
    selectedColumns,
    totalItems,
    itemsPerPage,
    requestToRows,
    updateCurrentPage,
    updateItemsPerPage,
    updateOptions,
    debouncedSearch,
    excludedColumns,
    tableAttributeColumns,
    updateAttributeNameFilter,
    exactSearch,
    filteredAttribute,
    selected,
  };
};

export { useSimpleTable };
