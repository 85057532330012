import { useNotifications } from "@/compositions";
import { MSG_FORBIDDEN_ERROR, MSG_INTERNAL_SERVER_ERROR, MSG_REQUEST_TIMEOUT_ERROR } from "@/globals";
import { AxiosError } from "axios";

/**
 * Handles an unsuccessful api requests and notifies the errors while calling api endpoints
 * @param {AxiosError} error
 */
export const handleApiErrors = {
  endpoint: [
    "rules",
    "rules/*",
    "datasets",
    "datasets/*",
    "users",
    "users/*",
    "vocabs/*",
    "groups",
    "groups/*",
    "jobs",
    "jobs/*",
    "mappings",
    "mappings/*"
  ],
  status: [400, 403, 404, 409, 424, 500, 504],
  handle: (error: AxiosError) => {
    if (error.response?.status == 403 && error.request?.responseURL.includes('/users/me/internal')) {
      return;
    }

    let message = error.response?.data.detail;
    if (error.response?.status == 500) {
      message = MSG_INTERNAL_SERVER_ERROR;
    } else if (error.response?.status == 403) {
      message = MSG_FORBIDDEN_ERROR;
    } else if (error.response?.status == 504) {
      message = MSG_REQUEST_TIMEOUT_ERROR;
    } 

    useNotifications().pushNotification({
      type: "error",
      text: message,
      timeout: true,
    });
  },
};
