import Vue from "vue";
import Vuetify from "vuetify";

Vue.use(Vuetify);

export default new Vuetify({
  customVariables: "~/sass/themes/dark/variables.scss",
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#189AD6",
        accent: "#04ded3",
        secondary: "#4D5061",
        success: "#18cc85",
        info: "#F4E285",
        warning: "#e5aa52",
        error: "#DB222A",
        background: "#E0E0E0",
        foreground: "#FFFFFF",
      },
      dark: {
        primary: "#189AD6",
        accent: "#04ded3",
        secondary: "#4D5061",
        success: "#18cc85",
        info: "#F4E285",
        warning: "#e5aa52",
        error: "#DB222A",
        background: "#161313",
      },
    },
  },
});
