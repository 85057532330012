import Axios from "axios";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const annotationsUrl = `${baseUrl}/datasets`;

type GetAnnotationConfigSuggestionResponse =
  operations["getAnnotationConfigSuggestion"]["responses"]["200"]["content"]["application/json"];

/**
 * Request configuration suggestions for a specified dataset.
 * @param {number} datasetId
 * @return {Promise<GetAnnotationConfigSuggestionResponse>}
 */
const getAnnotationConfigSuggestion = async (
  datasetId: number
): Promise<GetAnnotationConfigSuggestionResponse> => {
  const url = `${annotationsUrl}/${datasetId}/annotationConfigSuggestion`;

  const response = await Axios.get(url);

  return response.data || {};
};

export { getAnnotationConfigSuggestion };
