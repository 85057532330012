<template>
  <v-dialog v-model="showDialog" scrollable :width="width" v-if="dialogProps">
    <v-card>
      <v-card-title class="text-h5 lighten-2">
        {{ dialogTitle }}
      </v-card-title>

      <v-card-text class="flex">
        <component :is="dialogProps.component" v-bind="dialogProps" />
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="ml-6 pa-0" v-if="dialogProps.options">
        <component :is="dialogProps.options" v-bind="dialogProps" />
      </v-card-actions>

      <v-card-actions class="mb-2 mt-2 mr-2">
        <v-spacer></v-spacer>
        <v-btn
          v-if="displayCancelButton"
          @click="handleCancel"
          color="error"
          plain
          depressed
          >Cancel
        </v-btn>
        <v-btn
          :disabled="confirmButtonDisabled"
          @click="handleConfirm"
          color="primary"
          depressed
          plain
          >{{ confirmButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { computed, defineComponent, onUnmounted } from "vue";
import { useAppDialog } from "@/compositions";

export default defineComponent({
  name: "AppDialog",
  setup(_, { emit }) {
    const showDialog = useAppDialog().getShowDialog();
    const displayCancelButton = useAppDialog().getDisplayCancelButton();
    const confirmButtonDisabled = useAppDialog().getConfirmButtonDisabled();
    const confirmButtonText = useAppDialog().getConfirmButtonText();
    const width = computed(() => useAppDialog().getDialogueWidth());

    const { dialogTitle, dialogProps } = useAppDialog().getCurrentDialog();

    const handleHidden = (event: Event) => {
      emit("onHidden", event);
    };

    const handleShow = (event: Event) => {
      emit("onShow", event);
    };

    /**
     *
     * @todo Implement app-wise observers and notify observers
     * @param {Event} event
     */

    const handleConfirm = (event: KeyboardEvent) => {
      useAppDialog().setShowDialog(false);
      useAppDialog().confirm(event);
      emit("onConfirm", event);
      useAppDialog().unsetDialog();
    };

    const handleCancel = (event: Event) => {
      useAppDialog().unsetDialog();
      useAppDialog().confirm(event);
      emit("onCancel", event);
    };

    onUnmounted(() => {
      useAppDialog().unsetDialog();
    });

    return {
      width,
      showDialog,
      displayCancelButton,
      confirmButtonDisabled,
      confirmButtonText,
      dialogTitle,
      dialogProps,
      handleConfirm,
      handleCancel,
      handleShow,
      handleHidden,
    };
  },
});
</script>
<style lang="scss" scoped>
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>
