import Axios from "axios";
import qs from "qs";
import { components, operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const annotationsUrl = `${baseUrl}/datasets`;

type annotateByFilterQuery =
  operations["manualAnnotateData"]["parameters"]["query"];
type annotateByFilterResponse = Promise<components["schemas"]["Annotation"][]>;
interface AnnotateByFilterRequestData {
  hit: components["schemas"]["AnnotationHit"];
}

/**
 * Create a new annotation for multiple values based on a filter.
 * @param datasetId
 * @param {annotateByFilterQuery} query
 * @param rows
 * @return {annotateByFilterResponse}
 */
const annotateByFilter = async (
  datasetId: number,
  query: annotateByFilterQuery,
  rows: AnnotateByFilterRequestData[]
): annotateByFilterResponse => {
  let queryString = qs.stringify(query, {
    arrayFormat: "comma",
    encode: false,
  });

  //the qs library skips undefined but keeps nulls so it's easier to build the query string like this
  if (typeof query?.filter_attributes === "undefined") {
    queryString = `${queryString}&filter_attributes=`;
  }

  const url = `${annotationsUrl}/${datasetId}/annotateByFilter?${queryString}`;

  const response = await Axios.post(url, rows);

  return response.data;
};

export { annotateByFilter };
