import Axios from "axios";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");
const datasetURL = `${baseUrl}/datasets`;

/**
 * @param fileType File type from a type of file
 * @returns Will return a string url for the request, defaults to empty in not found
 */
const getFileUrl = (fileType: string): string => {
  let fileUrl: string;

  switch (fileType) {
    case "text/csv":
      fileUrl = "uploadCsv";
      break;

    case "text/tsv":
    case "text/plain":
    case "text/tab-separated-values":
      fileUrl = "uploadTsv";
      break;

    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      fileUrl = "uploadExcel";
      break;

    default:
      fileUrl = "";
      break;
  }

  return fileUrl;
};

/**
 * Links a dataset with a file, a dataset must already exist
 * @param id The id of the dataset you're assigning the file to
 * @param file Formdata containing a file
 * @param firstRowIsAttributes is the first row a attribute
 */
const uploadDatasetFile = async (
  id: number,
  file: File,
  firstRowIsAttributes: boolean
) => {
  //Gets the mime type of the file and then finds the url needed to upload it too
  let fileUrl = getFileUrl(file.type);

  // Issue with xls files not be recognized as a file type
  // see https://bugs.chromium.org/p/chromium/issues/detail?id=105382
  if (file.name.endsWith(".xls") && fileUrl === "") {
    fileUrl = "uploadExcel";
  }

  if (fileUrl === "") return false;

  //Creates the form data to be used for uploading
  const formData = new FormData();

  // Has to been named file for server to recognize the upload
  formData.append("file", file, file.name);

  const uploadUrl = `${datasetURL}/${id}/${fileUrl}`;

  const response = await Axios.post(uploadUrl, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    params: {
      firstRowIsAttributes,
    },
  });

  return response.status == 200;
};

export { uploadDatasetFile };
