import Axios from "axios";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const datasetURL = `${baseUrl}/datasets`;

/**
 * [deleteDataset description]
 *
 * @param   {number}  id  [id description]
 *
 * @return  {[type]}      [return description]
 */
const deleteDataset = async (id: number): Promise<boolean> => {
  const response = await Axios.delete(`${datasetURL}/${id}`);

  return response.status === 200;
};

export { deleteDataset };
