import Axios from "axios";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const annotationsUrl = `${baseUrl}/datasets`;

type DeleteAnnotationByIdPathParams =
  operations["deleteAnnotationsById"]["parameters"]["path"];
type DeleteAnnotationResponse =
  operations["deleteAnnotationsById"]["responses"]["200"]["content"]["application/hal+json"];

/**
 * Deletes an annotations for a single value by annotation id.
 * @param {number} datasetId - The id of the dataset that the change needs to occur on
 * @param {verifyAnnotationParams} params - The params needed for the request
 * @return {verifyAnnotationResponse}
 * @todo This function hasn't been tested.
 */
export const deleteAnnotationById = async (
  pathParams: DeleteAnnotationByIdPathParams
): Promise<DeleteAnnotationResponse> => {
  const { id: dataset_id, value_id, annotation_id } = pathParams;

  const url = `${annotationsUrl}/${dataset_id}/values/${value_id}/annotations/${annotation_id}`;

  const response = await Axios.delete(url);

  return response.data as DeleteAnnotationResponse;
};
