import { Observer } from "@/ts/types";
import { useJobsQueue } from "@/compositions";
import { MessageEventEncoded } from "@/ts/interfaces";

/**
 * Adds a job to the queuedJobs array so that it's displayed in the jobs bar.
 * @param {Record<string, unknown>} args
 * @param {MessageEventEncoded} args.event
 * @return {boolean} true in order to execute the next action
 */
export const stackQueuedJob: Observer = (args) => {
  const event = args.event as MessageEventEncoded;
  if (!event.data.job) return false;
  
  const jobType = event.data.job.jobType;
  event.data.job
  
  let url;
  let title;
  if (jobType == "MAPPING") {
    url = event.data.job.dataset && `/mappings/${event.data.job.dataset.id}`;
    title = event.data.job.dataset && event.data.job.dataset.title;
  } else {
    url = event.data.job.dataset && `/datasets/${event.data.job.dataset.id}`;
    title = event.data.job.dataset && event.data.job.dataset.uploadedFileName;
  }
  
  const { queueJob } = useJobsQueue();

  let type;
  if (args.subjectName == "ANNOTATION_STARTED") {
    type = "annotate"
  } else if (args.subjectName == "ONTOLOGY_MAPPING_STARTED") {
    type = "mapping"
  } else {
    type = "upload"
  }

  if (title) {
    queueJob({
      label: title,
      type: type,
      status: "ongoing",
      id: event.data?.job?.jobId as number,
      jobType : jobType,
      url,
    });
  }

  return true;
};
