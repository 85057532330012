import Axios from "axios";
import { components } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");

type response = components["schemas"]["CollectionModelAnnotationExportFieldValueset"];
/**
 * Gets list of annotation export fields
 * @returns Promise<(Array<Vocab> | undefined)>
 */
const findAnnotationExportFieldValueset = async (
): Promise<response | undefined> => {
  const response = await Axios.get(`${baseUrl}/valuesets/annotationExportFields`);
  return response.data || undefined;
};

export { findAnnotationExportFieldValueset };
