<template>
  <v-list-item class="toolbox-panel">
    <v-list-item-content>
      <v-list-item-title class="toolbox-panel-title subtitle-2">
        {{ title }} <v-icon>{ mdi-chevron-down }</v-icon>
      </v-list-item-title>
      <v-list-item-content class="toolbox-panel-content">
        <slot name="content" />
      </v-list-item-content>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ToolboxPanel",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
});
</script>

<style scoped lang="scss">
.toolbox-panel {
  padding: 0;
  background-color: var(--v-background-base);
  border-bottom: 1px solid var(--v-secondary-darken2);

  .toolbox-panel-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    background-color: var(--v-background-darken1);
    border-bottom: 1px solid var(--v-secondary-lighten5);

    .v-icon {
      float: right;
    }
  }

  .toolbox-panel-content {
    padding: 10px 0 20px 0;
  }

  > .v-list-item__content {
    padding: 0;
  }

  ::v-deep {
    .panel-content-container {
      display: flex;
      flex-direction: column;
      max-height: 300px;
      overflow-y: auto;
      .panel-item {
        margin-bottom: 10px;
        padding: 0 16px 10px 16px;

        &:not(:last-of-type) {
          border-bottom: 1px solid var(--v-secondary-lighten5);
        }
        .panel-item-header {
          position: fixed;
          background-color: var(--v-background-base);
          z-index: 1;
          margin: 0 -16px;
          padding: 0 16px;
          width: 100%;
        }
        .panel-item-body {
          margin-top: 6rem;
        }
        &__title {
          margin-bottom: 10px;
          border-bottom: 1px solid #ccc;
        }
        &__button {
          position: absolute;
          bottom: 0;
          right: 0;
        }
        &__value {
          padding-bottom: 5px;
        }
        &__labels {
          &:not(:last-child) {
            &:after {
              content: ", ";
            }
          }
          &--not-found {
            font-style: italic;
          }
        }

        .panel-item-chip {
          &__text {
            display: block;
            max-width: 80%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-right: 5px;
          }
        }
      }

      &__btn {
        align-self: center;
        margin: 0 10px;
      }
    }
  }
}
</style>
