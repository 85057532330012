import { Observer } from "@/ts/types";
import { streamNotifications } from "@/api";

/**
 * Performs a request to the stream endpoint.
 * @param {Record<string, unknown>} args
 * @param {string} token
 * @returns {Promise<boolean>}
 */
export const startNotificationsStreaming: Observer = async (args) => {
  const token = args.token as string;

  if (token) {
    await streamNotifications(token);
  }

  return true;
};
