<template>
  <v-footer
    fixed
    class="main-footer foreground"
    data-testid="main-footer"
    app
    outlined
  >  
  <v-row v-show="(hasJobs && showJobs) || showMyJobsClicked" class="job-queue">
      <span  class="text-center px-4 py-2 font-weight-medium job-queue-item__text" v-show="!hasJobs"
      >No job is currently running
      </span>
    <jobs-queue />
    <v-icon light @click="toggleFooter"> mdi-close </v-icon>
  </v-row>
      <v-col cols="12" class="mt-2 bg-blue-lighten-1 info-row rounded-t-sm">
        Version: <strong>{{ projectInfo?.version.split("-")[0] }}</strong>
        <span class="support">
          <v-btn class="mx-2" size="small" color="primary" variant="plain" elevation="0" href="mailto: help@scibite.com?subject=Workbench - Contact us" title="Contact" target="_blank">Contact</v-btn>
          <v-btn class="mx-2" size="small" color="primary" variant="plain" elevation="0" href="http://help.scibite.com" title="Scibite Knowledgebase &amp; Support" target="_blank">Support</v-btn>
          <v-btn class="mx-2" size="small" color="primary" variant="plain" elevation="0"  href="https://www.scibite.com" title="Scibite" target="_blank">Scibite</v-btn>
        </span>
      </v-col>
  </v-footer>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, Ref, ref, watch } from "vue";
import JobsQueue from "@/components/common/JobsQueue.vue";
import { useJobsQueue } from "@/compositions";
import { getProjectInfo } from "../../api";

export default defineComponent({
  name: "MainFooter",
  components: { JobsQueue },
  props: { 
    showMyJobsClicked: { type: Boolean, default: false}
  },
  setup(props, { emit }) {
    const { runningJobs } = useJobsQueue();
    const showJobs: Ref<boolean> = ref(true);
    const projectInfo: Ref<unknown> = ref();

    const hasJobs = computed(() => {
      return runningJobs.value.list.length;
    });
 
    watch(hasJobs, () => {
      showJobs.value = true;
    });

    const toggleFooter = () => {
      showJobs.value = !showJobs.value;
      emit("onFooterClose");
    };

    onMounted(async () => {
      projectInfo.value = await getProjectInfo();
    });

    return { runningJobs, hasJobs, showJobs, toggleFooter, projectInfo };
  },
});
</script>

<style scoped>
.main-footer {
  z-index: 10;
  display: flex;
  padding: 0;
}
.bg-blue-lighten-1 {
    background-color: #189ad6;
    color: #fff;
}
.job-queue {
  padding: 0.5em 0;
}
.info-row{
  padding: 4px 12px;
}
.support {
    float: right;
}
</style>
