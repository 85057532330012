/**
 * Loops through an array and performs an async callback on each iteration, resolves when all actions are resolved.
 * @param {T[]} iterable
 * @param {(instance: T) => Promise<void>} callback
 * @return {Promise<void[]>}
 */
export const awaitAll = async <T>(
  iterable: T[],
  callback: (instance: T) => Promise<void>
) => {
  const promises = iterable.map(async (instance) => {
    await callback(instance);
  });

  return await Promise.all(promises);
};
