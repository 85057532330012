import Axios from "axios";
import { components } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");

type JoinAttributesRequest = components["schemas"]["JoinAttributesRequest"];

/**
 * join list of attribute/column into a attributes/columns
 * @param {number} baseAttributeId
 * @param {number | undefined} attributeIds
 * @param {number} separator
 * @param {boolean} newColumnName
 * @param {number} deleteJoinedColumns
 * @return {Promise<void>}
 */
const joinAttribute = async (
  datasetId: number,
  { baseAttributeId, attributeIds, separator, newColumnName, deleteJoinedColumns }: JoinAttributesRequest
): Promise<boolean> => {
  const endpoint = `${baseUrl}/datasets/${datasetId}/attributes/join`;

  const response = await Axios.put(endpoint, {
    baseAttributeId,
    attributeIds,
    separator,
    newColumnName,
    deleteJoinedColumns,
  });

  return response.status == 200;
};

export { joinAttribute };
