import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import { initKeycloak, useKeycloak } from "./compositions/useKeycloak";
import { API_TOKEN_NAME, AUTH_HEADER_PREFIX } from "./globals";
import Axios from "axios";
import { serverUrl } from "@/utils";
import { KeycloakConfig } from "keycloak-js";

Vue.config.productionTip = false;

const baseUrl: string = serverUrl("api");

Axios.get(baseUrl + "/config").then(response => {
  localStorage.setItem('MAX_MULTIPART_FILE_SIZE', response.data["maxMultipartFileAllowed"]);
  const keycloakProp = response.data["keycloak"];
  const keycloakConfig = { 
    realm : keycloakProp.realm,
    clientId: keycloakProp.clientIdWebapp,
    url: keycloakProp.url
  } as KeycloakConfig;
  initKeycloak(keycloakConfig);

  const _keycloak = useKeycloak();
  _keycloak.init({
      onLoad: "check-sso",
      checkLoginIframe:false
    })
    .then((authenticated) => {
      if (authenticated &&  _keycloak.token) {
        localStorage.setItem(API_TOKEN_NAME, _keycloak.token);
        Axios.defaults.headers.common["Authorization"] = AUTH_HEADER_PREFIX + _keycloak.token
      }
      new Vue({
        router,
        store,
        vuetify,
        render: (h) => h(App),
      }).$mount("#app");
    });

});

