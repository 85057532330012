import Axios from "axios";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");
const datasetsUrl = `${baseUrl}/datasets`;

type ChangeDatasetGroupOwnerResponse =
  operations["changeGroup"]["responses"]["200"]["content"]["application/hal+json"];

/**
 * Changes the datasets owners group
 * @param datasetId - The id of the dataset you want the group to change for
 * @param groupId - The group id you want the dataset to transfer to
 */
const changeDatasetGroupOwner = async (
  datasetId: number,
  groupId: number
): Promise<ChangeDatasetGroupOwnerResponse | undefined> => {
  const url = `${datasetsUrl}/${datasetId}/changeGroup/${groupId}`;
  const response = await Axios.put(url);

  return response?.data || undefined;
};

export { changeDatasetGroupOwner };
