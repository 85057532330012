import { computed, Ref, ref } from "vue";

const searchFilter = ref("");
const exactFilter = ref(false);
const attributesFilter: Ref<number[]> = ref([]);

export const useDatasetFilters = () => {
  const resetFilters = () => {
    searchFilter.value = "";
    exactFilter.value = false;
    attributesFilter.value = [];
  };

  const getSearchFilter = computed(() => {
    return searchFilter.value;
  });

  const setSearchFilter = (search: string) => {
    searchFilter.value = search;
  };

  const getExactFilter = computed(() => {
    return exactFilter.value;
  });

  const setExactFilter = (exact: boolean) => {
    exactFilter.value = exact;
  };

  const getAttributesFilter = computed(() => {
    return attributesFilter.value;
  });

  const setAttributesFilter = (attributes: number[] | undefined) => {
    if (typeof attributes !== "undefined") attributesFilter.value = attributes;
    attributesFilter.value = [];
  };

  const pushAttributesFilter = (attribute: number) => {
    attributesFilter.value.push(attribute);
  };

  return {
    getSearchFilter,
    setSearchFilter,
    getExactFilter,
    setExactFilter,
    getAttributesFilter,
    setAttributesFilter,
    pushAttributesFilter,
    resetFilters,
  };
};
