import Axios from "axios";
import qs from "qs";
import { components, operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const mappingUrl = `${baseUrl}/mappings`;

/**
 * Get mapping results for a specified mapping.
 * @param {operations["getMappingResults"]["parameters"]["path"]["id"]} mappingId
 * @param {operations["getMappingResults"]["parameters"]["query"]} params
 * @return {Promise<components["schemas"]["MappingResult"]>}
 */
const getMappingResults = async (
  mappingId: operations["getMappingResults"]["parameters"]["path"]["id"],
  params: operations["getMappingResults"]["parameters"]["query"] | undefined
): Promise<components["schemas"]["MappingResult"]> => {
  const queryString = qs.stringify(params, { arrayFormat: "repeat" });
  const url = `${mappingUrl}/${mappingId}/mappingResults?${queryString}`;

  const response = await Axios.get(url);

  return response.data || [];
};

export { getMappingResults };
