import { Observer } from "@/ts/types";
import { useDatasets } from "@/compositions";
import { Ref } from "vue";
import { Dataset, DatasetParams } from "@/ts/interfaces";

/**
 * Updates the dataset cards in the Dashboards.
 * @param {Record<string, unknown>} args
 * @param {Dataset[]} args.datasetsCards Dashboard datasets array.
 * @return {Promise<boolean>} true in order to execute the next action.
 */
export const updateDatasetsCards: Observer = async (args) => {
  const datasetsCards = args.datasetsCards as Ref<Dataset[]>;

  const params: DatasetParams = { sharing_mode: "", page: 0, size: 10 };

  const { updateDatasets } = useDatasets();

  datasetsCards.value = await updateDatasets(params);

  return true;
};
