import Axios from "axios";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");
const mappingURL = `${baseUrl}/mappings`;

type createMappingResponse =
  operations["create_1"]["responses"]["200"]["content"]["application/hal+json"];

/**
 * Creates a mapping from the API mapping controller
 * @param {String} title The title you wish the mapping to have
 * @param {String} targetVocabTermiteName target vocab for mapping
 * @param {String} sourceVocabTermiteName source vocab for mapping
 * @param {string[]} masks mask terms for mapping
 * @param {String} bestHitsOnly 
 * @param {String} exactMatchOnly 
 * @param {String} cutOff 
 * @param {String} mediatingVocabTermiteName mediating vocab for mapping
 * @param {String} description The description you wish the mapping to have
 * @param {String} datasetStatus Leave blank if mapping is empty
 */
const createMapping = async (
  title: string,
  targetVocabTermiteName: string,
  sourceVocabTermiteName: string,
  masks?: string[],
  bestHitsOnly?: boolean,
  exactMatchOnly?: boolean,
  cutOff?: number,
  mediatingVocabTermiteName?: string,
  description?: string,
  datasetStatus?: string
): Promise<createMappingResponse | undefined> => {
  const response = await Axios.post(mappingURL, {
    title,
    targetVocabTermiteName,
    sourceVocabTermiteName,
    description,
    masks,
    bestHitsOnly,
    exactMatchOnly,
    cutOff,
    mediatingVocabTermiteName,
    datasetStatus,
  });

  if (!response.data) return undefined;

  return response.data;
};

export { createMapping };