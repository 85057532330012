import Axios from "axios";
import { components, operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");

type AttributesByDatasetIdResponse = Promise<
  components["schemas"]["Attribute"][]
>;

/**
 * Get a datasets for a specified dataset name.
 * @param {number} datasetId
 * @returns {DatasetResponse}
 */
const getAttributesByDatasetId = async (
  datasetId: operations["findAttributesById"]["parameters"]["path"]["id"]
): AttributesByDatasetIdResponse => {
  const url = `${baseUrl}/datasets/${datasetId}/attributes`;
  const response = await Axios.get(url);

  return response.data;
};

export { getAttributesByDatasetId };
