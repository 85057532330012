import Axios from "axios";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");

type getGroupInfoResponse =
  operations["getGroupById"]["responses"]["200"]["content"]["application/hal+json"];
/**
 * Get group information for a specified group id.
 * @param {number} groupId - The id of the groups member you want
 * @returns {getGroupInfoResponse}
 */

const getGroupInfo = async (
  groupId: number
): Promise<getGroupInfoResponse | undefined> => {
  const url = `${baseUrl}/groups/${groupId}`;

  const response = await Axios.get(url);

  return response.data || undefined;
};

export { getGroupInfo };
