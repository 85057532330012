import Axios from "axios";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const annotationsUrl = `${baseUrl}/datasets`;

type annotationAttributesLookupResponse = Promise<
  operations["getAnnotatedAttributes"]["responses"]["200"]["content"]["application/hal+json"]
>;

/**
 * Makes the request to the attributes lookup endpoint
 * @param {datasetId} The id of the dataset to get the attributes from
 * @return {annotationAttributesLookupResponse}
 */
const annotationAttributesLookup = async (
  datasetId: number
): annotationAttributesLookupResponse => {
  const url = `${annotationsUrl}/${datasetId}/annotated/attributesLookup`;

  const response = await Axios.get(url);

  return response.data || [];
};

export { annotationAttributesLookup };
