import { computed, ref, Ref } from "vue";
import { PanelTypes } from "@/ts/types";
import { Panel, PanelItemLabel } from "@/ts/interfaces";
import AnnotationsPanel from "@/components/layout/MainToolbox/AnnotationsPanel.vue";
import OntologySelectionPanel from "@/components/layout/MainToolbox/OntologySelectionPanel.vue";
import OntologyConfigurationPanel from "@/components/layout/MainToolbox/OntologyConfigurationPanel.vue";

/**
 * The panels definitions and data.
 * @type Ref<Record<PanelTypes, Panel>>
 */
const panels: Ref<Record<PanelTypes, Panel>> = ref({
  annotations: {
    title: "Annotations",
    selectionType: "cell",
    component: AnnotationsPanel,
    icon: "mdi-notebook",
    data: [],
  },
  attributes: {
    title: "Vocab Selection",
    selectionType: "column",
    component: OntologySelectionPanel,
    icon: "mdi-selection",
    data: [],
  },
  configuration: {
    title: "Vocab Configuration",
    selectionType: "column",
    component: OntologyConfigurationPanel,
    icon: "mdi-tune",
    data: [],
  },
});

export const useToolboxPanels = () => {
  /**
   * Getter for the panels variable.
   * @type {ComputedRef<Record<PanelTypes, Panel>>}
   */
  const getPanels = computed(() => {
    return panels.value;
  });

  /**
   * Setter for the panels variable.
   * @param {Record<PanelTypes, Panel>} panelsData
   * @returns {Record<PanelTypes, Panel>}
   */
  const setPanels = (panelsData: Record<PanelTypes, Panel>) => {
    panels.value = panelsData;
  };

  /**
   * Reset panel data of a specified type.
   * @param {PanelTypes} type
   */
  const resetPanelData = (type: PanelTypes) => {
    panels.value[type].data = [];
  };

  //#region Annotations

  /**
   * Getter for the annotationPanelData variable.
   * @type {ComputedRef<Panel>}
   */
  const getAnnotationPanelData = computed(() => {
    return panels.value.annotations.data;
  });

  /**
   * Setter for the annotationPanelData variable.
   * @param {Panel} annotationPanelData
   * @returns {Panel}
   */
  const setAnnotationPanelData = (annotationPanelData: Panel["data"]) => {
    panels.value.annotations.data = annotationPanelData;
  };

  /**
   * Removes a single annotation from the annotations panel.
   * @param {number} cellId
   * @param {number} annotationId
   */
  const removeAnnotationById = (cellId: number, annotationId: number) => {
    for (const cell of panels.value.annotations.data) {
      if (cell.selectionId !== cellId) continue;

      cell.labels = cell.labels.filter((label) => {
        const thisLabel = label as unknown as Record<string, unknown>;
        return thisLabel.annotationId !== annotationId;
      });
    }
  };

  /**
   * Resets annotations panel data.
   */
  const resetAnnotationsPanelData = () => {
    resetPanelData("annotations");
  };

  //#endregion Annotations

  //#region Attributes
  /**
   * Adds a single attribute to the attributes panel.
   * @param {{value: string, attributeId: number, labels: PanelItemLabel[]}} attribute
   * @todo Fix the typescript error
   */
  const addAttributeToPanel = (attribute: {
    label: string;
    selectionId: number;
    labels: PanelItemLabel[];
    sortOrder: number;
  }) => {
    panels.value.attributes.data.unshift(attribute);
  };

  //#endregion Attributes

  /**
   * Resets attributes panel data.
   */
  const resetAttributesPanelData = () => {
    resetPanelData("attributes");
  };

  //#endregionregion Annotations

  /**
   * Resets configuration panel data.
   */
  const resetConfigurationPanelData = () => {
    resetPanelData("configuration");
  };

  return {
    getPanels,
    getAnnotationPanelData,
    addAttributeToPanel,
    setPanels,
    setAnnotationPanelData,
    removeAnnotationById,
    resetAnnotationsPanelData,
    resetAttributesPanelData,
    resetConfigurationPanelData,
  };
};
