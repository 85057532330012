import { AxiosResponse } from "axios";
import { interceptLoginSuccessful } from "@/interceptors/interceptLoginSuccessful";
import { isTargetEndpoint, isTargetStatus } from "@/utils";
import { Interceptor } from "@/ts/types";

/**
 * List of interceptors that need to be processed.
 * @type {Interceptor[]}
 */
const interceptors: Interceptor[] = [interceptLoginSuccessful];

/**
 * Executes a process interceptor if requested endpoint and response status match.
 * @param {AxiosResponse} response
 * @param {Interceptor} interceptor
 */
const processInterceptor = (
  response: AxiosResponse,
  interceptor: Interceptor
) => {
  if (!isTargetStatus(interceptor.status, response.status)) return;
  if (!isTargetEndpoint(interceptor.endpoint, response.request)) return;

  void interceptor.action(response);
};

/**
 * Runs all the Axios interceptors.
 * @param {AxiosResponse} response
 */
export const intercept = (response: AxiosResponse) => {
  interceptors.forEach((interceptor) => {
    processInterceptor(response, interceptor);
  });
};
