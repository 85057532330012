import { computed, Ref, ref } from "vue";
import { Route } from "vue-router";

const currentRoute: Ref<Route | undefined> = ref();

export const useRouter = () => {
  const setCurrentRoute = (route: Route) => {
    currentRoute.value = route;
  };

  const getCurrentRoute = () => {
    return computed(() => {
      return currentRoute.value;
    });
  };

  return { setCurrentRoute, getCurrentRoute };
};
