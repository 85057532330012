import Axios from "axios";
import { components, operations } from "@/ts/interfaces/ApiSchemas";
import qs from "qs";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");
const userUrl = `${baseUrl}/users`;

type getUsersResponse =
  components["schemas"]["CollectionModelUser"]["_embedded"];
type getUsersParams = operations["getUsers"]["parameters"]["query"];

type page = components["schemas"]["PageMetadata"];

/**
 * Gets all users from the group api
 * @param pageStart - page of which to start to get the user from
 * @param size - the length of the users you want to retrieve
 */

const getAllUsers = async (params?: getUsersParams) => {
  const queryString = qs.stringify(params, { arrayFormat: "comma" });

  const url = params !== "" ? `${userUrl}?${queryString}` : userUrl;

  const response = await Axios.get(url);

  if (response.status !== 200 || !response?.data) return [];

  const resData: getUsersResponse = response.data._embedded.users;
  const page: page = response.data.page;

  return { data: resData, page };
};

export { getAllUsers };
