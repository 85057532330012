import { Ref, ref, reactive } from "vue";
import { Mapping } from "@/ts/interfaces/api/Mapping";
import { getMappingsAndPages } from "@/api/mappings/getMappings";
import { deleteMapping } from "@/api";
import { MappingParams, Pages } from "@/ts/interfaces";

interface MappingComposition {
  mappings: Ref<Mapping[] | never[]>;
  updateMappings: (params: MappingParams) => Promise<Mapping[] | never[]>;
  deleteMappingCard: (id: number) => Promise<boolean>;
  pages: Pages;
}

/**
 * Requests mappings from the API and returns a reactive reference to it.
 * @returns {MappingComposition}
 */
const pages: Pages = reactive({
  number: 0,
  totalPages: 0,
  size: 9,
  totalElements: 0,
});

export const useMappings = (): MappingComposition => {
  const mappings: Ref<Mapping[] | never[]> = ref([]);

  const updateMappings = async (params: MappingParams) => {
    const res = await getMappingsAndPages(params);
    
    if (!res.page) {
      mappings.value = [];
      return [];
    }
    // set the page object with the pages recieved from api
    pages.number = res.page.number;
    pages.size = res.page.size;
    pages.totalElements = res.page.totalElements;
    pages.totalPages = res.page.totalPages;

    mappings.value = res._embedded.ontologyMappings;
    return res._embedded.ontologyMappings;
  };

  const deleteMappingCard = (mappingId: number): Promise<boolean> => {
    return deleteMapping(mappingId);
  };

  return {
    mappings,
    updateMappings,
    deleteMappingCard,
    pages,
  };
};
