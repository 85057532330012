import Axios from "axios";
import qs from "qs";
import { serverUrl } from "@/utils";
import { components, operations } from "@/ts/interfaces/ApiSchemas";


const baseUrl: string = serverUrl("api");
const mappingURL = `${baseUrl}/mappings/recommendMediatingVocab`;

type RecommendMediatingVocabResponse = Promise<(components["schemas"]["TermiteMediatingVocabSuggestion"])>;

/**
 * recommand mediating ontologies list
 * @param {number} mappingId
 * @returns {RecommendMediatingVocabResponse}
 */

const recommendMediatingOntology = async (params: operations["recommendMediatingVocab"]["parameters"]["query"]): RecommendMediatingVocabResponse => {
  const queryString = qs.stringify(params, { arrayFormat: "repeat" });
  const response = await Axios.get(`${mappingURL}?${queryString}`);
  return response.data;
};

export { recommendMediatingOntology };