import { Interceptor } from "@/ts/types";

import { getCurrentUserRole } from "@/api";
import { useNotifications, useUser } from "@/compositions";

import { MSG_LOGIN_SUCCESSFUL } from "@/globals";

/**
 * Intercepts a successful login request and notifies the user that login was successful.
 * @param {AxiosResponse} response
 * @return {Promise<void>}
 */
export const interceptLoginSuccessful: Interceptor = {
  endpoint: "login",
  status: 200,
  action: async () => {
    useUser().setLoggedIn(true);
    useUser().setUserRole(await getCurrentUserRole());

    useNotifications().pushNotification({
      type: "success",
      text: MSG_LOGIN_SUCCESSFUL,
      timeout: true,
    });
  },
};
