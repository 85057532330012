import Axios from "axios";
import { components, operations } from "@/ts/interfaces/ApiSchemas";
import qs from "qs";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");
const groupsURL = `${baseUrl}/groups`;

type getAllGroupsResponse = components["schemas"]["Group"][];
type getAllGroupsParams = operations["getGroups"]["parameters"]["query"];

/**
 * Gets groups from the group api
 */
const getAllGroups = async (
  params?: getAllGroupsParams
): Promise<getAllGroupsResponse | []> => {
  const queryString = qs.stringify(params, { arrayFormat: "comma" });

  const url = params !== "" ? `${groupsURL}?${queryString}` : groupsURL;

  const response = await Axios.get(url);

  if (!response.data._embedded?.groups) return [];

  return response.data._embedded.groups;
};

export { getAllGroups };
