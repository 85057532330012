import Axios from "axios";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");

const meUrl = `${baseUrl}/users/me/internal`;

/**
 * Retrieves user information from the api.
 * @returns Promise<'WORKBENCH_ADMIN' | 'WORKBENCH_USER' | ''>
 */
const getCurrentUserRole = async (): Promise<"WORKBENCH_ADMIN" | "WORKBENCH_USER" | ""> => {
  const response = await Axios.get(meUrl);
  const userRole = response.data?.role || "";
  if (!userRole) return "";

  return userRole;
};

export { getCurrentUserRole };
