import { EventSourcePolyfill } from "event-source-polyfill";
import useObservers from "@/compositions/useObservers";
import { MessageEventEncoded } from "@/ts/interfaces";
import { NotificationStatus } from "@/ts/types";
import { components } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";
import { AUTH_HEADER_PREFIX } from "@/globals";
import { Ref, ref } from "vue";

const baseUrl: string = serverUrl("api");

const endpoint = `${baseUrl}/notifications/stream`;

const source: Ref<any | undefined> = ref();
/**
 * Creates a notification stream using EventSourcePolyfill and
 * listens for subscribed actions when the stream receives an update.
 * @returns {Promise<void|undefined>}
 */
const streamNotifications = async (token: string) => {
  // to close the connection for reinitializing a new one.
  if (source.value) {
    source.value.close();
  }

  source.value = new EventSourcePolyfill(endpoint, {
    headers: {
      Authorization: AUTH_HEADER_PREFIX + token,
    },
  });
  
  /**
   * Implements the onmessage source event,
   * every message from the backend loops through the actions state object and runs them.
   * @param {MessageEvent} messageEvent
   * @emits LOGGED_IN
   * @emits TOKEN_VERIFICATION_SUCCEEDED
   * @emits ANNOTATION_STARTED
   * @emits ANNOTATION_RUNNING
   * @emits ANNOTATION_COMPLETE
   * @emits ANNOTATION_ERROR
   * @emits DATA_EXPORT_STARTED
   * @emits DATA_EXPORT_COMPLETE
   * @emits ONTOLOGY_MAPPING_STARTED
   * @emits ONTOLOGY_MAPPING_COMPLETE
   * @emits ONTOLOGY_MAPPING_ERROR
   * @emits MAPPING_EXPORT_STARTED
   * @emits MAPPING_EXPORT_COMPLETE
   */
   source.value.onmessage = (messageEvent: MessageEvent) => {
    const { notifyObservers } = useObservers();

    const messageEventEncoded: MessageEventEncoded = {
      ...messageEvent,
      data: JSON.parse(
        messageEvent.data
      ) as components["schemas"]["Notification"],
    };

    const datasetStatus: NotificationStatus =
      messageEventEncoded.data.datasetStatus;
    if (!datasetStatus) return;

    notifyObservers(datasetStatus, { event: messageEventEncoded });
  };

  /**
   * Handles connection error.
   * @todo prevent the 45 seconds timeout (eg. server sending an heartbeat every 30-40 seconds)
   * @param {ErrorEvent} errorEvent
   */
   source.value.onerror = (errorEvent: ErrorEvent) => {
   console.log(errorEvent);
  };
};

export { streamNotifications };
