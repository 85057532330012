<template>
  <v-tooltip
    :top="$attrs.top"
    :right="$attrs.right"
    :bottom="$attrs.bottom"
    :left="$attrs.left"
  >
    <template v-slot:activator="{ on, attrs }">
      <span v-on="on" v-bind="attrs">
        <v-btn
          class="dataset-table-data-actions__btn"
          :class="name"
          :loading="loading"
          v-bind="$attrs"
          @click="handleClick"
        >
          <v-icon>{{ iconCode }}</v-icon>
          <slot name="text"></slot>
        </v-btn>
      </span>
    </template>
    {{ tooltip }}
  </v-tooltip>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useActionButtons } from "@/compositions";

export default defineComponent({
  name: "ActionButton",
  props: {
    action: {
      type: String,
      default: "",
    },
    actionArgs: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    iconCode: {
      type: String,
      default: "",
    },
    tooltip: {
      type: String,
    },
    name: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const performAction = useActionButtons().performAction;

    /**
     * If an action name is specified performs the action using the useActionButtons composition
     * otherwise just emit a click event.
     * @param {MouseEvent} e
     */
    const handleClick = (e: MouseEvent) => {
      if (props.action === "") emit("click", e);
      else performAction(props.action, e, props.actionArgs);
    };

    return { handleClick };
  },
});
</script>
