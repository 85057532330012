import Axios from "axios";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");

/**
 * Deletes a user from the database
 * @param userId - The id of the user you want to delete
 */
const deleteUser = async (userId: number) => {
  const datasetURL = `${baseUrl}/users/internal/${userId}`;

  const response = await Axios.delete(datasetURL);

  if (!response.data) {
    return Promise.reject("Could not be deleted");
  }

  return response.data;
};

export { deleteUser };
