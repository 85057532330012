import Axios from "axios";
import { Mapping } from "@/ts/interfaces";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");

type MappingResponse = Promise<Mapping | undefined>;

/**
 * Get a mapping for a specified mapping id.
 * @param {number} mappingId
 * @returns {MappingResponse}
 */

const getMappingById = async (mappingId: number): MappingResponse => {
  const url = `${baseUrl}/mappings/${mappingId}`;

  const response = await Axios.get(url);

  return response.data;
};

export { getMappingById };
