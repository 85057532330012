import Axios from "axios";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const annotationsUrl = `${baseUrl}/datasets`;

type getSourcesLookupResponse =
  operations["getAnnotatedSources"]["responses"]["200"]["content"]["application/hal+json"];

/**
 * Gets the annotation sources from the api
 * @param {operations["getAnnotated"]["parameters"]["path"]["id"]} datasetId
 * @return {Promise<getSourcesLookupResponse>}
 */
const getSourcesLookup = async (
  datasetId: operations["getAnnotated"]["parameters"]["path"]["id"]
): Promise<getSourcesLookupResponse> => {
  const url = `${annotationsUrl}/${datasetId}/annotated/sourcesLookup`;

  const response = await Axios.get(url);

  return response.data || [];
};

export { getSourcesLookup };
