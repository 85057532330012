<template>
  <div class="toast-alerts">
    <v-fade-transition group class="toast-alerts-transition">
      <v-alert
        v-for="(notification, index) in notificationsList"
        :key="index"
        :prominent="notification.type === 'error'"
        dismissible
        transition="scale-transition"
        border="left"
        elevation="2"
        class="toast-alerts-transition__alert ma-1"
        :type="notification.type"
        :icon="alertIcon(notification)"
        @input="removeNotification(notification.uuid)"
      >
        <div class="toast-alert-text__wrapper overflow-y-auto">
          <h1
            v-if="notification.title"
            class="toast-alerts-transition__alert__title"
          >
            {{ notification.title }}
          </h1>
          <span class="toast-alerts-transition__alert__text">{{
            notification.text
          }}</span>
        </div>
      </v-alert>
    </v-fade-transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useNotifications } from "@/compositions";
import { Notification } from "@/ts/interfaces";

export default defineComponent({
  name: "ToastAlert",
  setup() {
    /**
     * Returns the icon for the alert. If no custom icon is defined in the `alert` parameter will choose
     * the default icon related to the type. If no type is defined will return 'mdi-alert'.
     * @param {Notification} alert
     * @returns {string}
     */
    const alertIcon = (alert: Notification): string => {
      if (alert.icon) return alert.icon;

      switch (alert.type) {
        case "success":
          return "mdi-check-bold";
        case "info":
          return "mdi-information";
        case "error":
          return "mdi-alert-octagram";
        case "warning":
          return "mdi-alert";
        default:
          return "mdi-alert";
      }
    };

    const { notificationsList, removeNotification } = useNotifications();

    return { notificationsList, alertIcon, removeNotification };
  },
});
</script>

<style scoped lang="scss">
.toast-alerts {
  position: fixed;
  right: 0;
  max-width: 50%;
  z-index: 999999;

  .toast-alerts-transition {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    position: relative;
    z-index: 10;

    &__alert {
      margin: 1rem;
      width: 100%;

      overflow: scroll;
      top: 20px;
      margin-bottom: 20px;
      margin-right: 20px;

      &__title {
        font-size: 1.2rem;
      }
    }
  }
}
</style>
