import Axios from "axios";
import { components } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const annotationsUrl = `${baseUrl}/datasets`;

type AnnotationRunConfig = components["schemas"]["AnnotationRunConfig"][];
type AnnotateWithTermiteResponse = Promise<
  components["schemas"]["Annotation"][]
>;

/**
 * Auto annotate multiple values based on a filter given a specified configuration.
 * @param {number} datasetId
 * @param {autoAnnotateWithTermiteQuery} query
 * @param {termiteConfig} termiteConfig
 * @return {AnnotateWithTermiteResponse}
 */
const autoAnnotateWithTermite = async (
  datasetId: number,
  annotationRunConfig: AnnotationRunConfig,
  replace = false
): AnnotateWithTermiteResponse => {
  const url = `${annotationsUrl}/${datasetId}/autoAnnotateWithTermite?replace=${replace}`;
  const response = await Axios.post(url, annotationRunConfig);

  return response.data;
};

export { autoAnnotateWithTermite };
