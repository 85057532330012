import Axios from "axios";
import { components } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");

type UpdateAttributeParams = components["schemas"]["Attribute"];

/**
 * Updates an attribute (Header Cell).
 * @param {number} datasetId
 * @param {number | undefined} id
 * @param {number} columnIndex
 * @param {string | undefined} name
 * @param {string | undefined} uri
 * @return {Promise<void>}
 */
const updateAttribute = async (
  datasetId: number,
  { id, columnIndex, name, uri, termiteConfig }: UpdateAttributeParams
): Promise<components["schemas"]["Attribute"]> => {
  const endpoint = `${baseUrl}/datasets/${datasetId}/attributes/${id}`;

  const response = await Axios.put(endpoint, {
    columnIndex,
    name,
    uri,
    termiteConfig,
  });

  return response.data;
};

export { updateAttribute };
