import Axios from "axios";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");
const datasetURL = `${baseUrl}/datasets`;

type createDatasetResponse =
  operations["create"]["responses"]["200"]["content"]["application/hal+json"];

/**
 * Creates a dataset from the API dataset controller
 * @param {String} title The title you wish the dataset to have
 * @param {String} description The description you wish the dataset to have
 * @param {String} uploadedFileName The file name of the dataset
 * @param {String} datasetStatus Leave blank if dataset is empty
 */
const createDataset = async (
  title: string,
  description?: string,
  uploadedFileName?: string,
  datasetStatus?: string
): Promise<createDatasetResponse | undefined> => {
  const response = await Axios.post(datasetURL, {
    title,
    description,
    uploadedFileName,
    datasetStatus,
  });

  if (!response.data) return undefined;

  return response.data;
};

export { createDataset };
