import Axios from "axios";
import qs from "qs";
import { operations, components } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const annotationsUrl = `${baseUrl}/datasets`;

type getUnannotatedQuery = operations["getUnAnnotated"]["parameters"]["query"];
type getUnannotatedResponse = Promise<
  components["schemas"]["CollectionModelUnannotatedValue"]
>;

/**
 * Makes the request ot the unannotated table endpoint
 * @param {getUnannotatedQuery} query
 * @return {getUnannotatedResponse}
 */
const getUnannotated = async (
  datasetId: number,
  query: getUnannotatedQuery
): getUnannotatedResponse => {
  const queryString = qs.stringify(query, {
    arrayFormat: "comma",
    encode: false,
  });
  const url = `${annotationsUrl}/${datasetId}/unannotated?${queryString}`;

  const response = await Axios.get(url);

  return response.data || [];
};

export { getUnannotated };
