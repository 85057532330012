enum CaretLocation {
  "START",
  "END",
}

/**
 * Focuses on an HTML element at a specified caret position.
 * @param {HTMLElement} element
 * @param {CaretLocation} position
 */
export const focusAtCaretLocation = (
  element: HTMLElement,
  position: CaretLocation = CaretLocation.END
) => {
  const selection = window.getSelection();
  const range = document.createRange();

  range.setStart(element, position);

  selection?.removeAllRanges();
  selection?.addRange(range);
};
