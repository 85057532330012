import Axios from "axios";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const ruleUrl = `${baseUrl}/rules`;

type editRuleRequestBody =
  operations["updateRule"]["requestBody"]["content"]["application/json"];
type editRuleResponse = Promise<
  operations["updateRule"]["responses"]["200"]["content"]["application/hal+json"]
>;

const editRule = async (rule: editRuleRequestBody): editRuleResponse => {
  const url = `${ruleUrl}/${rule.id}`;
  const response = await Axios.put(url, rule);

  return response.data;
};

export { editRule };
