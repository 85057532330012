import Axios from "axios";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");

type deactivateVocabResponse =
  operations["deactivateVocab"]["responses"]["200"]["content"];
/**
 * deactivates a singular vocab from the api
 * @returns Promise<(Array<Vocab> | undefined)>
 */
const deactivateVocab = async (
  termiteId: string
): Promise<deactivateVocabResponse | undefined> => {
  const vocabsUrl = `${baseUrl}/vocabs/deactivate/${termiteId}`;

  const response = await Axios.put(vocabsUrl);

  return response.data || undefined;
};

export { deactivateVocab };
