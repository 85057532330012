import { computed, ref, Ref } from "vue";
import { DialogueProps } from "@/ts/interfaces";
import { AppDialogAction } from "@/ts/types";

const showDialog: Ref<boolean> = ref(false);
const dialogTitle: Ref<string> = ref("");
const displayCancelButton: Ref<boolean> = ref(false);
const confirmButtonDisabled: Ref<boolean> = ref(false);
const confirmButtonText: Ref<string> = ref("OK");
const dialogConfirm: Ref<AppDialogAction | undefined> = ref();
const dialogConfirmArgs: Ref<unknown> = ref();
const dialogOptionsArgs: Ref<unknown> = ref();
const dialogCancel: Ref<AppDialogAction | undefined> = ref();
const dialogProps: Ref<DialogueProps | undefined> = ref();
const dialogWidth: Ref<sizes> = ref(1000);

type sizes = 1000 | 800 | 500 | 300;

export const useAppDialog = () => {
  const getShowDialog = () => {
    return showDialog;
  };

  const getDisplayCancelButton = () => {
    return displayCancelButton;
  };

  const getConfirmButtonDisabled = () => {
    return confirmButtonDisabled;
  };

  const getConfirmButtonText = () => {
    return confirmButtonText;
  };

  const getDialogueWidth = () => {
    return dialogWidth.value;
  };

  const getArgs = computed(() => {
    return dialogProps.value?.args;
  });

  const getTitle = computed(() => {
    return dialogTitle.value;
  });

  const getCurrentDialog = () => {
    return {
      dialogTitle: dialogTitle,
      confirmAction: dialogConfirm,
      cancelAction: dialogCancel,
      dialogProps: dialogProps,
    };
  };

  const getConfirmProps = computed(() => {
    return dialogProps.value;
  });

  const confirm = (event: Event) => {
    const args = dialogConfirmArgs.value;
    dialogConfirm.value && dialogConfirm.value(args, event);
  };

  const cancel = (event: Event, args: unknown) => {
    dialogCancel.value && dialogCancel.value(args, event);
  };

  const setProps = (args: DialogueProps | undefined = undefined) => {
    dialogProps.value = args;
  };

  const setConfirmButtonText = (text: string) => {
    confirmButtonText.value = text;
  };

  const setConfirmAction = (action: AppDialogAction) => {
    dialogConfirm.value = action;
  };

  const setConfirmOptionsArgs = (args: unknown) => {
    dialogOptionsArgs.value = args;
  };

  const getConfirmOptionsArgs = computed(() => {
    return dialogOptionsArgs.value;
  });

  const setConfirmActionArgs = (args: unknown) => {
    dialogConfirmArgs.value = args;
  };

  const getConfirmActionArgs = computed(() => {
    return dialogConfirmArgs.value;
  });

  const setCancelAction = (action: AppDialogAction) => {
    dialogCancel.value = action;
  };

  const setConfirmButtonDisabled = (isDisabled: boolean) => {
    confirmButtonDisabled.value = isDisabled;
  };

  const setDialogueWidth = (width: sizes) => {
    dialogWidth.value = width;
  };

  const setDialog = (
    show: boolean,
    title: string,
    options:
      | {
          confirmAction?: <T>(args?: T) => Promise<void>;
          confirmButtonDisabled?: boolean;
          confirmButtonText?: string;
          cancelAction?: AppDialogAction;
          showCancelButton?: boolean;
          props?: DialogueProps;
        }
      | undefined = undefined,
    width: sizes = 1000
  ) => {
    showDialog.value = show ? show : showDialog.value;
    dialogTitle.value = title ? title : dialogTitle.value;
    setDialogueWidth(width);

    if (!options) return;

    displayCancelButton.value =
      options.showCancelButton !== undefined ? options.showCancelButton : false;
    dialogConfirm.value = options.confirmAction
      ? options.confirmAction
      : dialogConfirm.value;
    dialogCancel.value = options.cancelAction
      ? options.cancelAction
      : dialogCancel.value;
    dialogProps.value = options.props ? options.props : dialogProps.value;
    confirmButtonDisabled.value =
      options.confirmButtonDisabled !== undefined
        ? options.confirmButtonDisabled
        : false;
    confirmButtonText.value = options.confirmButtonText || "OK";
  };

  const unsetDialog = () => {
    showDialog.value = false;
    dialogTitle.value = "";
    dialogConfirm.value = undefined;
    dialogCancel.value = undefined;
    dialogProps.value = undefined;
    confirmButtonText.value = "OK";
    confirmButtonDisabled.value = false;
    displayCancelButton.value = false;
    dialogWidth.value = 1000;
  };

  const setShowDialog = (show: boolean) => {
    showDialog.value = show;
  };

  const enableConfirmButton = () => {
    setConfirmButtonDisabled(false);
  };

  const disableConfirmButton = () => {
    setConfirmButtonDisabled(true);
  };

  return {
    getTitle,
    getConfirmProps,
    getConfirmOptionsArgs,
    getConfirmActionArgs,
    getArgs,
    getDialogueWidth,
    setDialogueWidth,
    enableConfirmButton,
    disableConfirmButton,
    setConfirmAction,
    setCancelAction,
    setConfirmButtonDisabled,
    getShowDialog,
    getDisplayCancelButton,
    getConfirmButtonDisabled,
    getConfirmButtonText,
    getCurrentDialog,
    setDialog,
    setShowDialog,
    setConfirmButtonText,
    setConfirmOptionsArgs,
    setConfirmActionArgs,
    unsetDialog,
    confirm,
    cancel,
    setProps,
  };
};
