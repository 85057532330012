<template>
  <v-layout>
  <main-module-bar  />
  <v-main class="main--container__wrapper">
    <v-container>
      <v-row class="text-center">
        <v-col cols="12">
          <router-view />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
  </v-layout>
</template>

<script lang="ts">
import { defineComponent, onUnmounted } from "vue";
import { useScrollSpy } from "@/compositions";
import { debounce } from "lodash";
import MainModuleBar from "./MainModuleBar.vue";

export default defineComponent({
  name: "MainContent",
  components: {
    MainModuleBar,
  },
  setup() {
    const scrollSpy = useScrollSpy();

    function handleScrollEvent() {
      scrollSpy.setMainCurrentContainerScroll(window.top.scrollY);
    }
    const debouncedHandleScrollEvent = debounce(handleScrollEvent, 100);

    window.addEventListener("scroll", debouncedHandleScrollEvent);

    onUnmounted(() => {
      window.removeEventListener("scroll", debouncedHandleScrollEvent);
    });
  },
});
</script>

<style lang="scss">
.main--container__wrapper {
  background-color: #f5f5f5;
}
.container {
  max-width: 100%;
  margin-bottom: 50px;
}
</style>
