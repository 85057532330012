<template>
  <div class="jobs-queue">
    <div class="jobs-queue-items">
      <job-queue-item
        v-for="job in currentJobs"
        :job="job"
        :key="job.id"
      ></job-queue-item>
    </div>
    <div class="job-queue-items-more">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-2"
            color="primary"
            outlined
            small
            v-bind="attrs"
            v-on="on"
            :disabled="allJobs.length === 0"
          >
            Show all
          </v-btn>
        </template>
        <v-list>
          <job-queue-item
            v-for="job in allJobs"
            :job="job"
            :key="job.id"
          ></job-queue-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount } from "vue";
import { useJobsQueue, useObservers } from "@/compositions";

import JobQueueItem from "./JobQueueItem.vue";
import { reverse } from "lodash";

export default defineComponent({
  name: "JobsQueue",
  components: { JobQueueItem },
  setup() {
    const { runningJobs } = useJobsQueue();

    onBeforeMount(() => {
      const { registerObserver } = useObservers();
      registerObserver("DATA_IMPORT_STARTED", {
        observer: "STACK_QUEUED_JOB",
        args: { runningJobs },
      });
      registerObserver("DATA_IMPORT_COMPLETE", {
        observer: "MARK_QUEUED_JOB_COMPLETE",
        args: { runningJobs },
      });
      registerObserver("DATA_IMPORT_ERROR", {
        observer: "MARK_QUEUED_JOB_COMPLETE",
        args: { runningJobs },
      });
      registerObserver("ANNOTATION_STARTED", {
        observer: "STACK_QUEUED_JOB",
        args: { runningJobs },
      });
      registerObserver("ANNOTATION_COMPLETE", {
        observer: "MARK_QUEUED_JOB_COMPLETE",
        args: { runningJobs },
      }); 
      registerObserver("ONTOLOGY_MAPPING_STARTED", {
        observer: "STACK_QUEUED_JOB",
        args: { runningJobs },
      }); 
      registerObserver("ONTOLOGY_MAPPING_COMPLETE", {
        observer: "MARK_QUEUED_JOB_COMPLETE",
        args: { runningJobs },
      });
      registerObserver("ONTOLOGY_MAPPING_ERROR", {
        observer: "MARK_QUEUED_JOB_COMPLETE",
        args: { runningJobs },
      });
    }); 

    const currentJobs = computed(() => {
      if (runningJobs.value.list.length < 4) return reverse(runningJobs.value.list);
      else return reverse(runningJobs.value.list).slice(0, 4);
    });

    const allJobs = computed(() => runningJobs.value.list);
    return { currentJobs, allJobs };
  },
});
</script>

<style lang="scss" scoped>
.jobs-queue {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  .jobs-queue-items {
    display: flex;
    width: auto;
  }
  .job-queue-items-more {
    display: flex;
    align-items: center;
  }
}
</style>
