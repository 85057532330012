<template>
  <v-app id="sbwb" class="layout-bare-bones">
    <toast-alert />
    <main-content />
  </v-app>
</template>

<script>
import MainContent from "@/components/layout/MainContent.vue";
import ToastAlert from "@/components/common/ToastAlert.vue";

export default {
  name: "BareBones",
  components: { MainContent, ToastAlert },
};
</script>
