import Axios from "axios";
import qs from "qs";
import { components } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const mappingsUrl = `${baseUrl}/mappings`;

type verifyMappingsRequest = components["schemas"]["VerifyMappingRequest"]
type verifyMappingsResponse = Promise<boolean>;

/**
 * Verify mappings by Ids
 * @param {number} mappingId - The id of the mapping dataset that the change needs to occur on
 * @param {verifyMappingsRequest} params - The params needed for the request
 * @return {verifyMappingsResponse}
 */
const verifyMappings = async (
  mappingId: number,
  requestBody: verifyMappingsRequest
): verifyMappingsResponse => {
  const url = `${mappingsUrl}/${mappingId}/verifyMapping`;
  const response = await Axios.put(url, requestBody);
  return response.status === 200;
};

export { verifyMappings };
