import { computed, ref, Ref } from "vue";
import { DatasetTable, DatasetTableCell, DatasetTableRow } from "@/ts/interfaces";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { getAnnotationSummaries as requestAnnotationSummaries } from "@/api";

type AnnotationSummaries =
  operations["getAnnotationSummaries"]["responses"]["200"]["content"]["application/hal+json"];

/**
 * The number of annotations per row
 * @type {Ref<UnwrapRef<AnnotationSummaries>>}
 */
const annotationSummaries: Ref<AnnotationSummaries> = ref([]);

/**
 * The current dataset ID.
 * @type {Ref<UnwrapRef<string>>}
 */
const datasetId: Ref<string> = ref("");

/**
 * The total number of rows in whole the table.
 * @type {Ref<UnwrapRef<number>>}
 */
const totalItems: Ref<number> = ref(0);

/**
 * The total number of rows in a page.
 * @type {Ref<UnwrapRef<number>>}
 */
const itemsPerPage: Ref<number> = ref(25);

/**
 * [description]
 */
const rowsPerPage: Ref<Array<number>> = ref([25, 50, 100, 200]);

/**
 * Contains the current table row IDs for quick access.
 * @type {{value: number[]}}
 */
const rowIds: { value: number[] } = { value: [] };

/**
 * The rows that are currently displayed in the table.
 * @type {Ref<UnwrapRef<(Map<string, DatasetTableCell>|null)[]>>}
 */
const tableRows: Ref<Array<Map<string, DatasetTableCell> | null>> = ref([]);

export const useDatasetTable = () => {
  const getAnnotationSummaries = computed(() => {
    return annotationSummaries.value;
  });

  /**
   * Computes an array of cells IDs or undefined if a cell does not have any annotation attached.
   * @type {ComputedRef<(number | undefined)[]>}
   */
  const getCellsWithAnnotations = computed((): (number | undefined)[] => {
    return annotationSummaries.value.length
      ? annotationSummaries.value.map((value) => value.valueId)
      : [];
  });

  const getDatasetId = computed(() => {
    return datasetId.value;
  });

  /**
   * Getter for the rowIds variable.
   * @type {ComputedRef<number[]>}
   */
  const getRowIds = computed(() => {
    return rowIds.value;
  });

  /**
   * Getter for the tableRows variable.
   * @type {ComputedRef<(Map<string, DatasetTableCell>|null)[]>}
   */
  const getTableRows = computed(() => {
    return tableRows.value;
  });

  /**
   * Calculates the tabindex of the first cell of a row.
   * @param {DatasetTable} rowData The row elements.
   * @returns {String} The first cell index.
   */
  const calculateFirstCellIndex = (rowData: DatasetTable): string => {
    const firstIndex = parseInt(Object.keys(rowData.values)[0]);
    return (firstIndex - 1).toString();
  };

  /**
   * Loops through the cells of a given row and fills `id` and `value` properties of null cells.
   * @param {DatasetTable} rowData
   */
  const populateEmptyCells = (rowData: DatasetTable) => {
    Object.entries(rowData.values).forEach((entry) => {
      const cellId = entry[0];
      const cellValue = entry[1];

      if (!cellValue.value)
        rowData.values[cellId] = { id: cellId, value: null, columnIndex: cellValue.columnIndex, links: {} }
      
    });
  };

  const setAnnotationSummaries = (summaries: AnnotationSummaries) => {
    annotationSummaries.value = summaries;
  };

  const setDatasetId = (id: string) => {
    datasetId.value = id;
  };

  /**
   * Setter for the rowIds variable.
   * @param {number[]} rows
   * @returns {number[]}
   */
  const setRowIds = (rows: number[]) => {
    rowIds.value = rows;
  };

  /**
   * Setter for the tableRows variable.
   * @param {(DatasetTableRow|null)[]} rows
   * @returns {(DatasetTableRow|null)[]}
   */
  const setTableRows = (rows: (Map<string, DatasetTableCell>| null)[]) => {
    tableRows.value = rows;
  };

  /**
   * Computes the total number of pages of the table.
   * @type {Number}
   */
  const getTablePagesLength = computed(() => {
    return Math.ceil(totalItems.value / itemsPerPage.value);
  });

  const resetTableRows = () => {
    tableRows.value = [];
  };

  const setItemsPerPage = (rows: number) => {
    const storedItemsPerPage = window.localStorage.getItem("itemsPerPage");
    if (storedItemsPerPage) {
      itemsPerPage.value = parseInt(storedItemsPerPage);
    } else {
      itemsPerPage.value = rows;
    }
    window.localStorage.setItem("itemsPerPage", rows.toString());
  };

  const getItemsPerPage = (): number => {
    const storedItemsPerPage = window.localStorage.getItem("itemsPerPage");
    if (storedItemsPerPage) {
      return parseInt(storedItemsPerPage);
    } else {
      return itemsPerPage.value;
    }
  };

  /**
   * Requests dataset summaries of which cell is annotated and assigns it to annotationSummaries reactive variable.
   * @return {Promise<void>}
   */
  const loadAnnotationSummaries = async () => {
    const rowIds = getRowIds.value;

    if (!rowIds.length) return;

    const annotationSummaries = await requestAnnotationSummaries(
      parseInt(datasetId.value),
      { rowIds }
    );

    // if (!annotationSummaries?.length) return;

    setAnnotationSummaries(annotationSummaries);
  };

  /**
   * Updates the rowIds variable with the ids of currently displayed rows.
   * @param {DatasetTable|null} tableRows
   */
  const updateRowIds = (tableRows: Array<DatasetTable | null>) => {
    const rowIds: number[] = [];

    if (tableRows?.length) {
      tableRows.forEach((row) => {
        if (row?.rowId) rowIds.push(parseInt(row.rowId));
      });
    }

    useDatasetTable().setRowIds(rowIds);
  };

  return {
    itemsPerPage,
    rowsPerPage,
    getAnnotationSummaries,
    getCellsWithAnnotations,
    getDatasetId,
    getRowIds,
    getTableRows,
    getTablePagesLength,
    calculateFirstCellIndex,
    populateEmptyCells,
    setAnnotationSummaries,
    setDatasetId,
    setTableRows,
    setRowIds,
    resetTableRows,
    setItemsPerPage,
    getItemsPerPage,
    loadAnnotationSummaries,
    updateRowIds,
  };
};
