import Axios from "axios";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");

/**
 * Adds a groups from the group api
 * @param groupId - The id of the goup you want to remove the users from
 * @param userIds - An array of user ids that you want to remove from a group
 */
const removeGroupMembers = async (groupId: number, userIds: number[]) => {
  const datasetURL = `${baseUrl}/groups/${groupId}/members`;

  const response = await Axios.delete(datasetURL, {
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userId: userIds,
      permission: "RO",
    },
  });

  if (!response.data) return [];

  return response.data;
};

export { removeGroupMembers };
