<template>
  <div v-if="isLoggedIn">
    <v-navigation-drawer
      id="main-module-bar"
      expand-on-hover
      rail
      class="main-module-bar"
      :clipped="$vuetify.breakpoint.lgAndUp">
      <v-list
            :lines="false"
            density="compact"
            nav
          >
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
              :value="item"
              :[item.typeOfLink]="item.link"
              active-color="primary"
              link
              :id="item.divId"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
  </v-navigation-drawer>
  </div>
</template>

<script lang="ts">
import { useLoginState } from "@/compositions";
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "MainToolbox",
 
  setup() {
    const isLoggedIn = computed(() => { return useLoginState().isLoggedIn.value; });
    const items = [
      {
        divId: "datasetMenu",
        text: 'Data Annotation',
        icon: 'mdi-file-table',
        link: "/",
        typeOfLink: "to",
      },
      {
        divId: "mappingMenu",
        text: 'Mapping',
        icon: 'mdi-alpha-m-box',
        link: "/mappings",
        typeOfLink: "to",
      }
    ];
    
    return {
      isLoggedIn,
      items,
    };
  },
});
</script>
<style scoped>
.main-module-bar {
  margin-top:74px;
}
</style>
