import { computed, ComputedRef, Ref, ref } from "vue";
import { SelectedColumn, TableSelection } from "@/ts/interfaces";
import { SelectionTypes } from "@/ts/types";
import { useAttributes } from "@/compositions/useAttributes";
import { v4 as generateUuid } from "uuid";
import { useObservers } from "@/compositions/useObservers";
import { useContext } from "@/compositions/useContext";

interface SelectionComposition {
  getSelection: ComputedRef<TableSelection | undefined>;
  setSelection: (currentSelection: TableSelection) => void;
  unsetSelection: () => void;
  selectionType: ComputedRef<SelectionTypes | undefined>;
  selectedColumns: ComputedRef<SelectedColumn[]>;
  columnIds: ComputedRef<number[]>;
  datasetId: ComputedRef<number | undefined>;
  vocabsIdsByAttributeIds: ComputedRef<Record<number, number[]>>;
  vocabIds: ComputedRef<number[]>;
  refreshSelection: () => void;
  uniqueVocabIdsCommonToSelectedAttributes: ComputedRef<number[]>;
  isSelectionEmpty: ComputedRef<boolean>;
}

/**
 * Reactive reference to the current selected object, which could be:
 * - Single or multiple values (multiple yet to implement)
 * - Single or multiple attributes
 * @type {Ref<TableSelection | undefined>}
 */
const selection: Ref<TableSelection | undefined> = ref();

/**
 * Requests datasets from the API and returns a reactive reference to it.
 * @returns {DatasetComposition}
 */
export const useSelection = (): SelectionComposition => {
  /**
   * Retrieves the current selection.
   * @type {ComputedRef<TableSelection | undefined>}
   */
  const getSelection = computed(() => {
    return selection.value;
  });

  const notifyObservers = () => {
    const context = useContext().getContext;
    void useObservers().notifyObservers("SELECTION_CHANGED", { context });
  };

  /**
   * Sets the current selection.
   * @param {TableSelection} currentSelection
   */
  const setSelection = (currentSelection: TableSelection) => {
    currentSelection.selectionId = generateUuid();
    selection.value = currentSelection;
    notifyObservers();
  };

  /**
   * Unset the current selection.
   */
  const unsetSelection = () => {
    selection.value = undefined;
    notifyObservers();
  };

  /**
   * Force an update of the current selection by re-generating the selection id.
   */
  const refreshSelection = () => {
    if (selection.value)
      selection.value = {
        ...selection.value,
        selectionId: generateUuid(),
      };
  };

  /**
   * Retrieves the current selection type.
   * @type {ComputedRef<"cell" | "column" | undefined>}
   */
  const selectionType = computed((): SelectionTypes | undefined => {
    return selection.value && selection.value.type;
  });

  /**
   * Retrieves the selected columns from the current selection.
   * @type {ComputedRef<SelectedColumn[]>}
   */
  const selectedColumns = computed((): SelectedColumn[] => {
    return getSelection?.value?.data?.selectedColumns || [];
  });

  /**
   * Retrieves the selected columns ids from the current selection.
   * @type {ComputedRef<any>}
   */
  const columnIds: ComputedRef<number[]> = computed(() => {
    if (!selectedColumns.value.length) return [];

    return selectedColumns.value.map((selectedCol) => selectedCol.id);
  });

  /**
   * Retrieves the dataset id of the current selection.
   * @type {ComputedRef<number | undefined>}
   */
  const datasetId = computed((): number | undefined => {
    return selection?.value?.datasetId;
  });

  /**
   * Retrieves the vocabs ids of the selected attributes, shows them in an
   * associative array having per key the attribute it and value the vocab ids.
   * @type {ComputedRef<Record<number, number[]>>}
   */
  const vocabsIdsByAttributeIds = computed((): Record<number, number[]> => {
    if (!selectedColumns?.value.length) return {};

    const attributes = useAttributes().datasetAttributes.value;

    const selectedVocabs: Record<number, number[]> = {};

    attributes.forEach((attribute) => {
      if (!attribute.id || !columnIds.value.includes(attribute.id)) return;

      const vocabsIds = attribute.termiteConfig?.vocabIds || [];

      selectedVocabs[attribute.id] = vocabsIds;
    });

    return selectedVocabs;
  });

  /**
   * Returns a collection of vocab ids which are common to all selected attributes.
   * @type {ComputedRef<number[]>}
   */
  const uniqueVocabIdsCommonToSelectedAttributes = computed((): number[] => {
    const vocabsIdsByAttribute = Object.values(vocabsIdsByAttributeIds.value);
    let commonVocabIds: number[] = [];

    if (!vocabsIdsByAttribute.length) return [];

    vocabsIdsByAttribute.forEach((vocabIdsGroup) => {
      commonVocabIds = [...commonVocabIds, ...vocabIdsGroup];
    });

    return commonVocabIds.filter(
      (currentValue, index, array) => array.indexOf(currentValue) !== index
    );
  });

  /**
   * Retrieves the vocabs ids of the selected attributes
   * @type {ComputedRef<any[] | number[]>}
   */
  const vocabIds = computed(() => {
    if (!selectedColumns?.value.length) return [];

    const selectedVocabsByAttrId = Object.entries(
      vocabsIdsByAttributeIds.value
    );
    let selectedVocabs: number[] = [];

    selectedVocabsByAttrId.forEach((selectedVocab) => {
      const vocabsIds = selectedVocab[1];
      selectedVocabs = [...selectedVocabs, ...vocabsIds];
    });

    return selectedVocabs;
  });

  /**
   * Checks if any element has been selected.
   * @type {ComputedRef<boolean>}
   */
  const isSelectionEmpty: ComputedRef<boolean> = computed((): boolean => {
    let isEmpty = true;

    if (getSelection.value?.data.selectedColumns?.length) isEmpty = false;

    if (getSelection.value?.data.selectedCell) isEmpty = false;

    return isEmpty;
  });

  return {
    columnIds,
    datasetId,
    getSelection,
    unsetSelection,
    selectedColumns,
    refreshSelection,
    selectionType,
    setSelection,
    vocabIds,
    vocabsIdsByAttributeIds,
    uniqueVocabIdsCommonToSelectedAttributes,
    isSelectionEmpty,
  };
};
