import { MiddlewareContext } from "@/ts/interfaces";
import { useUser } from "@/compositions";
import { useKeycloak } from "@/compositions/useKeycloak";

/**
 * Allows only logged in users to navigate the requested route. Non-logged users will be redirected to the /login url.
 * @param {Route} to
 * @param {(to?: (RawLocation | false | ((vm: Vue) => any) | void)) => void} next
 * @param {VueRouter} router
 * @return {Promise<void>}
 */
export const authenticateUser = async ({
  to,
  next,
  router,
}: MiddlewareContext) => {
  const {
    setUserAuthorisationComplete,
  } = useUser();

  switch (true) {
    case to.name === "Login":
    case to.name === "Register":
    case to.name === "Logout":
      setUserAuthorisationComplete();
      return next();
      break;
    default: {
      if (!useKeycloak().authenticated) await router.push("/login");

      setUserAuthorisationComplete();
    }
  }
  next();
};
