import Axios from "axios";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");
const datasetsUrl = `${baseUrl}/datasets`;

type ChangeDatasetGroupOwnerResponse =
  operations["changeGroup"]["responses"]["200"]["content"]["application/hal+json"];

/**
 * Changes the datasets owners
 * @param datasetId - The id of the dataset you want to change the owner for
 * @param userId - The group id you want the dataset to transfer to
 */
const changeDatasetOwner = async (
  datasetId: number,
  userId: number
): Promise<ChangeDatasetGroupOwnerResponse | undefined> => {
  const url = `${datasetsUrl}/${datasetId}/changeOwner/${userId}`;
  const response = await Axios.put(url);

  return response?.data || undefined;
};

export { changeDatasetOwner };
