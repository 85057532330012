<template>
  <v-chip
    filter
    close
    small
    label
    color="orange"
    @click:close="$emit('click:close')"
  >
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <span class="panel-item-chip__text" v-bind="attrs" v-on="on">{{
          label
        }}</span>
      </template>
      {{ label }}
    </v-tooltip>
  </v-chip>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "VocabChip",
  props: {
    label: {
      type: String,
      required: true,
    },
  },
});
</script>
