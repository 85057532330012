import { computed, Ref, ref, watch } from "vue";
import { useUser } from "@/compositions/useUser";
import { verifyToken } from "@/api";

const isLoggedIn: Ref<boolean> = ref(false);
const appIsReady: Ref<boolean> = ref(false);
const tokenVerified: Ref<boolean> = ref(false);

export const useLoginState = () => {
  const {
    getLoginState,
    setTokenVerificationComplete,
    getUserAuthorisationState,
  } = useUser();

  const getAppIsReady = computed(() => {
    return appIsReady.value;
  });

  watch(
    () => getLoginState.value,
    async (loginState) => {
      isLoggedIn.value = loginState === "logged-in" ? true : false;
    }
  );

  /**
   * Getter for the tokenVerified variable.
   * @type {ComputedRef<boolean>}
   */
  const getTokenVerified = computed(() => {
    return tokenVerified.value;
  });

  /**
   * Setter for the tokenVerified variable.
   * @param {boolean} verified
   * @returns {boolean}
   */
  const setTokenVerified = (verified: boolean) => {
    tokenVerified.value = verified;
  };

  appIsReady.value =
    getUserAuthorisationState.value === "completed" ? true : false;

  const unwatchAuthorisationState = watch(
    () => getUserAuthorisationState.value,
    (userAuthorisationState) => {
      appIsReady.value = userAuthorisationState === "completed" ? true : false;

      appIsReady.value && unwatchAuthorisationState();
    }
  );

  /**
   * Sets the appIsReady global state.
   * @param {boolean} isReady
   */
  const setAppIsReady = (isReady: boolean) => {
    appIsReady.value = isReady;
  };

  const verifyLoginStatus = async () => {
    if (tokenVerified.value) return;

    const tokenIsValid = await verifyToken();

    useUser().setLoggedIn(tokenIsValid);

    setTokenVerificationComplete();
  };

  return {
    isLoggedIn,
    appIsReady,
    setAppIsReady,
    verifyLoginStatus,
    getAppIsReady,
    setTokenVerified,
    getTokenVerified,
  };
};
