/**
 * Formats an ISO string to application format.
 * @param {String} isoString
 * @returns {String}
 */
export const formatDateTime = (isoString: string): string => {
  if (isoString === "") return "";

  const datetime = new Date(isoString);
  const month = datetime.toLocaleString("default", { month: "short" });
  const day = addZero(datetime.getUTCDate());
  const year = addZero(datetime.getUTCFullYear());
  const hour = addZero(datetime.getUTCHours());
  const minutes = addZero(datetime.getUTCMinutes());

  return `${month} ${day}, ${year}, ${hour}:${minutes}`;
};

const addZero = (hour: number) => {
  if (hour > 9) return `${hour}`;
  return `0${hour}`;
};
