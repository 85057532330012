import { computed, Ref, ref } from "vue";
import { Mapping } from "@/ts/interfaces";

const mapping: Ref<Mapping | undefined> = ref({} as Mapping);
export const useMapping = () => {
  const getMappingId = computed(() => {
    return mapping.value?.id;
  });

  /**
   * Getter for the mapping variable.
   * @type {ComputedRef<Mapping>}
   */
  const getMapping = computed(() => {
    return mapping.value;
  });

  /**
   * Setter for the mapping variable.
   * @param {Mapping} mappingData
   * @returns {Mapping}
   */
  const setMapping = (mappingData: Mapping) => {
    mapping.value = mappingData;
  };

  /**
   * Updates the title property of current mapping object.
   * @param {string} mappingTitle
   */
  const setMappingTitle = (mappingTitle: string) => {
    if (!mapping.value) return;

    mapping.value.title = mappingTitle;
  };

  return { setMapping, setMappingTitle, getMapping, getMappingId };
};
