import Axios from "axios";
import qs from "qs";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const mappingUrl = `${baseUrl}/mappings`;

type exportMappingParams = operations["exportMapping"]["parameters"]["query"];
type exportMappingResponse =
  operations["exportMapping"]["responses"]["200"]["content"]["application/hal+json"];

const exportMapping = async (
  mappingId: number,
  params: exportMappingParams
): Promise<exportMappingResponse | undefined> => {
  const queryString = qs.stringify(params, { arrayFormat: "comma" });
  const url = `${mappingUrl}/${mappingId}/export?${queryString}`;

  const res = await Axios.post(url);

  return res.data || undefined;
};

export { exportMapping };
