import Axios from "axios";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("base");
const projectInfoUrl = `${baseUrl}/actuator/info`;


/**
 * Retrieves all vocabs from the api.
 * @returns Promise<any>
 */
const getProjectInfo = async (): Promise<unknown> => {
  const response = await Axios.get(projectInfoUrl);
  return response.data || undefined;
};

export { getProjectInfo };
