<template>
  <div class="edit-annotation-dialogue pb-4">
    <v-container>
      <div class="mb-2">
        <span class="mr-2">Annotate against:</span>
        <words-selector
          v-if="cellValue"
          :phrase="cellValue"
          :initial-selection="annotationHit.matchingName.split(' ')"
          @change="handleWordsSelectedChange"
        />
      </div>

      <div class="mb-4">
        <span class="font-weight-bold">Match Type: </span> {{ isPartial() ? "Partial" : "Full"}}
      </div>

      <div class="mb-4">
        <v-chip label small class="orange mr-4">{{
          annotationHit.termiteVocabId
        }}</v-chip>
        <span class="mr-4">{{ annotationHit.label }}</span>
        <v-tooltip top :disabled="!annotationHit.primaryUri">
          <template v-slot:activator="{ on, attrs }">
            <span class="orange--text" v-on="on" v-bind="attrs">{{
              annotationHit.primaryId
            }}</span>
          </template>
          {{ annotationHit.primaryUri }}
        </v-tooltip>
      </div>
      <div v-if="matchingSynonym">
        <span class="font-weight-bold">Matching Synonym: </span> {{matchingSynonym}}
      </div>
    </v-container>
  </div>
</template>

<script lang="ts">
import { computed, ComputedRef, defineComponent, Ref, ref } from "vue";
import { useAppDialog, useSelection } from "@/compositions";
import { components } from "@/ts/interfaces/ApiSchemas";
import WordsSelector from "@/components/common/WordsSelector.vue";
import { cloneDeep } from "lodash";

export default defineComponent({
  name: "EditAnnotationDialogue",
  components: {
    WordsSelector,
  },
  setup() {
    const dialogueArgs: ComputedRef<{
      annotationHit: components["schemas"]["AnnotationHit"];
    }> = useAppDialog().getArgs;

    const annotationHit = computed(() => {
      return dialogueArgs.value.annotationHit;
    });

    const matchingSynonym = computed(() => {
      return annotationHit.value.synonyms?.find(synonym => synonym === annotationHit.value.matchingName 
        || annotationHit.value.matchingName?.startsWith(synonym))
    });

    const updatedAnnotationHit: Ref<components["schemas"]["AnnotationHit"]> =
      ref(cloneDeep(annotationHit.value));

    /**
     * The current cell value.
     * @type {ComputedRef<string | undefined>}
     */
    const cellValue = computed(() => {
      return useSelection().getSelection.value?.data.selectedCell?.label;
    });

    const selectedVocabParts: Ref<string[]> = ref([]);

    /**
     * Checks if the current annotation hit is a partial.
     * @return {boolean}
     */
    const isPartial = () => {
      if (selectedVocabParts.value.length > 0) { 
        return selectedVocabParts.value.length !== cellValue.value?.split(" ").length
      } else {
        return annotationHit.value.partial;
      }
    };

    /**
     * Handler for the words selector change event.
     * @param {string[]} selectedWords
     */
    const handleWordsSelectedChange = (selectedWords: string[]) => {
      selectedVocabParts.value = selectedWords;

      updatedAnnotationHit.value.matchingName = selectedWords.join(" ");
      updatedAnnotationHit.value.partial = isPartial();

      if (
        selectedVocabParts.value.join(" ") === annotationHit.value.matchingName
      ) {
        useAppDialog().setConfirmButtonDisabled(true);
      } else {
        useAppDialog().setConfirmButtonDisabled(false);
      }

      useAppDialog().setConfirmActionArgs({
        hit: { ...updatedAnnotationHit.value },
      });
    };

    return { cellValue, handleWordsSelectedChange, isPartial, annotationHit, matchingSynonym };
  },
});
</script>

<style lang="scss">
.cell-value {
  &__part {
    .cell-value__part--unselected {
      text-decoration: line-through;
      color: var(--v-secondary-lighten4);
    }
  }
}
</style>
