import Axios from "axios";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const app = `${baseUrl}/datasets`;

type undoResponse = operations["undo"]["responses"]["200"]["content"]["application/hal+json"];

const undoLastChange = async (datasetId: number): Promise<undoResponse> => {
  const url = `${app}/${datasetId}/undo`;

  const res = await Axios.put(url);

  return res.data;
};

export { undoLastChange };
