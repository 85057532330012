import { components } from "@/ts/interfaces/ApiSchemas";
import { awaitAll } from "@/utils";
import { annotateByFilter } from "@/api";

export const useAnnotationTableRequests = (datasetId: number) => {
  const setAnnotations = async (args: {
    annotationHitsOnMultipleWords: components["schemas"]["AnnotationHit"][];
    selectedWordsForFilter: string[];
    saveAsRuleChecked: boolean;
  }) => {
    if (typeof args.selectedWordsForFilter === "undefined")
      return setManualAnnotations(args.annotationHitsOnMultipleWords, args.saveAsRuleChecked);

    const annotationHitList = args.annotationHitsOnMultipleWords;
    const selectedWords = args.selectedWordsForFilter;
    let index = 0;

    void (await awaitAll(annotationHitList, async (match) => {
      await annotateByFilter(
        datasetId,
        {
          filter: selectedWords[index],
          exact: !match.partial,
          replace: false,
          filter_attributes: undefined,
          add_as_rule: args.saveAsRuleChecked,
        },
        [{ hit: match }]
      );
      index++;
    }));
  };

  const setManualAnnotations = async (
    annotationHitsOnMultipleWords: components["schemas"]["AnnotationHit"][], 
    saveAsRuleFlag: boolean
  ) => {
    void (await awaitAll(annotationHitsOnMultipleWords, async (match) => {
      await annotateByFilter(
        datasetId,
        {
          filter: match.matchingName,
          exact: !match.partial,
          replace: false,
          filter_attributes: undefined,
          add_as_rule: saveAsRuleFlag,
        },
        [{ hit: match }]
      );
    }));
  };

  return { setAnnotations };
};
