import Keycloak, { KeycloakConfig } from 'keycloak-js';
import Axios from "axios";
import { API_TOKEN_NAME, AUTH_HEADER_PREFIX } from '@/globals';
import { useUser } from './useUser';
import { useLoginState } from './useLoginState';

let _keycloak: Keycloak | null = null;

export const initKeycloak = (keycloakConfig: KeycloakConfig) => {
  _keycloak = new Keycloak(keycloakConfig);

  _keycloak.onTokenExpired = () => {
    if (!_keycloak) return;

    _keycloak.updateToken(180).then(function(refreshed) {
      if (refreshed) {
          if (_keycloak) {
            localStorage.setItem(API_TOKEN_NAME, _keycloak.token as string);
            Axios.defaults.headers.common["Authorization"] = AUTH_HEADER_PREFIX + _keycloak.token
          }
      } 
    }).catch(function() {
        console.error('Failed to refresh the token, or the session has expired');
        
        localStorage.removeItem(API_TOKEN_NAME);
        delete Axios.defaults.headers.common["Authorization"];
        useUser().setLoggedIn(false);
        useUser().setUserRole("");
        useLoginState().setTokenVerified(false);
        useKeycloak().logout();
    });
  }
};

export const useKeycloak = () => {
  return _keycloak as Keycloak;
};
