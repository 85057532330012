import Axios from "axios";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const annotationsUrl = `${baseUrl}/datasets`;

type VerifyAnnotationByIdPathParams =
  operations["verifyAnnotationsById"]["parameters"]["path"];
type VerifyAnnotationResponse =
  operations["verifyAnnotationsById"]["responses"]["200"]["content"]["application/hal+json"];

/**
 * Retrieves annotations for a single value.
 * @param {number} datasetId - The id of the dataset that the change needs to occur on
 * @param {verifyAnnotationParams} params - The params needed for the request
 * @return {verifyAnnotationResponse}
 */
export const verifyAnnotationAndDeleteById = async (
  pathParams: VerifyAnnotationByIdPathParams, saveAsRuleFlag: boolean
): Promise<VerifyAnnotationResponse> => {
  const { id: dataset_id,  value_id, annotation_id } = pathParams;

  const url = `${annotationsUrl}/${dataset_id}/values/${value_id}/annotations/${annotation_id}/verifyAndDelete?add_as_rule=${saveAsRuleFlag}`;

  const response = await Axios.put(url);

  return response.data as VerifyAnnotationResponse;
};
