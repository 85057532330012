<template>
  <v-app id="sbwb" class="app-main" data-testid="app-main">
    <toast-alert />
    <template>
      <main-navbar @click.stop="drawer = !drawer" @showMyJobs="showMyJobsInFooter" />
      <main-toolbox v-model="drawer" />
      <app-dialog />
    </template>

    <main-content />

    <main-footer :showMyJobsClicked="showMyJobs" @onFooterClose="hideMyJobsInFooter"/>
  </v-app>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, Ref, ref } from "vue";
import MainToolbox from "@/components/layout/MainToolbox.vue";
import MainNavbar from "@/components/layout/MainNavbar.vue";
import MainContent from "@/components/layout/MainContent.vue";
import MainFooter from "@/components/layout/MainFooter.vue";
import ToastAlert from "@/components/common/ToastAlert.vue";
import useObservers from "@/compositions/useObservers";
import AppDialog from "../components/common/AppDialog.vue";
import { useVocabs } from "@/compositions";

export default defineComponent({
  name: "AppMain",
  setup() {
    const { registerObserver } = useObservers();
    const showMyJobs = ref(false);

    registerObserver("DATA_IMPORT_STARTED", {
      observer: "DISPLAY_UPLOAD_NOTIFICATIONS",
    });
    registerObserver("DATA_IMPORT_COMPLETE", {
      observer: "DISPLAY_UPLOAD_NOTIFICATIONS",
    })
    registerObserver("DATA_IMPORT_ERROR", {
      observer: "DISPLAY_UPLOAD_NOTIFICATIONS",
    });
    registerObserver("ONTOLOGY_MAPPING_STARTED", {
      observer: "DISPLAY_MAPPING_NOTIFICATIONS",
    });
    registerObserver("ONTOLOGY_MAPPING_COMPLETE", {
      observer: "DISPLAY_MAPPING_NOTIFICATIONS",
    }); 
    registerObserver("ONTOLOGY_MAPPING_ERROR", {
      observer: "DISPLAY_MAPPING_NOTIFICATIONS",
    });
    
    onBeforeMount(() => {
      useVocabs().updateActiveVocabs();
    });

    const showMyJobsInFooter = () => {
      showMyJobs.value = true;
    };
 
    const hideMyJobsInFooter = () => { 
      showMyJobs.value = false;
    }

    /**
     * Determines if the sidebar is opened or closed.
     * @var {boolean} drawer
     */
    const drawer: Ref<boolean> = ref(true);
    
    return { drawer,  showMyJobsInFooter, showMyJobs, hideMyJobsInFooter};
  },
  components: {
    MainContent,
    MainNavbar,
    ToastAlert,
    MainFooter,
    MainToolbox,
    AppDialog,
  },
});
</script>
