import { computed, reactive, ref, Ref, UnwrapRef, watch } from "vue";
import { v4 as generateUuid } from "uuid";
import { Notification, NotificationUnique } from "@/ts/interfaces";

const notifications: UnwrapRef<{ list: Array<NotificationUnique> }> = reactive({
  list: [],
});
const preventNotifications: Ref<boolean> = ref(false);

export const useNotifications = () => {
  const defaultTimeout = 5000;

  const notificationsList = computed(() => {
    return notifications.list;
  });

  /**
   * Removes a notification from the notifications.list array.
   * @param {string} uuid
   */
  const removeNotification = (uuid: string) => {
    notifications.list = notifications.list.filter((notificationsItem) => {
      return notificationsItem.uuid !== uuid;
    });
  };

  /**
   * Adds a uuid to a given notification and pushes it to the notifications.list array.
   * @param {Notification} notification
   */
  const pushNotification = (notification: Notification) => {
    if (preventNotifications.value) return;

    const uuid = generateUuid();

    notifications.list.push({ uuid, ...notification });
  };

  /**
   * Checks the timeout value of each notification and set a timer after which the notification will be removed.
   */
  const handleNotificationsTimeouts = () => {
    notifications.list.forEach((notification) => {
      if (notification.timeout) {
        const timeout =
          typeof notification.timeout === "boolean"
            ? defaultTimeout
            : notification.timeout;

        setTimeout(() => {
          removeNotification(notification.uuid);
        }, timeout);
      }
    });
  };

  /**
   * Setter for the preventNotification state.
   * @param {boolean} prevent
   */
  const setPreventNotifications = (prevent: boolean) => {
    preventNotifications.value = prevent;
  };

  const pushSuccessNotification = (text: string) => {
    pushNotification({
      text: text,
      type: "success",
      timeout: true,
    });
  };

  watch(
    () => notifications.list,
    () => {
      handleNotificationsTimeouts();
    },
    { deep: true }
  );

  return {
    notificationsList,
    pushNotification,
    pushSuccessNotification,
    removeNotification,
    setPreventNotifications,
  };
};
