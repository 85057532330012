import Axios from "axios";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const vocabUrl = `${baseUrl}/vocabs/deactivateAll`;

type deactivateAllVocabsResponse =
  operations["deactivateAllVocabs"]["responses"]["200"]["content"];

/**
 * deactivates all vocabs from the api.
 * @returns Promise<(deactivateAllVocabsResponse | undefined)>
 */
const deactivateAllVocabs = async (): Promise<
  deactivateAllVocabsResponse | undefined
> => {
  const response = await Axios.put(vocabUrl);

  return response.data || undefined;
};

export { deactivateAllVocabs };
