<template>
  <div class="panel-item panel-content-container panel-configuration">
    <ul class="configuration-vocabs panel-item">
      <li
        v-for="item in mappedVocabs"
        :key="item.vocab.termiteId"
        class="configuration-vocabs-item"
      >
        <v-chip v-if="item.vocab.name" class="mb-2 panel-item-chip" label small>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <span class="panel-item-chip__text" v-bind="attrs" v-on="on">{{
                item.vocab.name
              }}</span>
            </template>
            {{ item.vocab.name }}
          </v-tooltip>

          <v-btn
            icon
            right
            x-small
            :disabled="isPlusDisabled(item.vocab.id)"
            @click="addVocab(item.vocab.id)"
          >
            <v-icon small> mdi-plus-circle </v-icon>
          </v-btn>

          <v-btn
            icon
            right
            x-small
            :disabled="!isVocabSelected(item.vocab.id)"
            @click="removeVocab(item.vocab.id)"
          >
            <v-icon small> mdi-minus-circle </v-icon>
          </v-btn>
        </v-chip>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, PropType, ref, Ref, watch } from "vue";
import { AnnotationPanelItem } from "@/ts/interfaces";
import { components } from "@/ts/interfaces/ApiSchemas";
import { useAttributes, useSelection, useVocabs } from "@/compositions";
// TODO when clicking on checkbox change selection

export default defineComponent({
  name: "OntologyConfigurationPanel",
  props: {
    panelData: {
      type: Array as PropType<AnnotationPanelItem[]>,
      default: () => [],
    },
  },
  setup() {
    const vocabs: Ref<components["schemas"]["Vocab"][]> = ref([]);
    const selector = useSelection();
    const selectAll: { value: boolean } = { value: true };
    const mappedVocabs: Ref<
      {
        vocab: components["schemas"]["Vocab"];
        selected: boolean;
      }[]
    > = ref([]);

    /**
     * Checks if a vocabId is in the list of selected vocabs.
     * @param {number | undefined} vocabId
     * @return {boolean}
     */
    const isVocabSelected = (vocabId: number | undefined) => {
      if (!vocabId) return false;

      return useSelection().vocabIds.value.includes(vocabId);
    };

    /**
     * Determines if the plus button should be disabled by checking
     * if all attributes have a specific vocab id configured.
     * @param {number} vocabId
     * @return {boolean}
     */
    const isPlusDisabled = (vocabId: number): boolean => {
      return useSelection().uniqueVocabIdsCommonToSelectedAttributes.value.includes(
        vocabId
      );
    };

    /**
     * Creates a collection of objects containing vocab data and selection state of the vocab.
     */
    const mapVocabs = () => {
      mappedVocabs.value = [];

      vocabs.value.forEach((vocab) => {
        mappedVocabs.value.push({
          vocab: vocab,
          selected: isVocabSelected(vocab.id),
        });
      });
    };

    /**
     * Retrieves all active vocabs and maps them against their selection state.
     * @return {Promise<void>}
     */
    const getVocabs = () => {
      vocabs.value = useVocabs().getActiveVocabs();

      if (!vocabs.value.length) return;

      mapVocabs();
    };

    /**
     * Removes a vocab from the termiteConfig property of all selected attributes.
     * @param {number} vocabId
     */
    const removeVocab = (vocabId: number) => {
      useAttributes().removeVocabFromSelectedAttributes(vocabId);
    };

    /**
     * Adds a vocab to the termiteConfig property of all selected attributes.
     * @param {number} vocabId
     */
    const addVocab = (vocabId: number) => {
      useAttributes().addVocabToSelectedAttributes(vocabId);
    };

    watch(selector.getSelection, async () => {
      if (selector.columnIds.value.length !== 1) {
        selectAll.value = true;
        return mapVocabs();
      }

      selectAll.value = false;

      mapVocabs();
    });

    onBeforeMount(async () => {
      getVocabs();
    });

    return {
      vocabs,
      mappedVocabs,
      isVocabSelected,
      removeVocab,
      addVocab,
      isPlusDisabled,
    };
  },
});
</script>

<style scoped lang="scss">
.configuration-vocabs {
  list-style: none;
  padding: 0;
  .configuration-vocabs-item {
    .panel-item-chip {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}
</style>
