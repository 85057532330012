import Axios from "axios";
import qs from "qs";
import { components, operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

type SetAnnotationForValuePathParams =
  operations["getAnnotationsForValue"]["parameters"]["path"];
type SetAnnotationForValueQueryParams =
    operations["getAnnotationsForValue"]["parameters"]["query"];
type SetAnnotationForValueResponse = components["schemas"]["Annotation"][];
type SetAnnotationForValueRequestBody = components["schemas"]["Annotation"][];

const baseUrl: string = serverUrl("api");

/**
 * Lookup terms using the Termite vocab API.
 * @param {SetAnnotationForValuePathParams} queryParams
 * @return {Promise<SetAnnotationForValueResponse>}
 */
export const setAnnotationForValue = async (
  pathParams: SetAnnotationForValuePathParams,
  queryParams: SetAnnotationForValueQueryParams,
  requestBody: SetAnnotationForValueRequestBody
): Promise<SetAnnotationForValueResponse> => {
  const { id: dataset_id,  value_id } = pathParams;
  const queryString = qs.stringify(queryParams, {
    arrayFormat: "comma",
    encode: false,
  });
  const url = `${baseUrl}/datasets/${dataset_id}/values/${value_id}/annotations?${queryString}`;

  const response = await Axios.post(url, requestBody);

  return (response.data as SetAnnotationForValueResponse) || [];
};
