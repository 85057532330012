/**
 * Requests configuration suggestions and applies them to attributes.
 * @param {number} datasetId
 * @return {Promise<void>}
 */
import { useAttributes, useNotifications } from "@/compositions";
import { getAnnotationConfigSuggestion } from "@/api/annotation/getAnnotationConfigSuggestion";

/**
 * Pushes a notification to alert that the auto-configuration process has started.
 */
const notifyAutoConfigStarter = () => {
  useNotifications().pushNotification({
    title: "Auto configuration started",
    text: "Retrieving best matching ontologies from Termite...",
    type: "info",
    timeout: true,
  });
};

/**
 * Pushes a notification to alert that no good matches have been found.
 */
const notifyNoSuggestionsFound = () => {
  useNotifications().pushNotification({
    title: "No suggestion found",
    text: "We couldn't match your dataset with any suitable configuration",
    type: "warning",
    timeout: true,
  });
};

/**
 * Pushes a notification to alert that the autoconfiguration process has ended.
 * Also reports the number of attributes that have been configured.
 * @param {number} totalConfigured
 */
const notifyAutoConfigComplete = (totalConfigured: number) => {
  useNotifications().pushNotification({
    title: "Auto configuration complete",
    text: totalConfigured + " attributes have been automatically configured",
    type: "success",
    timeout: true,
  });
};

/**
 * Requests the configuration suggestions and apply them to the matching attributes.
 * @param {number} datasetId
 * @return {Promise<void>}
 */
export const applyConfigSuggestions = async ({
  datasetId,
}: Record<"datasetId", number>) => {
  notifyAutoConfigStarter();

  const suggestions = await getAnnotationConfigSuggestion(datasetId);

  const nonEmptySuggestions = suggestions.length
    ? suggestions.filter(
        (suggestion) => suggestion.suggestedConfig?.vocabIds?.length
      )
    : [];

  if (!nonEmptySuggestions.length) {
    notifyNoSuggestionsFound();
    return;
  }

  const setAttributeVocabs = useAttributes().setAttributeVocabs;

  const promises = nonEmptySuggestions.map(async (suggestion) => {
    const { attributeId, suggestedConfig } = suggestion;
    const vocabIds = suggestedConfig && suggestedConfig.vocabIds;

    if (attributeId === undefined) return;
    if (suggestedConfig === undefined) return;
    if (vocabIds === undefined) return;

    const nonEmptyVocabIds = vocabIds.filter(
      (vocabId) => vocabId !== undefined
    );

    await setAttributeVocabs(
      attributeId,
      nonEmptyVocabIds,
      suggestedConfig,
      true
    );
  });

  await Promise.all(promises);

  notifyAutoConfigComplete(nonEmptySuggestions.length);
};
