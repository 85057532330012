<template>
  <div class="panel-content-container">
    <template v-if="currentAnnotationHit">
      <annotations-panel-save-rule-dialogue
        v-model="saveRuleDialogueOpen"
        :annotation-hit="currentAnnotationHit"
        :cell-value="currentCellValue"
        @closeClick="toggleRuleDialogue"
        @saveClick="toggleRuleDialogue"
      />
    </template>

    <template v-if="panelData.length">
      <div
        v-for="annotation in panelData"
        :key="getSelectionId(annotation)"
        class="panel-item"
      >
        <div class="panel-item-header" ref="panelHead">
          <h3
            class="panel-item__title ont-weight-bold pb-2 mb-3"
            v-if="annotation.label"
          >
            {{ annotation.label }}
          </h3>
          <div class="panel-item__add-button mb-3 pb-1">
            <v-btn
              block
              depressed
              outlined
              color="primary"
              @click="openAddAnnotationModal"
              :disabled="userPermission === 'RO'"
              >Add annotation
              <v-icon right dense>mdi-plus-circle</v-icon></v-btn
            >
          </div>
        </div>
        <div
          class="panel-item-body"
          v-if="annotation.labels.length"
          ref="panelBody"
        >
          <ul class="panel-annotations-item__annotations">
            <li
              v-for="(label, index) in annotation.labels"
              :key="`summaries-${annotation.attributeId}-${index}`"
              class="annotations-panel-item-annotation"
            >
              <div
                class="
                  annotations-panel-item-annotation__text
                  annotations-panel-item-annotation-text
                "
              >
                <span
                  class="
                    annotations-panel-item-annotation-text__label
                    text-body-2
                  "
                  >{{ label.label }}</span
                >&nbsp;
                <span
                  class="
                    annotations-panel-item-annotation-text__id
                    orange--text
                    text-body-2
                  "
                  >{{ label.primaryId }}</span
                >
              </div>

              <v-chip
                class="annotations-panel-item-annotation__vocab-id orange"
                label
                small
                >{{ label.vocabId }}</v-chip
              >
              <div
                class="annotations-panel-item-annotation__text
                  annotations-panel-item-annotation-text mt-1"
                ><small><strong>Matched:</strong> {{ label.matchingName }}</small></div
              >

              <v-icon
                class="annotations-panel-item-annotation__annotation-type"
                x-small
                :color="changeAnnotationTypeColour(label.type)"
                >{{ decorateAnnotationType(label.type) }}</v-icon
              >

              <annotations-panel-actions
                :panelData="annotation"
                :annotation="label.annotation"
                :annotation-vocab="label.vocabId"
                :annotation-value="label.label"
                :annotation-id="label.annotationId"
                :primary-id="label.primaryId"
                :annotation-type="label.annotationType"
                :all-annotation-ids="label.allAnnotationIds"
                :user-permission="userPermission"
                @saveRule="showSaveRuleDialogue"
              />
            </li>
          </ul>
        </div>

        <span
          v-else
          class="
            panel-annotations-item__labels
            panel-annotations-item__labels--not-found
          "
          >No annotation found</span
        >
      </div>
    </template>

    <div v-else class="panel-item">
      <span
        class="
          panel-annotations-item__labels
          panel-annotations-item__labels--not-selected
        "
        >Please select a {{ type }}</span
      >
    </div>
  </div>
</template>

<script lang="ts">
import { computed, ComputedRef, defineComponent, nextTick, onUpdated, PropType, Ref, ref } from "vue";
import { AnnotationPanelItem } from "@/ts/interfaces";
import {
  useAnnotationStyles,
  useAppDialog,
  useContext,
  useDataset,
  useDatasetTable,
  useNotifications,
  useSelection,
useUser,
} from "@/compositions";
import { capitalize } from "lodash";
import { v4 as generateUuid } from "uuid";
import AnnotationsPanelActions from "./AnnotationsPanelActions.vue";
import AnnotationsPanelSaveRuleDialogue from "./AnnotationsPanelSaveRuleDialogue.vue";
import { components } from "@/ts/interfaces/ApiSchemas";
import AddAnnotationDialogue from "@/components/dialogs/AddAnnotationDialogue.vue";
import { AppDialogAction } from "@/ts/types";
import { setAnnotationForValue } from "@/api";
import { MSG_ADDED_ANNOTATION_SUCCESS } from "@/globals";
import { updateCurrentUser } from "@/observers";
import { getAllGroupMembers } from "../../../api";
import { Dataset } from "../../../ts/interfaces";


type GroupMember = components["schemas"]["GroupMember"];
type Group = components["schemas"]["Group"];

export default defineComponent({
  name: "AnnotationsPanel",
  props: {
    panelData: {
      type: Array as PropType<AnnotationPanelItem[]>,
    },
    type: {
      type: String,
    },
  },
  components: { AnnotationsPanelActions, AnnotationsPanelSaveRuleDialogue },
  setup() {
    const selector = useSelection();
    const selectionType = ref(capitalize(selector.selectionType.value));
    const { decorateAnnotationType, changeAnnotationTypeColour } =
      useAnnotationStyles();
    const saveRuleDialogueOpen: Ref<boolean> = ref(false);
    const currentAnnotationHit: Ref<
      components["schemas"]["AnnotationHit"] | undefined
    > = ref();
    const panelHead: Ref<HTMLDivElement[]> = ref([]);
    const panelBody: Ref<HTMLDivElement[]> = ref([]);


    const groupMembers: Ref<GroupMember[]> = ref([]);
    const userPermission: Ref<string> = ref("");
    const dataset: ComputedRef<Dataset | undefined> = useDataset().getDataset;
    const datasetGroup: ComputedRef<Group | undefined> = computed(() => dataset.value?.group);
    const currentCellValue = computed(() => useSelection().getSelection.value?.data.selectedCell?.label);
    /**
     * Gets the selected cell id.
     * @param {AnnotationPanelItem} annotation
     * @return {number}
     */
    const getSelectionId = (annotation: AnnotationPanelItem): number => {
      return annotation.selectionId || parseInt(generateUuid());
    };

    const toggleRuleDialogue = () => {
      saveRuleDialogueOpen.value = !saveRuleDialogueOpen.value;
    };

    /**
     * Shows the save rule dialogue and set the current annotation hit.
     * @param {components["schemas"]["AnnotationHit"]} hit
     */
    const showSaveRuleDialogue = (
      hit: components["schemas"]["AnnotationHit"]
    ) => {
      currentAnnotationHit.value = hit;
      saveRuleDialogueOpen.value = true;
    };

    /**
     * Sets annotations against a value.
     * @param {components["schemas"]["AnnotationHit"][]} hits
     * @return {Promise<void>}
     */
    const setAnnotations = async (args: {
      annotationHitsOnMultipleWords: components["schemas"]["AnnotationHit"][];
      selectedWordsForFilter: string[];
      saveAsRuleChecked: boolean;
    }) => {
      const datasetId = useContext().getCurrentDatasetId.value as number;
      const valueId = useSelection().getSelection.value?.data.selectedCell?.id;
      const value = useSelection().getSelection.value?.data.selectedCell?.label;

      if (valueId === undefined || datasetId === undefined) return;

      useNotifications().pushNotification({
        type: "info",
        text: `Starting saving annotations against "${value}"`,
        timeout: true,
      });

      // await useObservers().notifyObservers("ANNOTATION_COMPLETE");

      await nextTick();

      if (!args.annotationHitsOnMultipleWords) return console.error("No hits found");

      const annotations = args.annotationHitsOnMultipleWords.map((hit) => {
        return { hit };
      });

      await setAnnotationForValue(
        {
          id: datasetId,
          value_id: valueId,
        },
        { add_as_rule: args.saveAsRuleChecked},
        annotations
      );

      useNotifications().pushSuccessNotification(MSG_ADDED_ANNOTATION_SUCCESS);

      useSelection().refreshSelection();
      await useDatasetTable().loadAnnotationSummaries();
    };

    /**
     * Displays the modal dialogue containing the AddAnnotationsDialogue component.
     * @return {Promise<void>}
     */
    const openAddAnnotationModal = async () => {
      useAppDialog().setProps({ component: AddAnnotationDialogue, args: {} });
      useAppDialog().setDialog(true, "Add annotation", {
        showCancelButton: true,
        confirmButtonText: "Annotate with selected vocabs",
        confirmButtonDisabled: true,
        confirmAction: setAnnotations as AppDialogAction,
      });
    };

    /**
     * Adjusts the top margin of the panel item body to the height of the fixed panel header.
     * @return {Promise<void>}
     */
    const adjustHeight = async () => {
      panelHead.value.forEach((panelHeaderElement, index) => {
        if (!panelBody.value[index]) return;

        panelBody.value[index].style.marginTop =
          panelHeaderElement.offsetHeight + "px";
      });
    };

    onUpdated(() => {
      selectionType.value = capitalize(selector.selectionType.value);
      adjustHeight();
      getUserPermission();
    });

    const getUserPermission = async () => {
      if (!useUser().getCurrentUser.value) {
        await updateCurrentUser();
      }

      const username = useUser().getCurrentUser.value.username;
      groupMembers.value = await getAllGroupMembers(datasetGroup.value.id || 0);
      const userGroup = groupMembers.value.find((member) => member.user.username === username);
      if (userGroup) {
        userPermission.value = userGroup.groupRole as string;
      }
    };

    return {
      selectionType,
      getSelectionId,
      decorateAnnotationType,
      changeAnnotationTypeColour,
      saveRuleDialogueOpen,
      showSaveRuleDialogue,
      openAddAnnotationModal,
      currentAnnotationHit,
      panelHead,
      panelBody,
      toggleRuleDialogue,
      userPermission, 
      currentCellValue
    };
  },
});
</script>

<style scoped lang="scss">
.panel-annotations-item {
  &__annotations {
    list-style: none;
    padding: 0;
    margin-left: 0;

    .annotations-panel-item-annotation {
      padding-right: 10px;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #ccc;
      position: relative;

      &:last-of-type {
        border-bottom: none;
        margin-bottom: 0;
        border-bottom: 0;
      }

      &__vocab-id {
        display: inline-block;
        margin-top: 5px;
      }

      &__annotation-type {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}
</style>
