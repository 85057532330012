var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"panel-content-container panel-attributes"},[(_vm.panelData.length)?_vm._l((_vm.sortedPanelData),function(annotation){return _c('div',{key:annotation.attributeId,staticClass:"panel-item"},[(annotation.value)?_c('h3',{staticClass:"panel-item__title subtitle-2"},[_vm._v(" "+_vm._s(annotation.value)+" ")]):_vm._e(),_c('v-autocomplete',{attrs:{"placeholder":"Search vocab","dense":"","items":_vm.allActiveVocabs,"item-text":"name","item-value":"id"},on:{"change":function($event){return _vm.addVocab($event, annotation.attributeId)}},scopedSlots:_vm._u([{key:"selection",fn:function(){return [_c('span')]},proxy:true},{key:"item",fn:function(data){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',[_c('v-list-item-action',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus-circle")])],1),_c('span',_vm._g(_vm._b({staticClass:"panel-item-chip__text"},'span',attrs,false),on),[_vm._v(_vm._s(data.item.name))])],1)]}}],null,true)},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}},{key:"append-outer",fn:function(){return [_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-badge',{attrs:{"content":_vm.activeFiltersCount(annotation.attributeId),"value":_vm.hasCustomFilters(annotation.attributeId),"color":"primary","overlap":""}},[_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-tune")])],1)],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-checkbox',{attrs:{"label":"Include ambiguous"},on:{"change":function($event){return _vm.updateAnnotationRunConfig(
                      annotation.attributeId,
                      'includeAmbiguous',
                      $event
                    )}},model:{value:(
                    _vm.termiteOptions.includeAmbiguous[annotation.attributeId]
                  ),callback:function ($$v) {_vm.$set(_vm.termiteOptions.includeAmbiguous, annotation.attributeId, $$v)},expression:"\n                    termiteOptions.includeAmbiguous[annotation.attributeId]\n                  "}})],1),_c('v-list-item',[_c('v-checkbox',{attrs:{"label":"Subsume"},on:{"change":function($event){return _vm.updateAnnotationRunConfig(
                      annotation.attributeId,
                      'subsume',
                      $event
                    )}},model:{value:(_vm.termiteOptions.subsume[annotation.attributeId]),callback:function ($$v) {_vm.$set(_vm.termiteOptions.subsume, annotation.attributeId, $$v)},expression:"termiteOptions.subsume[annotation.attributeId]"}})],1),_c('v-list-item',[_c('v-checkbox',{attrs:{"label":"Exact match"},on:{"change":function($event){return _vm.updateAnnotationRunConfig(
                      annotation.attributeId,
                      'exactMatch',
                      $event
                    )}},model:{value:(_vm.termiteOptions.exactMatch[annotation.attributeId]),callback:function ($$v) {_vm.$set(_vm.termiteOptions.exactMatch, annotation.attributeId, $$v)},expression:"termiteOptions.exactMatch[annotation.attributeId]"}})],1),_c('v-list-item',[_c('v-checkbox',{attrs:{"label":"Include cells containing numbers"},on:{"change":function($event){return _vm.updateAnnotationRunConfig(
                      annotation.attributeId,
                      'includeCellsThatAreNumbers',
                      $event
                    )}},model:{value:(
                    _vm.termiteOptions.includeCellsThatAreNumbers[
                      annotation.attributeId
                    ]
                  ),callback:function ($$v) {_vm.$set(_vm.termiteOptions.includeCellsThatAreNumbers, 
                      annotation.attributeId
                    , $$v)},expression:"\n                    termiteOptions.includeCellsThatAreNumbers[\n                      annotation.attributeId\n                    ]\n                  "}})],1),_c('v-list-item',[_c('v-checkbox',{attrs:{"label":"Include annotation rules"},on:{"change":function($event){return _vm.updateAnnotationRunConfig(
                      annotation.attributeId,
                      'includeAnnotationRulesAndStopwords',
                      $event
                    )}},model:{value:(
                    _vm.termiteOptions.includeAnnotationRulesAndStopwords[
                      annotation.attributeId
                    ]
                  ),callback:function ($$v) {_vm.$set(_vm.termiteOptions.includeAnnotationRulesAndStopwords, 
                      annotation.attributeId
                    , $$v)},expression:"\n                    termiteOptions.includeAnnotationRulesAndStopwords[\n                      annotation.attributeId\n                    ]\n                  "}})],1)],1)],1)]},proxy:true}],null,true)}),(annotation.labels.length)?_c('div',{staticClass:"panel-item-labels panel-item__labels"},[_vm._l((annotation.labels),function(label,index){return [_c('vocab-chip',{key:`summaries-${annotation.selectionId}-${index}`,staticClass:"panel-item-labels__item panel-item-chip",attrs:{"label":label.name},on:{"click:close":function($event){return _vm.removeVocabFromAttribute(annotation.attributeId, label.id)}}},[_vm._v(" > ")])]})],2):_c('span',{staticClass:"panel-annotations-item__labels panel-annotations-item__labels--not-found"},[_vm._v("No vocab assigned")])],1)}):_c('div',{staticClass:"panel-item"},[_c('span',{staticClass:"panel-annotations-item__labels panel-annotations-item__labels--not-selected"},[_vm._v("Please select an attribute")])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }