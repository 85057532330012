import { Observer } from "@/ts/types";
import { useDatasetTable, useNotifications, useToolboxPanels } from "@/compositions";

/**
 * Registers an action to load the dataset table when the undo button is pressed.
 */
export const updateDataTable: Observer = (args) => {
  const loadTableHeaders = args.loadTableHeaders as () => Promise<void>;
  const loadTableRows = args.loadTableRows as () => Promise<void>;

  loadTableHeaders();
  loadTableRows();
  useDatasetTable().loadAnnotationSummaries();
  useToolboxPanels().resetAnnotationsPanelData();
  
  useNotifications().pushSuccessNotification("Changes are reverted");

  return true;
};

export default updateDataTable;
