import { Observer } from "@/ts/types";
import { Route } from "vue-router";
import { useContext, useRouter, useToolbox } from "@/compositions";

/**
 * Logic to show hide the toolbox.
 * @param {Record<any, any>} args
 * @param {context} args.contex - The current context.
 * @param {string|null} args.route - The route name.
 * @return {boolean}
 */
export const showHideToolbox: Observer = () => {
  const route = useRouter().getCurrentRoute().value as Route;
  const context = useContext().getContext.value?.name || "";

  switch (true) {
    case context == "Dataset/Annotations":
    case context == "Dataset/Unannotated":
    case context == "Dataset/Sharing":
    case route && route.name !== "Dataset":
      useToolbox().hide();
      break;
    default:
      useToolbox().show();
      break;
  }

  return true;
};
