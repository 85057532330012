export const serverUrl = (type: "api" | "base" | "swagger") => {
  const baseUrl: string = process.env.VUE_APP_BASEURL || "";

  const apiRelativeUrl = process.env.VUE_APP_API_BASEURL || "/api";
  const swaggerRelativeUrl =
    process.env.VUE_APP_API_SWAGGER_URL ||
    "/swagger-ui/index.html?url=/api-docs";

  switch (type) {
    case "base":
      return baseUrl;
    case "api":
      return baseUrl + apiRelativeUrl;
    case "swagger":
      return baseUrl + swaggerRelativeUrl;
  }
};
