import { getAllActiveVocabs } from "@/api";
import { reactive, UnwrapRef } from "vue";
import { components } from "@/ts/interfaces/ApiSchemas";

const activeVocabs: UnwrapRef<{ list: components["schemas"]["Vocab"][] }> =
  reactive({ list: [] });

export const useVocabs = () => {
  const setActiveVocabs = (vocabs: components["schemas"]["Vocab"][]) => {
    activeVocabs.list = vocabs;
  };

  /**
   * Updates the activeVocabs state.
   * @return {Promise<components["schemas"]["Vocab"][] | undefined>}
   */
  const updateActiveVocabs = async () => {
    const vocabs = await getAllActiveVocabs();

    setActiveVocabs(vocabs || []);

    return vocabs;
  };

  /**
   * Gets all active vocabs.
   * @return {UnwrapRef<components["schemas"]["Vocab"][]>}
   */
  const getActiveVocabs = (excludeEmpty:boolean) => {
    return activeVocabs.list;
  };


  /**
   * Gets all active vocabs excluding empty ones.
   * @return {UnwrapRef<components["schemas"]["Vocab"][]>}
   */
  const getActiveVocabsExcludeEmpty = () => {
    return activeVocabs.list.filter(v => (v.termCount as number) > 0);
  };

  /**
   * Get an active vocab by its termite ID.
   * @param {string} termiteId
   * @return components["schemas"]["Vocab"]|undefined
   */
  const getVocabByTermiteId = (
    termiteId: string
  ): components["schemas"]["Vocab"] | undefined => {
    if (!activeVocabs.list) return;

    return activeVocabs.list.find((vocab) => {
      return vocab.termiteId === termiteId;
    });
  };

  /**
   * Get an active vocab internal ID by its termite ID.
   * @param {string} termiteId
   * @return {number | undefined}
   */
  const getVocabInternalIdByTermiteId = (
    termiteId: string
  ): number | undefined => {
    const vocab = getVocabByTermiteId(termiteId);

    return vocab && vocab.id;
  };

  /**
   * Generates a map of tge active vocabs mapped my termiteId.
   * @return {Record<string, components["schemas"]["Vocab"]> | {}}
   */
  const getVocabsMapByTermiteId = () => {
    if (!activeVocabs.list) return {};

    const vocabsMapByTermiteId: Record<string, components["schemas"]["Vocab"]> =
      {};

    for (const vocab of activeVocabs.list) {
      vocabsMapByTermiteId[vocab.termiteId] = vocab;
    }

    return vocabsMapByTermiteId;
  };

  return {
    setActiveVocabs,
    updateActiveVocabs,
    getActiveVocabs,
    getVocabInternalIdByTermiteId,
    getVocabsMapByTermiteId,
    getActiveVocabsExcludeEmpty
  };
};
