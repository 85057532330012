import Axios from "axios";
import qs from "qs";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const annotationsUrl = `${baseUrl}/datasets`;

type verifyAnnotationParams =
  operations["verifyAllByFilter"]["parameters"]["query"];
type verifyAnnotationResponse = Promise<boolean>;

/**
 * Retrieves annotations for a single value.
 * @param {number} datasetId - The id of the dataset that the change needs to occur on
 * @param {verifyAnnotationParams} params - The params needed for the request
 * @return {verifyAnnotationResponse}
 */
const verifyAnnotations = async (
  datasetId: number,
  params: verifyAnnotationParams
): verifyAnnotationResponse => {
  const queryString = qs.stringify(params, { arrayFormat: "comma" });

  const url = `${annotationsUrl}/${datasetId}/verifyAnnotations?${queryString}`;

  const response = await Axios.post(url);

  return response.data === "";
};

export { verifyAnnotations };
