<template>
  <v-dialog :value="value" max-width="1000px" persistent>
    <v-card>
      <v-card-title> Save rule </v-card-title>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on"> Value </span>
                  </template>
                  Value to be annotated
                </v-tooltip>
              </th>
              <th>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on"> Annotated part </span>
                  </template>
                  Annotate the value with this annotation
                </v-tooltip>
              </th>
              <th>Annotated source</th>
              <th>Matched Value</th>
              <th>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on"> Case sensitive </span>
                  </template>
                  Should the value search be case sensitive?
                </v-tooltip>
              </th>
              <th>Match type</th>
              <!-- <th>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on"> Attribute name </span>
                  </template>
                  Do you want to search for this value in a specific column?
                </v-tooltip>
              </th> -->
              <th>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on"> Group </span>
                  </template>
                  What group would you like the rule to belong to?
                </v-tooltip>
              </th>
            </tr>
          </thead>
          <tr>
            <td>{{ conditionValue }}</td>
            <td>
              {{ annotationHit.label }}
              <span class="orange--text"> {{ annotationHit.primaryId }} </span>
            </td>
            <td>
              <v-chip label color="orange">
                {{ annotationHit.termiteVocabId }}</v-chip
              >
            </td>
            <td>{{ annotationHit.matchingName }}</td>
            <td>
              <div style="display: flex; justify-content: space-evenly">
                <v-checkbox v-model="caseSensitive" />
              </div>
            </td>
            <td>
              <v-autocomplete
                dense
                rounded
                :items="[
                  { text: 'Exact', value: 'EXACT' },
                  { text: 'Starts with', value: 'STARTS_WITH' },
                  { text: 'Ends with', value: 'ENDS_WITH' },
                  { text: 'Contains', value: 'CONTAINS' },
                ]"
                item-text="text"
                item-value="value"
                v-model="matchType"
              />
            </td>
            <!-- <td>
              <v-autocomplete  v-if="!rule"
                clearable
                dense
                rounded
                item-value="id"
                item-text="text"
                :items="attributes"
                v-model="selectedAttribute"
              />
              <span class="v-text-field__slot">
                <v-text-field v-if="rule"
                  v-model="ruleAttributeName"
                  placeholder="Attribute name"
                />
              </span>
            </td> -->
            <td>
              <v-autocomplete
                dense
                rounded
                :items="groups"
                item-text="group.name"
                item-value="group.id"
                v-model="groupId"
              />
            </td>
          </tr>
        </template>
      </v-simple-table>
      <v-card-text>
        <v-col class="text-right">
          <v-btn color="secondary" text @click="$emit('closeClick')">
            Cancel
          </v-btn>
          <v-btn color="primary" text @click="handleSaveRuleClick">
            Save as new rule
          </v-btn>
          <v-btn
            v-show="showEditRule"
            color="primary"
            text
            @click="handleUpdateRuleClick"
          >
            Update rule
          </v-btn>
        </v-col>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, Ref, ref, watch } from "vue";
import { components } from "@/ts/interfaces/ApiSchemas";
import {
  useAttributes,
  useContext,
  useNotifications,
  useSelection,
  useUser,
} from "@/compositions";
import { saveRule } from "@/api/rule/saveRule";
import { DataTableHeader } from "vuetify";
import { editRule } from "@/api/rule/editRule";
import { updateCurrentUser } from "@/observers";

type AttributeColumn = components["schemas"]["Attribute"];
type Rule = components["schemas"]["Rule"];

interface AttributeVuetifyColumn extends AttributeColumn, DataTableHeader {}

export default defineComponent({
  name: "AnnotationsPanelSaveRuleDialogue",
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    annotationHit: {
      type: Object,
      required: true,
    },
    cellValue: {
      type: String,
      required: false,
    },
    showEditRule: {
      type: Boolean,
      required: false,
      default: false,
    },
    rule: {
      type: Object,
      required: false,
    }
  },
  setup(props, { emit }) {
    const caseSensitive: Ref<boolean> = ref(true);
    const selectedAttribute: Ref<number | undefined> = ref();
    const attributes: Ref<AttributeVuetifyColumn[]> = ref([]);
    const matchType: Ref<components["schemas"]["Condition"]["matchType"]> =
      ref("EXACT");
    const groupId: Ref<number | undefined> = ref();
    const groups: Ref<components["schemas"]["UserInGroup"][]> = ref([]);
    const ruleAttributeName: Ref<string> = ref("");
    const conditionValue: Ref<string|undefined> = 
      ref(props.rule && props.rule.value ? props.rule.value : props.cellValue);

    /**
     * Gets the current group by id.
     * @param {number} groupId
     * @return {components["schemas"]["Group"]|undefined}
     */
    const getGroupById = (
      groupId: number
    ): components["schemas"]["Group"] | undefined => {
      if (!groups.value.length) return;

      let selectedGroup: components["schemas"]["Group"] | undefined;

      groups.value.some((group: components["schemas"]["UserInGroup"]) => {
        if (group.group?.id !== groupId) return false;

        selectedGroup = group.group as components["schemas"]["Group"];

        return true;
      });

      return selectedGroup;
    };

    /**
     * Retrieves required data and saves the rule.
     * @return {Promise<void>}
     */
    const handleSaveRuleClick = async () => {
      emit("saveClick");
      const annotationHit =
        props.annotationHit as components["schemas"]["AnnotationHit"];
      const attribute =
        selectedAttribute.value &&
        useAttributes().getAttributeById(selectedAttribute.value);
      const creator = useUser().getCurrentUser
        .value as components["schemas"]["User"];
      const group = (groupId.value && getGroupById(groupId.value)) || {};
      
      // var attributeName = (attribute && attribute.name) || "";
      // if (props.rule) {
      //   attributeName = ruleAttributeName.value;
      // }
      
      const conditions: components["schemas"]["Condition"][] = [
        {
          matchType: matchType.value,
          value: conditionValue.value,
          // attributeName,
          caseSensitive: caseSensitive.value,
        },
      ];

      const res = await saveRule({
        conditions,
        annotation: [annotationHit],
        creator,
        group,
      });

      if (!res) return;

      useNotifications().pushNotification({
        title: "New rule created",
        text: "Rule successfully created",
        type: "success",
        timeout: true,
      });
      emit("edit-rule:completed");
    };

    const handleUpdateRuleClick = async () => {
      emit("updateClick");

      if (props.rule === undefined) {
        return;
      }

      const annotationHit =
        props.annotationHit as components["schemas"]["AnnotationHit"];
      const attribute = ruleAttributeName.value ;
      const attributeName = (attribute) || "";
      const creator = useUser().getCurrentUser
        .value as components["schemas"]["User"];
      const group = (groupId.value && getGroupById(groupId.value)) || {};
      const rule = props.rule as Rule;

      const conditions: components["schemas"]["Condition"][] = [
        {
          matchType: matchType.value,
          value: conditionValue.value,
          attributeName,
          caseSensitive: caseSensitive.value,
        },
      ];

      const editedRule: components["schemas"]["Rule"] = {
        id: rule.id,
        conditions: conditions,
        annotation: [annotationHit],
        creator: creator,
        group: group,
      };

      const response = await editRule(editedRule);
      if (!response) return;

      useNotifications().pushNotification({
        title: "Updated rule",
        text: "Rule successfully updated",
        type: "success",
        timeout: true,
      });
      emit("edit-rule:completed");
    };

    /**
     * Loads the current dataset attributes and assigns them to the `attributes` reactive property.
     */
    const loadAllAttributes = () => {
      const currentAttributes = [...useContext().getCurrentAttributes.value];

      currentAttributes.shift();

      attributes.value = currentAttributes;
    };

    /**
     * Loads and set the initial value for the attributes field.
     * By default the value is the selected value's attribute.
     */
    const loadSelectedAttributeInitialValue = () => {
      const attributeId =
        useSelection().getSelection.value?.data.selectedCell?.attributeId;
      
      ruleAttributeName.value = props.rule ? props.rule.attributeName : "";

      if (!attributeId) return;
      selectedAttribute.value = attributeId;
    };

    /**
     * Loads and set the initial value for the groups field.
     * By default the value is the user's default group.
     */
    const loadGroupsInitialValue = async () => {
      if (!useUser().getCurrentUser.value) {
        await updateCurrentUser();
      }

      const creator = useUser().getCurrentUser
        .value as components["schemas"]["EntityModelUser"];
      const rule = props.rule as components["schemas"]["Rule"];
      
      groups.value = creator.memberOf || [];


      groups.value.some((group: components["schemas"]["UserInGroup"]) => {
        if (!group.group) return false;

        const userGroup =
          group.group as unknown as components["schemas"]["Group"];
          
        if (rule) {
          if (rule.group !== userGroup.name) return false;
          else {
            groupId.value = userGroup.id
            return true;
          }
        } 

        if (userGroup.name === creator.username) {
          groupId.value = userGroup.id;
          return true;
        }
      });
    };

    watch(
      () => props.value,
      () => {
        // loadAllAttributes();
        conditionValue.value = props.rule && props.rule.value ? props.rule.value : props.cellValue;
        loadSelectedAttributeInitialValue();
        loadGroupsInitialValue();
      }
    );


    // loadAllAttributes();
    loadSelectedAttributeInitialValue();
    loadGroupsInitialValue();

    return {
      caseSensitive,
      selectedAttribute,
      matchType,
      groupId,
      handleSaveRuleClick,
      groups,
      handleUpdateRuleClick,
      attributes,
      ruleAttributeName,
      conditionValue
    };
  },
});
</script>

<style scoped lang="scss">
.v-input__slot {
  align-items: center;
  justify-content: center;
}

td {
  padding-left: 20px;
}
</style>
