import { Observer } from "@/ts/types";
import { TableSelection } from "@/ts/interfaces";
import { useSelection } from "@/compositions";

/**
 * Rebuilds panel data of the current selection using the `buildPanelsData` passed from the MainToolbox component
 * @param {Record<string, unknown>} args
 * @link /src/components/layout/MainToolbox.vue
 * @return {Promise<boolean>}
 */
export const rebuildPanelData: Observer = async (args) => {
  const buildPanelsData = args.buildPanelsData as (
    selection: TableSelection | undefined
  ) => Promise<void>;
  const selection = useSelection().getSelection.value;
  await buildPanelsData(selection);
  return true;
};
