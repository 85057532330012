import { MiddlewareContext } from "@/ts/interfaces";
import { VueRouter } from "vue-router/types/router";
import { middlewarePipeline } from "@/middlewares";

export const middlewareGuard = (
  router: VueRouter,
  globalMiddleware: Array<(context: MiddlewareContext) => void>
): void => {
  router.beforeEach((to, from, next) => {
    if (!to?.meta?.middleware && !globalMiddleware.length) {
      return next();
    }

    const middleware = [...globalMiddleware, ...(to.meta?.middleware || [])];

    const context: MiddlewareContext = {
      to,
      from,
      next,
      router,
    };

    return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1),
    });
  });
};
