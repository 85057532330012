import Axios from "axios";
import qs from "qs";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const annotationsUrl = `${baseUrl}/datasets`;

type deleteAnnotationByFilterRequest =
  operations["deleteAnnotateData"]["parameters"]["query"];
type deleteAnnotation = Promise<boolean>;

/**
 * Deletes annotations.
 * @param {deleteAnnotationByFilterRequest} query - The query string for the delete req
 * @param datasetId - The ID of the dataset which the value needs to be deleted
 * @return {deleteAnnotation}
 */
const deleteAnnotationByFilter = async (
  datasetId: number,
  query: deleteAnnotationByFilterRequest
): deleteAnnotation => {
  const queryString = qs.stringify(query, {
    arrayFormat: "comma",
    encode: false,
  });

  const url = `${annotationsUrl}/${datasetId}/annotateByFilter?${queryString}`;

  const response = await Axios.delete(url);

  return response.data === "";
};

export { deleteAnnotationByFilter };
