import Axios from "axios";
import qs from "qs";
import { serverUrl } from "@/utils";
import { components, operations } from "@/ts/interfaces/ApiSchemas";


const baseUrl: string = serverUrl("api");
const mappingURL = `${baseUrl}/mappings/highlyLinkedTerms`;

type LinkedTermResponse = Promise<(components["schemas"]["LinkedTerm"])[]>;

/**
 * generates high linked terms by vocabs list
 * @param {number} mappingId
 * @returns {LinkedTermResponse}
 */

const generateLinkedTerms = async (params: operations["generateAndGetLinkedTermsByVocabs"]["parameters"]["query"]): LinkedTermResponse => {
  const queryString = qs.stringify(params, { arrayFormat: "repeat" });
  const response = await Axios.get(`${mappingURL}?${queryString}`);
  return response.data._embedded.linkedTerms;
};

export { generateLinkedTerms };