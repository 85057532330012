import { computed, reactive, UnwrapRef } from "vue";
import { camelCase } from "lodash";
import { v4 as generateUuid } from "uuid";
import * as importedActions from "@/observers";
import { Observer, ObserverParams, SubjectName } from "@/ts/types";

const observerActions = importedActions as unknown as Record<string, Observer>;

interface Observers extends ObserverParams {
  id: string;
  priority: number;
}

/**
 * Collection of observers by subject name.
 * @type {UnwrapRef<{}>}
 */
const observers: UnwrapRef<Record<string, Observers[]>> = reactive({});

/**
 * Observers system, use to subscribe/unsubscribe actions against events.
 * @returns Object
 */
export const useObservers = () => {
  const getSubjects = computed(() => observers);

  /**
   * Removes an event from the events reactive object.
   * @param {string} name
   */
  const unregisterSubject = (name: string) => {
    observers[name] && delete observers[name];
  };

  /**
   * Removes a record by observerId from the observers[subjectName] array.
   * @param {string} subjectName
   * @param {string} observerId
   */
  const unregisterObserver = (subjectName: string, observerId: string) => {
    if (!observers[subjectName]) return;

    observers[subjectName] = observers[subjectName].filter((observer) => {
      return observer.id !== observerId;
    });
  };

  /**
   * Adds an observer (action) against a subject name in the observers reactive object.
   * @param {SubjectName} subjectName The event name
   * @param {ObserverName} subjectName The action name
   * @param {Record<string, unknown> | undefined} args The arguments to pass to the hook.
   * @param {number | undefined} priority Determines the order the hook will be executed.
   * @return {() => void}
   */
  const registerObserver = (
    subjectName: SubjectName,
    { observer, args, priority = 10 }: ObserverParams
  ) => {
    if (!observers[subjectName]) observers[subjectName] = [];

    const id = generateUuid();

    /**
     * Removes the registered hook from the actions reactive object.
     */
    const removeSelf = () => {
      unregisterObserver(subjectName, id);
    };

    args = { ...args, removeSelf };

    observers[subjectName].push({ observer: observer, args, priority, id });

    // Sorts again the array by priority
    observers[subjectName].sort((a, b) => a.priority - b.priority);

    return removeSelf;
  };

  /**
   * Adds an observer against multiple subjects.
   * @param {SubjectName[]} subjectNames The multiple events names
   * @param {ObserverName} observer The action name
   * @param {Record<string, unknown> | undefined} args The arguments to pass to the hook.
   * @param {number | undefined} priority Determines the order the hook will be executed.
   * @return {Record<string, () => void>} An object having as a key the subject name and as a value the remove function.
   */
  const registerObserverMultiple = (
    subjectNames: SubjectName[],
    { observer, args, priority = 10 }: ObserverParams,
    priorities: number[] = []
  ) => {
    const unregisterObservers: Record<string, () => void> = {};

    subjectNames.forEach((subjectName, index) => {
      priority = (priorities.length && priorities[index]) || priority;
      unregisterObservers[subjectName] = registerObserver(subjectName, {
        observer,
        args,
        priority,
      });
    });

    return unregisterObservers;
  };

  /**
   * Performs the observer actions contained in the observers[name] array at the specified index.
   * If the action returns true it will execute the next action in the observers[name] array.
   * @param {string} subjectName The event name
   * @param {Record<string, unknown>} args
   * @param {number} index
   * @return {Promise<void>}
   */
  const notifyObservers = async (
    subjectName: string,
    args: Record<string, unknown> = {},
    index = 0
  ) => {
    if (!observers[subjectName]) return;

    args.subjectName = subjectName;

    const subjectObservers = observers[subjectName];
    const observer = subjectObservers[index];
    const observerName = camelCase(observer.observer);
    const observerAction: Observer = observerActions[observerName];
    const nextAction = await observerAction({ ...args, ...observer.args });
    const nextIndex = index + 1;

    if (args.oneOff === true) unregisterObserver(subjectName, observer.id);

    if (nextAction && subjectObservers[nextIndex] !== undefined)
      await notifyObservers(subjectName, args, nextIndex);
  };

  return {
    getSubjects,
    unregisterSubject,
    unsubscribeObserver: unregisterObserver,
    registerObserver,
    registerObserverMultiple,
    notifyObservers,
  };
};

export default useObservers;
