import Axios from "axios";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const datasetURL = `${baseUrl}/datasets`;

/**
 * [deleteAttribute description]
 *
 * @param   {number}  id  [id description]
 *
 * @return  {[type]}      [return description]
 */
const deleteAttribute = async (datasetId: number, attributeId:number): Promise<boolean> => {
  const response = await Axios.delete(`${datasetURL}/${datasetId}/attributes/${attributeId}`);
  return response.status === 200;
};

export { deleteAttribute };
