import { computed, ref, Ref } from "vue";
import {
  getAttributesByDatasetId,
  updateAttribute as apiUpdateAttribute,
} from "@/api";
import { components } from "@/ts/interfaces/ApiSchemas";
import { useSelection } from "@/compositions/useSelection";
import { useContext } from "@/compositions/useContext";
import { awaitAll } from "@/utils";
import { TermiteConfigOption } from "@/ts/types/TermiteConfigOption";
import { useDataset } from "@/compositions/useDataset";

/**
 * Reactive reference to the attributes of the dataset currently displayed.
 * @type {Ref<UnwrapRef<any[]>>}
 */
const attributes: Ref<components["schemas"]["Attribute"][]> = ref([]);

/**
 * Requests attributes from the API and returns a reactive reference to it.
 * @returns {DatasetComposition}
 */
export const useAttributes = () => {
  /**
   * Request attributes for selected dataset id and stores them in a global variable.
   * @param {number} datasetId
   * @return {Promise<components["schemas"]["Attribute"][]>}
   */
  const getAttributes = async (
    datasetId: number
  ): Promise<components["schemas"]["Attribute"][]> => {
    attributes.value = await getAttributesByDatasetId(datasetId);

    return attributes.value;
  };

  const datasetAttributes = computed(() => {
    return attributes.value;
  });

  /**
   * Filters the attributes and returns the one corresponding to the specified attributeId.
   * @param {number} attributeId
   * @return {{} | {id?: number, columnIndex: number, name?: string, uri?: string, termiteConfig?: components["schemas"]["TermiteConfig"]} | any[]}
   */
  const getAttributeById = (
    attributeId: number
  ): components["schemas"]["Attribute"] | null => {
    if (!attributes.value.length) return null;

    const filteredAttributes: components["schemas"]["Attribute"][] =
      attributes.value.filter((attribute) => {
        return attribute.id === attributeId;
      });

    return filteredAttributes[0] || null;
  };

  /**
   * Gets a given attribute's column index by attribute id.
   * @param {number} columnIndex
   * @return {number | null}
   */
  const getColumnIndexById = (columnIndex: number): number | null => {
    if (!attributes.value.length) return null;

    const attribute = getAttributeById(columnIndex);

    return attribute?.columnIndex || null;
  };

  /**
   * Gets a given attribute's name by attribute id.
   * @param {number} attributeId
   * @return {string | null}
   */
  const getAttributeNameById = (attributeId: number): string | null => {
    if (!attributes.value.length) return null;

    const attribute = getAttributeById(attributeId);

    return attribute?.name || null;
  };

  /**
   * Makes an api request to update an attribute, updates the global attributes array with the result and triggers a
   * selection update to make sure that the change is reflected everywhere else.
   * @param {number} datasetId
   * @param {components["schemas"]["Attribute"]} params
   * @param {boolean} refreshSelection - Do we want to update the selection and notify all related components?
   * @return {Promise<components["schemas"]["Attribute"]>}
   */
  const updateAttribute = async (
    datasetId: number,
    params: components["schemas"]["Attribute"],
    refreshSelection = true
  ): Promise<components["schemas"]["Attribute"]> => {
    const updatedAttribute = await apiUpdateAttribute(datasetId, params);

    // todo Use a breakable loop instead
    attributes.value.forEach((attribute, index) => {
      if (attribute.id === params.id)
        attributes.value[index] = updatedAttribute;
    });

    if (refreshSelection) useSelection().refreshSelection();

    return updatedAttribute;
  };

  /**
   * Removes a vocab from the termite configuration of a specified attribute.
   * @param {number} attributeId
   * @param {number} targetVocabId
   * @return {Promise<undefined |components["schemas"]["Attribute"]>}
   */
  const removeVocabFromAttribute = async (
    attributeId: number,
    targetVocabId: number,
    refreshSelection = true
  ): Promise<undefined | components["schemas"]["Attribute"]> => {
    const attribute = getAttributeById(attributeId);
    const datasetId = useSelection().datasetId.value;

    if (!attribute?.termiteConfig?.vocabIds?.length || !datasetId) return;

    attribute.termiteConfig.vocabIds = attribute.termiteConfig.vocabIds.filter(
      (vocabId) => {
        return vocabId !== targetVocabId;
      }
    );

    const updatedAttribute = await updateAttribute(
      datasetId,
      attribute,
      refreshSelection
    );

    return updatedAttribute;
  };

  /**
   * Removes a vocab from the configuration of all selected attributes.
   * @param {number} vocabId
   * @return {Promise<components["schemas"]["Attribute"][]>}
   */
  const removeVocabFromSelectedAttributes = async (
    vocabId: number
  ): Promise<components["schemas"]["Attribute"][]> => {
    const selectedAttributes = useSelection().columnIds.value;
    const updatedAttributes: components["schemas"]["Attribute"][] = [];

    if (!selectedAttributes.length) return [];

    selectedAttributes.filter(async (attributeId) => {
      const updatedAttribute = await removeVocabFromAttribute(
        attributeId,
        vocabId,
        false
      );
      updatedAttribute && updatedAttributes.push(updatedAttribute);
    });

    useSelection().refreshSelection();

    return updatedAttributes;
  };

  /**
   * Adds a vocab from to termite configuration of a specified attribute.
   * @param {number} attributeId
   * @param {number} targetVocabId
   * @param refreshSelection
   * @return {Promise<undefined |components["schemas"]["Attribute"]>}
   */
  const addVocabToAttribute = async (
    attributeId: number,
    targetVocabId: number,
    refreshSelection = true
  ): Promise<undefined | components["schemas"]["Attribute"]> => {
    const attribute = getAttributeById(attributeId);
    const datasetId = useDataset().getDatasetId.value;
    if (!attribute || !datasetId || !targetVocabId) return;

    if (!attribute.termiteConfig) attribute.termiteConfig = { vocabIds: [] };

    if (!attribute.termiteConfig.vocabIds)
      attribute.termiteConfig.vocabIds = [];

    if (attribute.termiteConfig.vocabIds.includes(targetVocabId)) return;

    attribute.termiteConfig.vocabIds.push(targetVocabId);

    return await updateAttribute(datasetId, attribute, refreshSelection);
  };

  const updateAttributeTermiteConfig = async (
    attributeId: number,
    configName: TermiteConfigOption,
    configValue: boolean,
    refreshSelection?: true
  ) => {
    const attribute = getAttributeById(attributeId);
    const datasetId = useDataset().getDatasetId.value;

    if (!attribute || !datasetId) return;

    if (!attribute.termiteConfig) {
      attribute.termiteConfig = { [configName]: configValue };
    } else {
      attribute.termiteConfig[configName] = configValue;
    }

    const updatedAttribute = await updateAttribute(
      datasetId,
      attribute,
      refreshSelection
    );

    return updatedAttribute;
  };

  const setAttributeConfigSuggestions = async (
    attributeId: number,
    vocabIds: number[],
    termiteConfig: components["schemas"]["TermiteConfig"],
    refreshSelection = true
  ): Promise<undefined | components["schemas"]["Attribute"]> => {
    const attribute = getAttributeById(attributeId);
    const datasetId = useContext().getCurrentDatasetId.value;

    if (!attribute || !datasetId) return;

    attribute.termiteConfig = termiteConfig;

    attribute.termiteConfig.vocabIds = vocabIds;

    return await updateAttribute(datasetId, attribute, refreshSelection);
  };
  /**
   * Sets all the vocabs on an attribute, overrides current with the new ones.
   * @param {number} attributeId
   * @param {number[]} vocabIds
   * @param {boolean} refreshSelection
   * @param {components["schemas"]["TermiteConfig"]} termiteConfig
   * @return {Promise<{id?: number, columnIndex: number, name?: string, uri?: string, termiteConfig?: components["schemas"]["TermiteConfig"]} | undefined>}
   */
  const setAttributeVocabs = async (
    attributeId: number,
    vocabIds: number[],
    termiteConfig?: components["schemas"]["TermiteConfig"],
    refreshSelection = true
  ): Promise<undefined | components["schemas"]["Attribute"]> => {
    const attribute = getAttributeById(attributeId);
    const datasetId = useContext().getCurrentDatasetId.value;

    if (!attribute || !datasetId) return;

    if (!attribute.termiteConfig) attribute.termiteConfig = { vocabIds: [] };

    if (!attribute.termiteConfig.vocabIds)
      attribute.termiteConfig.vocabIds = [];

    attribute.termiteConfig.vocabIds = vocabIds;

    const updatedAttribute = await updateAttribute(
      datasetId,
      attribute,
      refreshSelection
    );

    return updatedAttribute;
  };

  /**
   * Adds a vocab from the configuration of specified attributes.
   * @param {number} vocabId
   * @param {number[]} attributes
   * @return {Promise<any[] | components["schemas"]["Attribute"][]>}
   */
  const addVocabToAttributes = async (
    vocabId: number,
    attributes: number[]
  ) => {
    const updatedAttributes: components["schemas"]["Attribute"][] = [];

    if (!attributes.length) return [];

    await awaitAll(attributes, async (attributeId) => {
      const updatedAttribute = await addVocabToAttribute(
        attributeId,
        vocabId,
        false
      );
      updatedAttribute && updatedAttributes.push(updatedAttribute);
    });

    useSelection().refreshSelection();

    return updatedAttributes;
  };

  /**
   * Adds a vocab from the configuration of all selected attributes.
   * @param {number} vocabId
   * @return {Promise<components["schemas"]["Attribute"][]>}
   */
  const addVocabToSelectedAttributes = async (vocabId: number) => {
    const selectedAttributes = useSelection().columnIds.value;

    return addVocabToAttributes(vocabId, selectedAttributes);
  };

  return {
    datasetAttributes,
    attributes,
    getAttributes,
    getAttributeById,
    getAttributeNameById,
    getColumnIndexById,
    updateAttribute,
    removeVocabFromAttribute,
    removeVocabFromSelectedAttributes,
    addVocabToAttribute,
    addVocabToAttributes,
    addVocabToSelectedAttributes,
    setAttributeVocabs,
    updateAttributeTermiteConfig,
    setAttributeConfigSuggestions,
  };
};
