import Axios from "axios";
import { Dataset } from "@/ts/interfaces";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");

type DatasetResponse = Promise<Dataset | undefined>;

/**
 * Get a dataset for a specified dataset id.
 * @param {number} datasetId
 * @returns {DatasetResponse}
 */

const getDatasetById = async (datasetId: number): DatasetResponse => {
  const url = `${baseUrl}/datasets/${datasetId}`;

  const response = await Axios.get(url);

  return response.data;
};

export { getDatasetById };
