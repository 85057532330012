import Axios from "axios";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");

/**
 * Adds a groups from the group api
 * @param groupId - The id of the group you wish to add a member too
 * @param memberIds - The user id you want to be added to the group
 * @param permission - The permission you want the member to have in the group
 */
const addGroupMembers = async (
  groupId: number,
  memberIds: Array<number>,
  permission = "RO"
) => {
  const datasetURL = `${baseUrl}/groups/${groupId}/members`;
  const response = await Axios.post(datasetURL, {
    userId: memberIds,
    permission,
  });

  if (!response.data) return [];

  return response.data;
};

export { addGroupMembers };
