import Axios from "axios";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const ruleUrl = `${baseUrl}/rules`;

type saveRuleRequestBody =
  operations["create"]["requestBody"]["content"]["application/json"];
type saveRuleResponse = Promise<
  operations["create"]["responses"]["200"]["content"]["application/hal+json"]
>;

/**
 * Create a new annotation for multiple values based on a filter.
 * @param {saveRuleRequestBody} query
 * @return {saveRuleResponse}
 */
const saveRule = async (rule: saveRuleRequestBody): saveRuleResponse => {
  const response = await Axios.post(ruleUrl, rule);

  return response.data;
};

export { saveRule };
