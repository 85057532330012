<template>
  <div class="confirm-actions d-inline-block" ref="actionsRef">
    <action-button
      icon
      :icon-code="iconCode"
      :tooltip="tooltip"
      :loading="loading"
      v-bind="$attrs"
    />

    <div class="confirm-sub-actions" ref="subActionsRef">
      <action-button
        v-for="(subAction, index) in subActions"
        icon
        :loading="loading"
        :disabled="$attrs.disabled"
        :top="subAction.pos === 'top'"
        :right="subAction.pos === 'right'"
        :bottom="subAction.pos === 'bottom'"
        :left="subAction.pos === 'left' || !subAction.pos"
        :icon-code="subAction.icon"
        :tooltip="subAction.tooltip"
        :key="index"
        :action="subAction.action"
        :name="subAction.name"
        @click="handleSubActionClick($event, subAction, index)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  Ref,
  toRefs,
} from "vue";
import ActionButton from "@/components/buttons/ActionButton.vue";
import { useNotifications } from "@/compositions";

interface SubAction {
  icon: string;
  tooltip: string;
  name?: string;
  action?: string;
  position?: boolean;
}

export default defineComponent({
  name: "HoverButtonsMenu",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    iconCode: {
      type: String,
      default: "",
    },
    tooltip: {
      type: String,
    },
    subActions: {
      type: Array,
      default: () => [],
    },
    menuPosBottom: {
      type: Boolean,
      default: false,
    },
  },
  components: { ActionButton },
  setup(props, { emit }) {
    const subActionsRef: Ref<HTMLElement | undefined> = ref();
    const actionsRef: Ref<HTMLElement | undefined> = ref();
    const { subActions } = toRefs(props);

    /**
     * Handles a subaction click by sending either click-subaction-name event or click-subaction-index event.
     * @param {MouseEvent} event
     * @param {SubAction} subAction
     * @param {number} index
     */
    const handleSubActionClick = (
      event: MouseEvent,
      subAction: SubAction,
      index: number
    ) => {
      emit("click", event);
      const eventName = subAction.name
        ? `click:${subAction.name}`
        : `click:${index}`;
      emit(eventName, event);
    };

    /**
     * If sub-action names are duplicated throw an error and displays a notification.
     */
    const enforceUniqueSubActionNames = () => {
      const actions = subActions.value as SubAction[];

      if (!actions.length) return;

      const names: string[] = [];

      actions.some((action) => {
        if (!action.name) return false;

        if (names.includes(action.name)) {
          useNotifications().pushNotification({
            title: "Sub action names must be unique",
            text: 'Please remove duplicate action name: " + action.name',
            type: "error",
          });

          useNotifications().setPreventNotifications(true);

          throw new Error(
            "Sub action names must be unique. Please remove duplicate action name: " +
              action.name
          );
        }

        action.name && names.push(action.name);
      });
    };

    const updateSubActionsPosition = () => {
      if (!subActionsRef.value) return;

      const height = subActions.value.length * 36;
      if (props.menuPosBottom) {
        subActionsRef.value.style.top = "36px";
        subActionsRef.value.style.borderRadius = " 0 0 20px 20px";
        if (actionsRef.value) {
          actionsRef.value.style.borderRadius = "20px 20px 0 0";
        }
      } else {
        subActionsRef.value.style.top = "-" + height + "px";
      }
    };

    onBeforeMount(() => {
      enforceUniqueSubActionNames();
    });

    onMounted(() => {
      updateSubActionsPosition();
    });

    return { handleSubActionClick, subActionsRef, actionsRef };
  },
});
</script>

<style lang="scss">
.confirm-actions {
  z-index: 9999;
  position: relative;
  height: fit-content !important;

  &:hover {
    background-color: #e5e5e5 !important;
    border-radius: 0 0 20px 20px;

    .confirm-sub-actions {
      display: block;
    }
  }
  .confirm-sub-actions {
    display: none;
    width: fit-content !important;
    position: absolute;
    background-color: #e5e5e5;
    border-radius: 20px 20px 0 0;
    width: 20px;
    top: -72px;
    left: 0;
  }
}
</style>
