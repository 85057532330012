import Axios from "axios";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const vocabsUrl = `${baseUrl}/vocabs/available`;

type VocabResponse = Promise<
  operations["getAvailable"]["responses"]["200"]["content"]["application/hal+json"]
>;

/**
 * Retrieves all vocabs from the api.
 * @returns Promise<(VocabResponse | undefined)>
 */
const getAllVocabs = async (): Promise<VocabResponse | undefined> => {
  const response = await Axios.get(vocabsUrl);

  return response.data || undefined;
};

export { getAllVocabs };
