import Axios from "axios";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const vocabUrl = `${baseUrl}/vocabs/activateAll`;

type activateVocabResponse =
  operations["activateAllVocabs"]["responses"]["200"]["content"];

/**
 * Activates all vocabs from the api.
 * @returns Promise<(activateVocabResponse | undefined)>
 */
const activateAllVocabs = async (): Promise<
  activateVocabResponse | undefined
> => {
  const response = await Axios.put(vocabUrl);

  return response.data || undefined;
};

export { activateAllVocabs };
