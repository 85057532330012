import Axios from "axios";
import qs from "qs";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");

/**
 * Transform cell values based on specified operation selected by a user
 * @param {number} datasetId
 * @param {number[]} attributeIds
 * @param {"TITLECASE" | "UPPERCASE" | "LOWERCASE"}operation
 * @return {Promise<void>}
 */
const transformValues = async (
  datasetId: number,
  attributeIds: number[],
  operation: "TITLECASE" | "UPPERCASE" | "LOWERCASE"
): Promise<boolean> => {
  const queryString = qs.stringify({ attributeIds: attributeIds, operation: operation}, { arrayFormat: "repeat" });
  const endpoint = `${baseUrl}/datasets/${datasetId}/value/transform?${queryString}`;
  const response = await Axios.put(endpoint);
  return response.status == 200;
};

export { transformValues };
