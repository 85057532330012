import Axios from "axios";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");
const mappingURL = `${baseUrl}/mappings`;

type createMappingResponse =
  operations["updateMappingConfiguration"]["responses"]["200"]["content"]["application/hal+json"];

/**
 * update a mapping configration from the API mapping controller
 * @param {number} id id of the mapping
 * @param {string[]} masks mask terms for mapping
 * @param {String} bestHitsOnly 
 * @param {String} exactMatchOnly 
 * @param {String} cutOff 
 * @param {String} mediatingVocabTermiteName mediating vocab for mapping
 * @param {String} replaceAutomatedMappingsOnly if checked the mapping job deletes only the automated mapping otherwise 
 * it delete all the mappings  
 */
const updateMappingConfiguration = async (
  id: number,
  masks?: string[],
  bestHitsOnly?: boolean,
  exactMatchOnly?: boolean,
  cutOff?: number,
  mediatingVocabTermiteName?: string,
  replaceAutomatedMappingsOnly?: boolean
): Promise<createMappingResponse | undefined> => {
  const response = await Axios.put(`${mappingURL}/${id}/configuration`, {
    masks,
    bestHitsOnly,
    exactMatchOnly,
    cutOff,
    mediatingVocabTermiteName,
    replaceAutomatedMappingsOnly
  });

  if (!response.data) return undefined;

  return response.data;
};

export { updateMappingConfiguration };