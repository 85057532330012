import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import {
  adminOnlyUser,
  authenticateUser,
  middlewareGuard,
} from "@/middlewares/index";
import AppMain from "@/layout/AppMain.vue";
import BareBones from "@/layout/BareBones.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "@/pages/TheDashboard.vue"),
    meta: {
      layout: AppMain,
    },
  },
  {
    path: "/upload",
    name: "Upload",
    component: () =>
      import(/* webpackChunkName: "Upload" */ "@/pages/TheUploader.vue"),
    meta: {
      layout: AppMain,
    },
  },
  {
    path: "/datasets/:datasetId",
    name: "Dataset",
    component: () =>
      import(
        /* webpackChunkName: "DatasetData" */ "@/pages/Dataset/DatasetData.vue"
      ),
    props: true,
    meta: {
      layout: AppMain,
    },
  },
  {
    path: "/datasets/:datasetId/annotations",
    name: "DatasetAnnotations",
    component: () =>
      import(
        /* webpackChunkName: "DatasetAnnotations" */ "@/pages/Dataset/DatasetAnnotations.vue"
      ),
    props: true,
    meta: {
      layout: AppMain,
    },
  },
  {
    path: "/datasets/:datasetId/unannotated",
    name: "DatasetUnannotated",
    component: () =>
      import(
        /* webpackChunkName: "DatasetUnannotated" */ "@/pages/Dataset/DatasetUnannotated.vue"
      ),
    props: true,
    meta: {
      layout: AppMain,
    },
  },
  {
    path: "/datasets/:datasetId/sharing",
    name: "DatasetSharing",
    component: () =>
      import(
        /* webpackChunkName: "DatasetSharing" */ "@/pages/Dataset/DatasetSharing.vue"
      ),
    props: true,
    meta: {
      layout: AppMain,
    },
  },
  {
    path: "/datasets/:datasetId/revisions",
    name: "DatasetRevisions",
    component: () =>
      import(
        /* webpackChunkName: "DatasetRevisions" */ "@/pages/Dataset/DatasetRevisions.vue"
      ),
    props: true,
    meta: {
      layout: AppMain,
    },
  },
  {
    path: "/mappings",
    name: "Mappings",
    component: () =>
      import(/* webpackChunkName: "Mappings" */ "@/pages/Mapping/TheMappings.vue"),
    meta: {
      layout: AppMain,
    },
  },
  {
    path: "/mappingform",
    name: "Mapping Form",
    component: () =>
      import(/* webpackChunkName: "Mappingform" */ "@/pages/Mapping/TheMappingForm.vue"),
    meta: {
      layout: AppMain,
    },
  },
  {
    path: "/mappingform/:mappingId",
    name: "Edit Mapping",
    component: () =>
      import(/* webpackChunkName: "Mappingform" */ "@/pages/Mapping/TheMappingForm.vue"),
    meta: {
      layout: AppMain,
    },
    props: true,
  },
  {
    path: "/mappings/:mappingId",
    name: "Mapping",
    component: () =>
      import(
        /* webpackChunkName: "MappingResult" */ "@/pages/Mapping/MappingResult.vue"
      ),
    props: true,
    meta: {
      layout: AppMain,
    },
  },
  {
    path: "/mappings/:mappingId/configuration",
    name: "MappingConfiguration",
    component: () =>
      import(
        /* webpackChunkName: "MappingResult" */ "@/pages/Mapping/MappingConfiguration.vue"
      ),
    props: true,
    meta: {
      layout: AppMain,
    },
  },
  {
    path: "/profile",
    name: "My Profile",
    component: () =>
      import(/* webpackChunkName: "Rules" */ "@/pages/TheUserProfile.vue"),
    meta: {
      layout: AppMain,
    },
  },
  {
    path: "/releases",
    name: "Workbench Releases",
    component: () =>
      import(/* webpackChunkName: "Rules" */ "@/pages/TheReleases.vue"),
    meta: {
      layout: AppMain,
    },
  },
  {
    path: "/users",
    name: "Users",
    component: () =>
      import(/* webpackChunkName: "users" */ "@/pages/TheUsers.vue"),
    meta: {
      layout: AppMain,
      middleware: [adminOnlyUser],
    },
  },
  {
    path: "/groups",
    name: "Groups",
    component: () =>
      import(/* webpackChunkName: "usersgroups" */ "@/pages/TheUserGroups.vue"),
    meta: {
      layout: AppMain,
    },
  },
  {
    path: "/groups/:groupId",
    name: "EditGroup",
    component: () =>
      import(
        /* webpackChunkName: "usersgroups" */ "@/pages/TheEditUserGroup.vue"
      ),
    meta: {
      layout: AppMain,
    },
  },
  {
    path: "/rules",
    name: "Rules",
    component: () =>
      import(/* webpackChunkName: "Rules" */ "@/pages/TheRules.vue"),
    meta: {
      layout: AppMain,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "Settings" */ "@/pages/TheSettings.vue"),
    meta: {
      layout: AppMain,
      middleware: [adminOnlyUser],
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "@/pages/TheLogin.vue"),
    meta: {
      layout: BareBones,
      class: "login-root",
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "Login" */ "@/pages/Register.vue"),
    meta: {
      layout: BareBones,
      class: "logout-root",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

middlewareGuard(router, [authenticateUser]);

export default router;
