import Axios from "axios";
import { operations } from "@/ts/interfaces/ApiSchemas";
import qs from "qs";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");
const datasetURL = `${baseUrl}/datasets`;

type uploadParsedTextParams =
  operations["loadParsedText"]["parameters"]["query"];

const uploadParsedText = async (
  datasetId: number,
  params: uploadParsedTextParams
) => {
  //Creates the form data to be used for uploading
  const formData = new FormData();

  // Has to been named data for server to recognize the upload
  formData.append("data", params.data);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // The api needs changing, so it doesn't require params of data as I send it as a form
  delete params.data;

  const queryString = qs.stringify(params, { arrayFormat: "comma" });
  const url = `${datasetURL}/${datasetId}/uploadParsedText?${queryString}`;

  const res = await Axios.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return res.status == 200;
};

export { uploadParsedText };
