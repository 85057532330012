import { MiddlewareContext } from "@/ts/interfaces";
import { useUser } from "@/compositions";

/**
 * Allows only users having the ADMIN role to navigate the route. Others will be redirected to the root url.
 * @param {(to?: (RawLocation | false | ((vm: Vue) => any) | void)) => void} next
 * @param {VueRouter} router
 * @return {Promise<void>}
 */
export const adminOnlyUser = async ({ next, router }: MiddlewareContext) => {
  const userRole = await useUser().getUserRole();

  if (userRole !== "WORKBENCH_ADMIN") {
    await router.push("/");
  }

  next();
};
