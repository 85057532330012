<template>
  <v-theme-provider root>
    <component
      :is="this.layout"
      v-if="appIsReady && layout"
      :class="className"
    />
  </v-theme-provider>
</template>

<script lang="ts">
import { useLoginState, useObservers, useRouter } from "@/compositions";
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  onUpdated,
  watch,
} from "vue";
import { useContext } from "@/compositions/useContext";

export default defineComponent({
  setup() {
    const root = getCurrentInstance()?.proxy;
    const { registerObserver, notifyObservers } = useObservers();

    const layout = computed(() => {
      return root?.$route.meta?.layout || null;
    });

    const className = computed(() => {
      return root?.$route?.meta?.class || null;
    });

    const registerNotificationObservers = () => {
      registerObserver("TOKEN_VERIFICATION_SUCCEEDED", {
        observer: "START_NOTIFICATIONS_STREAMING",
      });
      registerObserver("TOKEN_VERIFICATION_SUCCEEDED", {
        observer: "UPDATE_CURRENT_USER",
      });
      registerObserver("ANNOTATION_STARTED", {
        observer: "DISPLAY_ANNOTATE_NOTIFICATIONS",
      });
      registerObserver("ANNOTATION_COMPLETE", {
        observer: "DISPLAY_ANNOTATE_NOTIFICATIONS",
      });
      registerObserver("ANNOTATION_ERROR", {
        observer: "DISPLAY_ANNOTATE_NOTIFICATIONS",
      });
      registerObserver("DATA_EXPORT_STARTED", {
        observer: "NOTIFY_DATA_EXPORT_STARTED",
      });
      registerObserver("DATA_EXPORT_COMPLETE", {
        observer: "NOTIFY_DATA_EXPORT_COMPLETE",
      });
      registerObserver("MAPPING_EXPORT_STARTED", {
        observer: "NOTIFY_MAPPING_EXPORT_STARTED",
      });
      registerObserver("MAPPING_EXPORT_COMPLETE", {
        observer: "NOTIFY_MAPPING_EXPORT_COMPLETE",
      });
    };

    onBeforeMount(() => {
      registerNotificationObservers();
    });

    onMounted(() => {
      useContext().initContext();
      useLoginState().verifyLoginStatus();
    });

    onUpdated(() => {
      useLoginState().verifyLoginStatus();
    });

    watch(
      () => root?.$route,
      (route, oldRoute) => {
        if (!route) return;
        useRouter().setCurrentRoute(route);
        notifyObservers("ROUTE_CHANGED", { route, oldRoute });
      }
    );

    const appIsReady = useLoginState().getAppIsReady;

    return { appIsReady, layout, className };
  },
});
</script>

<style lang="scss">
.login-root,
.logout-root {
  background-image: url("~@/assets/workbench-login-background.jpg") !important;
  background-size: cover !important;
}
</style>
