import Axios from "axios";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");
const mappingURL = `${baseUrl}/mappings`;

type createMappingResponse =
  operations["create"]["responses"]["200"]["content"]["application/hal+json"];

/**
 * Creates a mapping from the API mapping controller
 * @param {String} mappingId The title you wish the mapping to have
 */
const startMappingJob = async (mappingId: number) => {
  const response = await Axios.post(`${mappingURL}/${mappingId}/startOntologyMappingJob`);
  return response.status == 200;
};

export { startMappingJob };