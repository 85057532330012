import Axios from "axios";
import { components, operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const annotationsUrl = `${baseUrl}/datasets`;

type AnnotationsForValueRequest =
  operations["getAnnotationsForValue"]["parameters"]["path"];
type AnnotationsForValueResponse = Promise<
  components["schemas"]["Annotation"][]
>;

/**
 * Retrieves annotations for a single value.
 * @param {AnnotationsForValueRequest} pathParams
 * @return {AnnotationsForValueResponse}
 */
const getAnnotationsForValue = async (
  pathParams: AnnotationsForValueRequest
): AnnotationsForValueResponse => {
  const { id: datasetId, value_id: valueId } = pathParams;

  const url = `${annotationsUrl}/${datasetId}/values/${valueId}/annotations`;

  const response = await Axios.get(url);

  return response.data || [];
};

export { getAnnotationsForValue };
