import Axios from "axios";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const endpoint = `${baseUrl}/datasets`;

type CreateValueRequest = {
  datasetId: number;
  attributeId: number;
  rowId: number;
  value: string;
};

type CreateValuedResponse = {
  id: number;
  value: string;
  rowId: number;
};

/**
 * Requests to create a single cell value.
 * @param {number} datasetId The dataset id
 * @param {number} attributeId The cell id
 * @param {string} value The new value
 * @returns {Promise<CreateValuedResponse>}
 */
export const createValue = async ({
  datasetId,
  attributeId,
  rowId,
  value,
}: CreateValueRequest): Promise<CreateValuedResponse> => {
  const url = `${endpoint}/${datasetId}/row/${rowId}/attribute/${attributeId}/value`;
  const response = await Axios.post(url, { value });

  return response.data;
};
