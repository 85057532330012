import Axios from "axios";
import { components } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const meUrl = `${baseUrl}/users/me/internal`;

type User = components["schemas"]["User"];

/**
 * Retrieves user information from the api.
 * @returns Promise<(<User> | {}>
 */
const getUserInfo = async (): Promise<User | undefined> => {
  const response = await Axios.get(meUrl);

  return response.data;
};

export { getUserInfo };
