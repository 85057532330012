import Axios from "axios";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");

/**
 * Delete a mapping emtru for a specified mapping result id.
 * @param {number} mappingId
 * @param {number} mappingResultId
 * @returns {MappingResponse}
 */

const deleteMappingResultById = async (mappingId: number, mappingResultId: number): Promise<boolean> => {
  const url = `${baseUrl}/mappings/${mappingId}/mappingResults/${mappingResultId}`;
  const response = await Axios.delete(url);
  return response.status === 200;
};

export { deleteMappingResultById };
