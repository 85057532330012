<template>
  <v-container>
    <v-row>
      <div class="col-8 p-0">
        <v-checkbox
          v-model="manualAnnotationOn"
          :label="manualAnnotationSwitchLabel"
        ></v-checkbox>
      </div>
      <div class="col-4 p-0">
        <v-checkbox
          v-model="saveAsRuleChecked"
          :label="manualAnnotationOn ?  saveRuleAnnotatedManuallyLabel : saveRuleAnnotatedWithTermiteLabel"
          />
      </div>
    </v-row>
    <v-row>
      <div class="mb-2">
        <span class="mr-2">Annotate against:</span>
        <words-selector
          v-for="(cell, i) in cellValue"
          :key="cell + i"
          :phrase="cell"
          :initial-selection="selectedWords[i]"
          @change="updateSelectedWords($event, i)"
        />
      </div>
    </v-row>

    <template v-if="manualAnnotationOn">
      <v-form>
        <v-row>
          <v-text-field
            v-model="newAnnotationLabel"
            required
            validate-on-blur
            :rules="labelRules"
            @input="debouncedUpdateManualAnnotationValue"
          >
            <template #label>
              Label <span class="red--text font-weight-bold">*</span>
            </template>
          </v-text-field>
        </v-row>

        <v-row>
          <v-text-field
            v-model="newAnnotationPrimaryId"
            :rules="primaryIdRules"
            required
            validate-on-blur
            @input="debouncedUpdateManualAnnotationValue"
          >
            <template #label>
              Primary ID <span class="red--text font-weight-bold">*</span>
            </template>
          </v-text-field>
        </v-row>

        <v-row>
          <v-text-field
            v-model="newAnnotationVocabId"
            required
            validate-on-blur
            :rules="vocabIdRules"
            @input="debouncedUpdateManualAnnotationValue"
          >
            <template #label>
              Vocab/Ontology id
              <span class="red--text font-weight-bold">*</span>
            </template>
          </v-text-field>
        </v-row>

        <v-row>
          <v-text-field
            label="Vocab IRI"
            v-model="newAnnotationPrimaryIri"
            @input="debouncedUpdateManualAnnotationValue"
          ></v-text-field>
        </v-row>
      </v-form>
    </template>

    <template v-else>
      <v-row>
        <v-text-field
          label="TERMite lookup"
          v-model="lookupInput"
          @input="handleLookupInput"
        ></v-text-field>
      </v-row>
      <v-skeleton-loader
        type="table-heading, table-thead, table-row-divider@3"
        v-if="isLookupTermiteTableLoading"
      ></v-skeleton-loader>
      <v-simple-table :disable-pagination="true" v-else-if="termiteHits.length">
        <template v-slot:default>
          <thead>
            <tr>
              <th
                class="text-left font-weight-bold"
                v-for="header in termiteSearchHeaders"
                :key="header.value"
              >
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="hit in termiteHits" :key="hit.id">
              <td>
                <v-chip label small class="orange">{{
                  hit.termiteVocabId
                }}</v-chip>
              </td>
              <td>
                <span>{{ hit.label }}</span>
              </td>
              <td>
                <v-tooltip top :disabled="!!hit.primaryUri.length">
                  <template v-slot:activator="{ on, attrs }">
                    <span class="orange--text" v-on="on" v-bind="attrs">{{
                      hit.primaryId
                    }}</span>
                  </template>
                  {{ hit.primaryUri }}
                </v-tooltip>
              </td>
              <td>
                <v-tooltip top :disabled="hit.synonyms.length < 4">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">{{
                      truncateSynonyms(hit.synonyms)
                    }}</span>
                  </template>
                  <ul class="p-0 m-0">
                    <li
                      class="pa-0 m-0"
                      v-for="(synonym, index) in hit.synonyms"
                      :key="index"
                    >
                      {{ synonym }}
                    </li>
                  </ul>
                </v-tooltip>
              </td>
              <td>
                <v-simple-checkbox
                  :value="selectedVocabs.includes(hit)"
                  @input="handleSelectVocab(hit)"
                />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div
        class="d-flex justify-center"
        v-if="!termiteHits.length && !isLookupTermiteTableLoading"
      >
        <div class="no-search-results">
          <v-icon large> mdi-magnify </v-icon>
          <span>Sorry, no results found.</span>
        </div>
      </div>
    </template>
  </v-container>
</template>
<script lang="ts">
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  ref,
  Ref,
  watch,
} from "vue";
import { cloneDeep, debounce } from "lodash";
import { lookup } from "@/api";
import { components } from "@/ts/interfaces/ApiSchemas";
import { useAppDialog, useRouter, useSelection } from "@/compositions";
import WordsSelector from "@/components/common/WordsSelector.vue";

export default defineComponent({
  name: "AddAnnotationDialogue",
  components: {
    WordsSelector,
  },
  setup() {
    const manualAnnotationSwitchLabel = "Annotate manually";
    const saveRuleAnnotatedWithTermiteLabel = "Save annotations as rules";
    const saveRuleAnnotatedManuallyLabel = "Save manual annotation as a rule";
    const isLookupTermiteTableLoading: Ref<boolean> = ref(false);
    const saveAsRuleChecked: Ref<boolean> = ref(false);

    const cellValue = computed(() => {
      const route = useRouter().getCurrentRoute().value;
      if (
        route?.name === "DatasetAnnotations" ||
        route?.name === "DatasetUnannotated"
      ) {
        return useAppDialog().getArgs.value?.selectedRowsFromAnnotationTable?.map(
          (row: { value: unknown }) => row.value
        );
      }
      return [useSelection().getSelection.value?.data.selectedCell?.label];
    }) as ComputedRef<string[]>;

    const selectedWords: Ref<Array<string[]>> = ref(
      cellValue.value.map((value) => value.split(" ")) || []
    );

    /**
     * Switch element value, determines whether the form should show manual annotation fields or termite search.
     * @type {Ref<UnwrapRef<boolean>>}
     */
    const manualAnnotationOn: Ref<boolean> = ref(false);

    /**
     * Vocabs that have been selected for annotation.
     * @type {Ref<UnwrapRef<any[]>>}
     */
    const selectedVocabs: Ref<components["schemas"]["AnnotationHit"][]> = ref(
      []
    );

    /**
     * Updates the confirmation action arguments.
     */
    const updateConfirmActionArgs = () => {
      const annotationHits: components["schemas"]["AnnotationHit"][] =
        cloneDeep(selectedVocabs.value);
      
      const annotationHitsOnMultipleWords: components["schemas"]["AnnotationHit"][] =
        [];
      const selectedWordsForFilter: string[] = [];

      //Relies on selectedWords to be in the same order as cellValues, if not in the same order this will cause a problem.
      selectedWords.value.forEach((words, i) => {
        const wordsJoined = words.join(" ");
        if (wordsJoined.trim().length) selectedWordsForFilter.push(wordsJoined);
        const isPartial = wordsJoined.length !== cellValue.value[i].length;

        annotationHits.forEach((hit) => {
          const modifiedHit = {
            ...hit,
            matchingName: wordsJoined,
            partial: isPartial,
          };
          //if the user has crossed the full word out do not create a 'hit'
          if (wordsJoined === "") return;
          annotationHitsOnMultipleWords.push(modifiedHit);
        });
      });

      useAppDialog().setConfirmActionArgs({
        annotationHitsOnMultipleWords,
        selectedWordsForFilter,
        saveAsRuleChecked
      });
    };

    /**
     * Updates the currently selected call value words.
     * @param {string[]} selected
     * @param index
     */
    const updateSelectedWords = (selected: string[], index: number) => {
      selectedWords.value[index] = selected;
      updateConfirmActionArgs();
    };

    //#region Manual Annotation Form
    const newAnnotationLabel: Ref<string | undefined> = ref();
    const newAnnotationDescription: Ref<string | undefined> = ref();
    const newAnnotationVocabId: Ref<string | undefined> = ref();
    const newAnnotationPrimaryId: Ref<string | undefined> = ref();
    const newAnnotationPrimaryIri: Ref<string | undefined> = ref();
    const labelRules = [(label: string) => !!label || "Label is required"];
    const primaryIdRules = [(id: string) => !!id || "Primary ID is required"];
    const vocabIdRules = [(id: string) => !!id || "Vocab ID is required"];

    /**
     * Holds the value used to manually create a new annotation.
     * @type {Ref<Record<string, string | undefined>>}
     */
    const currentManualAnnotationVocab: Ref<
      components["schemas"]["AnnotationHit"]
    > = ref({});

    /**
     * Updates the current manually created vocab when a manual field is updated.
     */
    const updateManualAnnotationValue = () => {
      const currentManualAnnotationVocabList = selectedWords.value.map(
        (words, i) => {
          const wordsJoined = words.join(" ");
          return {
            label: newAnnotationLabel.value,
            primaryId: newAnnotationPrimaryId.value,
            termiteVocabId: newAnnotationVocabId.value,
            primaryUri: newAnnotationPrimaryIri.value,
            matchingName: wordsJoined,
            partial: wordsJoined.length !== cellValue.value[i].length,
          };
        }
      );

      useAppDialog().setConfirmActionArgs({
        annotationHitsOnMultipleWords: currentManualAnnotationVocabList,
        saveAsRuleChecked: saveAsRuleChecked
      });
    };
    //#endregion Manual Annotation Form

    const debouncedUpdateManualAnnotationValue = debounce(
      updateManualAnnotationValue,
      100
    );

    //#region Annotate with Termite Form

    /**
     * Holds termite search results.
     * @type {Ref<UnwrapRef<any[]>>}
     */
    const termiteHits: Ref<components["schemas"]["AnnotationHit"][]> = ref([]);

    /**
     * The termite search input text.
     * @type {Ref<UnwrapRef<string>>}
     */
    const lookupInput = ref("");

    /**
     * Termite search results table headers.
     * @type { {text: string}[] }
     */
    const termiteSearchHeaders = ["Type", "Name", "ID", "Hit Synonyms", ""].map(
      (text) => {
        return { text };
      }
    );

    /**
     * The first 4 synonyms coma separated followed by '...' if the total hits are more than 4.
     * @param {string[]} synonyms
     * @return {string}
     */
    const truncateSynonyms = (synonyms: string[]) => {
      const truncatedArray = synonyms.slice(0, 4);
      const stringifiedSynonyms = truncatedArray.join(", ");

      const suffix = synonyms.length > 4 ? "..." : "";

      return stringifiedSynonyms + suffix;
    };

    /**
     * Handler for the vocab selection checkbox. Adds vocabs to the selectedVocabs array.
     * @param {components["schemas"]["AnnotationHit"]} hit
     */
    const handleSelectVocab = (hit: components["schemas"]["AnnotationHit"]) => {
      const hitIndex = selectedVocabs.value.indexOf(hit);

      if (hitIndex === -1) {
        selectedVocabs.value.push(hit);
      } else {
        selectedVocabs.value.splice(hitIndex, 1);
      }

      updateConfirmActionArgs();
    };

    /**
     * Performs a termite API request 300 milliseconds after no keystroke
     * is detected in the termite search input field.
     * @type {DebouncedFunc<() => Promise<void>>}
     */
    const vocabsLookupDebounced = debounce(async () => {
      isLookupTermiteTableLoading.value = true;
      termiteHits.value = await lookup({
        query: lookupInput.value,
        exact: false,
      });
      isLookupTermiteTableLoading.value = false;
    }, 300);

    /**
     * Handler for the termite search field input event.
     */
    const handleLookupInput = () => {
      vocabsLookupDebounced.cancel();
      vocabsLookupDebounced();
    };

    //#endregion Annotate with Termite Form

    /**
     * If the confirm button should be disabled.
     * @type {ComputedRef<boolean>}
     */
    const shouldDisableConfirmButton = computed(() => {
      if (manualAnnotationOn.value)
        return !newAnnotationLabel.value || !newAnnotationVocabId.value;

      return selectedVocabs.value.length === 0;
    });

    /**
     * Updates the confirm action args when switching between termite and manual annotation.
     */
    watch(manualAnnotationOn, (isOn) => {
      if (isOn) {
        useAppDialog().setConfirmActionArgs([
          currentManualAnnotationVocab.value,
        ]);
      } else {
        useAppDialog().setConfirmActionArgs(selectedVocabs.value);
      }
    });

    watch(saveAsRuleChecked, () => {
      if (manualAnnotationOn.value) {
        updateManualAnnotationValue();
      } else {
        updateConfirmActionArgs();
      }
    });

    /**
     * Updates the confirm button state.
     */
    watch(shouldDisableConfirmButton, (shouldDisable) => {
      useAppDialog().setConfirmButtonDisabled(shouldDisable);
    });

    function setDefaultTermiteSearchTerm() {
      if (cellValue.value.length) {
        lookupInput.value = cellValue.value[0];
        vocabsLookupDebounced();
      }
    }

    onBeforeMount(() => {
      setDefaultTermiteSearchTerm();
    });

    return {
      manualAnnotationOn,
      manualAnnotationSwitchLabel,
      termiteSearchHeaders,
      termiteHits,
      lookupInput,
      selectedVocabs,
      newAnnotationLabel,
      newAnnotationDescription,
      newAnnotationVocabId,
      newAnnotationPrimaryId,
      newAnnotationPrimaryIri,
      labelRules,
      primaryIdRules,
      vocabIdRules,
      cellValue,
      selectedWords,
      handleLookupInput,
      truncateSynonyms,
      handleSelectVocab,
      updateManualAnnotationValue,
      updateSelectedWords,
      isLookupTermiteTableLoading,
      debouncedUpdateManualAnnotationValue,
      saveRuleAnnotatedWithTermiteLabel,
      saveRuleAnnotatedManuallyLabel,
      saveAsRuleChecked,
    };
  },
});
</script>
<style scoped>
.p-0 { padding: 0px;}
</style>
