import { components } from "@/ts/interfaces/ApiSchemas";
import { PanelItemLabel } from "@/ts/interfaces";

/**
 * Retrieves vocabs for specified vocab ids and extracts the names.
 * @param {number[]} vocabIds
 * @return {String[]} An array of vocab names.
 */
const getVocabsLabelsByIds = (
  vocabs: components["schemas"]["Vocab"][],
  vocabIds: number[]
): PanelItemLabel[] => {
  if (!vocabIds.length || !vocabs.length) return [];

  const vocabsNames: PanelItemLabel[] = [];

  vocabs.forEach((vocab) => {
    const vocabId = vocab.id && vocab.id;

    if (vocabId && vocab.name && vocabIds.includes(vocabId))
      vocabsNames.push({
        name: vocab.name,
        id: vocabId,
      });
  });

  return vocabsNames;
};

export { getVocabsLabelsByIds };
