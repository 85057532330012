import Axios from "axios";
import qs from "qs";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const datasetUrl = `${baseUrl}/datasets`;

type exportDataParams = operations["exportDataset"]["parameters"]["query"];
type exportDataResponse =
  operations["exportDataset"]["responses"]["200"]["content"]["application/hal+json"];

const exportDataset = async (
  datasetId: number,
  params: exportDataParams
): Promise<exportDataResponse | undefined> => {
  const queryString = qs.stringify(params, { arrayFormat: "repeat" });
  const url = `${datasetUrl}/${datasetId}/export?${queryString}`;

  const res = await Axios.post(url);

  return res.data || undefined;
};

export { exportDataset };
