import { computed, ref, Ref } from "vue";

const documentPageScrollOffsetY: Ref<number> = ref(0);

export const useScrollSpy = () => {
  const getMainCurrentContainerScroll = computed(() => {
    return documentPageScrollOffsetY.value;
  });

  const setMainCurrentContainerScroll = (offset: number) => {
    documentPageScrollOffsetY.value = offset;
  };

  return {
    getMainCurrentContainerScroll,
    setMainCurrentContainerScroll,
    documentPageScrollOffsetY,
  };
};
