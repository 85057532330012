import { ref, Ref } from "vue";
import { useAttributes } from "@/compositions/useAttributes";
import { useContext } from "@/compositions/useContext";
import { TermiteConfigOption } from "@/ts/types";

export const useOntologySelection = () => {
  const termiteOptions: Ref<
    Record<TermiteConfigOption, Record<number, boolean>>
  > = ref({
    includeAmbiguous: {},
    subsume: {},
    exactMatch: {},
    includeCellsThatAreNumbers: {},
    includeAnnotationRulesAndStopwords: {},
  });

  // TODO: remove these variables after updating OntologySelectionPanel to use termiteOptions instead.
  const includeAmbiguous: Ref<Record<number, boolean>> = ref({});
  const subsume: Ref<Record<number, boolean>> = ref({});
  const exactMatch: Ref<Record<number, boolean>> = ref({});
  const includeCellsThatAreNumbers: Ref<Record<number, boolean>> = ref({});
  const includeAnnotationRulesAndStopwords: Ref<Record<number, boolean>> = ref(
    {}
  );

  const optionNames: TermiteConfigOption[] = [
    "exactMatch",
    "subsume",
    "includeAmbiguous",
    "includeAnnotationRulesAndStopwords",
    "includeCellsThatAreNumbers",
  ];

  /**
   * Initialise the termite options of a component that uses vocab selection.
   * @todo remove `includeAmbiguous` `subsume` `exactMatch` and other single variables update after removing it
   * from OntologySelectionPanel
   * @param {number[]} attributesIds
   */
  const initTermiteOptions = (attributesIds: number[]) => {
    const datasetId = useContext().getCurrentDatasetId.value;

    if (!datasetId) return;

    attributesIds.forEach((attributeId) => {
      const attribute = useAttributes().getAttributeById(attributeId);

      if (!attribute) return;

      termiteOptions.value.includeAmbiguous[attributeId] =
        attribute.termiteConfig?.includeAmbiguous || false;
      termiteOptions.value.subsume[attributeId] =
        attribute.termiteConfig?.subsume || false;
      termiteOptions.value.exactMatch[attributeId] =
        attribute.termiteConfig?.exactMatch || false;
      termiteOptions.value.includeCellsThatAreNumbers[attributeId] =
        attribute.termiteConfig?.includeCellsThatAreNumbers || false;
      termiteOptions.value.includeAnnotationRulesAndStopwords[attributeId] =
        attribute.termiteConfig?.includeAnnotationRulesAndStopwords || false;

      // TODO: remove these after updating OntologySelectionPanel to use termiteOptions instead.
      includeAmbiguous.value[attributeId] =
        attribute.termiteConfig?.includeAmbiguous || false;
      subsume.value[attributeId] = attribute.termiteConfig?.subsume || false;
      exactMatch.value[attributeId] =
        attribute.termiteConfig?.exactMatch || false;
      includeCellsThatAreNumbers.value[attributeId] =
        attribute.termiteConfig?.includeCellsThatAreNumbers || false;
      includeAnnotationRulesAndStopwords.value[attributeId] =
        attribute.termiteConfig?.includeAnnotationRulesAndStopwords || false;
    });
  };

  /**
   * Checks if a component has active custom termite options activated.
   * @param {number} attributeId
   * @return {boolean}
   */
  const hasCustomFilters = (attributeId: number) => {
    return optionNames.some((optionName) => {
      return !!termiteOptions.value[optionName][attributeId];
    });
  };

  const activeFiltersCount = (attributeId: number) => {
    let count = 0;

    optionNames.forEach((optionName) => {
      termiteOptions.value[optionName][attributeId] && count++;
    });

    return count;
  };

  return {
    termiteOptions,
    includeAmbiguous,
    subsume,
    exactMatch,
    includeCellsThatAreNumbers,
    includeAnnotationRulesAndStopwords,
    hasCustomFilters,
    initTermiteOptions,
    activeFiltersCount,
  };
};
