import Axios from "axios";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");

/**
 * deletes a groups from the group api
 * @param groupId - The id of the group
 */
const deleteGroup = async (groupId: number) => {
  const datasetURL = `${baseUrl}/groups/${groupId}`;

  const response = await Axios.delete(datasetURL);

  if (!response.data) return;

  return response.data;
};

export { deleteGroup };
