import { AxiosError } from "axios";
import { handleLoginUnsuccessful } from "@/interceptors/handleLoginUnsuccessful";
import { isTargetEndpoint, isTargetStatus } from "@/utils";
import { ErrorHandler } from "@/ts/types";
import { handleApiErrors } from "./handleApiErrors";

/**
 * List of error handlers that need to be processed.
 * @type {{endpoint: string, handle: (error: AxiosError) => void, status: number[]}[]}
 */
const errorHandlers: ErrorHandler[] = [handleLoginUnsuccessful, handleApiErrors];

/**
 * Executes an error handler if requested endpoint and response status match.
 * @param {AxiosError} error
 * @param {ErrorHandler} errorHandler
 */
export const processHandler = (error: AxiosError, handler: ErrorHandler) => {
  if (!isTargetStatus(handler.status, error.response?.status)) return;
  if (!isTargetEndpoint(handler.endpoint, error.request)) return;

  void handler.handle(error);
};

/**
 * Runs all the Axios error handlers.
 * @param {AxiosError} error
 */
export const handleErrors = (error: AxiosError) => {
  for (const handler of errorHandlers) {
    processHandler(error, handler);
  }
};
