<template>
  <v-card
    outlined
    elevation="0"
    class="job-queue-item ml-2"
    :disabled="job.status === 'ongoing'"
  >
    <v-card-text>
      <component
        :is="job.url ? 'router-link' : 'span'"
        :to="job.url"
        class="
          ml-2
          font-weight-medium
          job-queue-item__text
          text-decoration-none
        "
        >{{ job.label }} 
      </component>
      
      <small v-if="job.jobType == 'ANNOTATE' && job.status === 'ongoing'"> (Annotating...) </small>
      <small v-if="job.jobType == 'UPLOAD' && job.status === 'ongoing'"> (Uploading...) </small> 
      <small v-if="job.jobType == 'MAPPING' && job.status === 'ongoing'"> (Mapping...) </small> 
      <v-progress-circular
        v-if="job.status === 'ongoing'"
        indeterminate
        size="14"
        width="2"
        class="job-queue-item__loading-spinner"
      />

      <v-icon
        v-if="job.status === 'complete'"
        color="success"
        class="mr-2 job-queue-item__complete"
        @click="removeItem(job.id)"
      >
        mdi-check
      </v-icon>
      <br/>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import { useJobsQueue } from "@/compositions/useJobsQueue";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    job: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { removeJob } = useJobsQueue();

    const removeItem = (jobId: number) => {
      removeJob(jobId);
    };
    return { removeItem };
  },
});
</script>
