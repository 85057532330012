import { computed, reactive, UnwrapRef } from "vue";
import { JobElement } from "@/ts/interfaces";

const jobs: UnwrapRef<{ list: Array<JobElement> }> = reactive({ list: [] });

/**
 * Define Jobs Queue element reactive property and methods to interact with them.
 * @return { {
 *  runningJobs: UnwrapRef<{list: Array<JobElement>}>,
 *  removeJob: (jobId) => void,
 *  queueJob: (job: JobElement) => void,
 *  updateJobStatus: (jobId, newStatus) => void } }
 */
export const useJobsQueue = () => {
  /**
   * Retrieves the currently running jobs in the jobs reactive object.
   * @type {ComputedRef<UnwrapRef<{list: Array<JobElement>}>>}
   */
  const runningJobs = computed(() => {
    return jobs;
  });

  /**
   * Adds a job to the jobs.list array.
   * @param {JobElement} job
   */
  const queueJob = (job: JobElement) => {
    runningJobs.value.list.push(job);
  };

  /**
   * Given a job id and job status updates the status property of the specified job in the jobs.list reactive object.
   * @param {number} jobId
   * @param {"ongoing" | "complete"} newStatus
   */
  const updateJobStatus = (
    jobId: number,
    newStatus: "ongoing" | "complete"
  ) => {
    runningJobs.value.list.forEach((job) => {
      if (job.id === jobId) job.status = newStatus;
    });
  };

  /**
   * Deletes a job from the jobs.list reactive object.
   * @param {number} jobId
   */
  const removeJob = (jobId: number) => {
    runningJobs.value.list = runningJobs.value.list.filter((job) => {
      return job.id !== jobId;
    });
  };

  return { runningJobs, queueJob, updateJobStatus, removeJob };
};
