import Axios from "axios";
import { components } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");

type getAllGroupMembersResponse = components["schemas"]["GroupMember"][];
/**
 * Get all group members for a specified group id.
 * @param {number} groupId - The id of the groups member you want
 * @returns {DatasetResponse}
 */

const getAllGroupMembers = async (
  groupId: number
): Promise<getAllGroupMembersResponse | undefined> => {
  const url = `${baseUrl}/groups/${groupId}/members`;

  const response = await Axios.get(url);

  return response.data._embedded.groupMembers || undefined;
};

export { getAllGroupMembers };
