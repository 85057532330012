import Axios from "axios";
import { components } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");

/**
 * Get all group members for a specified group id.
 * @param {number} groupId - The id of the groups member you want
 * @returns {DatasetResponse}
 */

const getMembersById = async (
  groupIds: number[]
): Promise<components["schemas"]["GroupsByIdResponseItem"][] | undefined> => {
  const url = `${baseUrl}/groups/members-by-id`;

  const response = await Axios.post(url, { ids: groupIds });

  return response.data?._embedded?.groupsByIdResponseItems || undefined;
};

export { getMembersById };
