import Axios from "axios";
import { Mapping, MappingParams } from "@/ts/interfaces";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const mappingURL = `${baseUrl}/mappings`;

type MappingResponse = Promise<Mapping[] | never[]>;

type MappingPagesReponse = Promise<
  | { number: number; size: number; totalElements: number; totalPages: number }
  | never
>;

/**
 * Retrieves mappings from the API Mapping controller.
 * @param {Number} page Page you want to retrieve
 * @param {Number} size Number of records per page
 * @param {('asc' | 'desc')} sort Sorting criteria in the format
 * @returns Promise<(Array<Mapping> | undefined)>
 */
const getMappings = async ({
  sharing_mode,
  page,
  sort,
  size,
  group_id,
}: MappingParams): MappingResponse => {
  const response = await Axios.get(mappingURL, {
    params: { sharing_mode, page, size, sort, group_id },
  });
  if (!response.data._embedded || !response.data._embedded.ontologyMappings) return [];

  return response.data;
};

const getMappingsAndPages = async ({
  sharing_mode,
  page,
  sort,
  size,
  group_id,
}: MappingParams) => {
  const response = await Axios.get(mappingURL, {
    params: { sharing_mode, page, size, sort, group_id },
  });
  if (!response.data._embedded || !response.data._embedded.ontologyMappings) return [];

  return response.data;
};

/**
 * [getMappingsPages description]
 *
 * @param {Number} page Page you want to retrieve
 * @param {Number} size Number of records per page
 * @param {('asc' | 'desc')} sort Sorting criteria in the format
 *
 * @return  {[type]}                [return description]
 */
const getMappingsPages = async ({
  sharing_mode,
  page,
  sort,
  size,
}: MappingParams): MappingPagesReponse => {
  const response = await Axios.get(mappingURL, {
    params: { sharing_mode, page, size, sort },
  });

  return response.data.page;
};

/**
 * [deletemapping description]
 *
 * @param   {number}  id  [id description]
 *
 * @return  {[type]}      [return description]
 */
const deleteMapping = async (id: number): Promise<Response> => {
  const response = await Axios.delete(`${mappingURL}/${id}`);
  return response.data;
};

export { getMappings, getMappingsPages, deleteMapping, getMappingsAndPages };
