import Axios from "axios";
import { components, operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const annotationsUrl = `${baseUrl}/datasets`;

type UpdateAnnotationById =
  operations["updateAnnotationById"]["parameters"]["path"];
type AnnotationHit = components["schemas"]["AnnotationHit"];
type UpdateAnnotationByIdResponse =
  operations["updateAnnotationById"]["responses"]["200"]["content"]["application/hal+json"];

/**
 * Retrieves annotations for a single value.
 * @return {verifyAnnotationResponse}
 * @param pathParams
 * @param hit
 */
export const updateAnnotationById = async (
  pathParams: UpdateAnnotationById,
  hit: AnnotationHit
): Promise<UpdateAnnotationByIdResponse> => {
  const { id: dataset_id,  annotation_id } = pathParams;
  const url = `${annotationsUrl}/${dataset_id}/annotations/${annotation_id}`;

  const response = await Axios.put(url, hit);

  return response.data as UpdateAnnotationByIdResponse;
};
