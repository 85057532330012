import { MessageEventEncoded, components } from "@/ts/interfaces";
import { Observer } from "@/ts/types";

/**
 * Registers an action to show show a notification when a dataset upload has started and when is ended.
 */
export const updateAnnotationProgressbar: Observer = (args) => {
  const event = args.event as MessageEventEncoded;
  if (args.subjectName == "ANNOTATION_RUNNING" && args.event) {
    const job = event.data.job as components["schemas"]["Job"];
    const loadAnnotationProgress = args.loadAnnotationProgress as (annocatationJob: components["schemas"]["Job"]) => void;
    loadAnnotationProgress(job);

  } else {
    const loadDatasetSummary = args.loadDatasetSummary as () => void;
    loadDatasetSummary();

  }
  
  return true;
};

export default updateAnnotationProgressbar;
