<template>
  <v-app-bar
    class="main-navbar foreground"
    data-testid="main-navbar"
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    :clipped-right="$vuetify.breakpoint.lgAndUp"
    app
    outlined
    elevation="1"
  >
    <v-toolbar-title style="width: 300px" class="ml-0">
      <router-link to="/">
        <span class="hidden-sm-and-down">
          <v-img
            src="../../assets/workbench_logo_strap.png"
            width="260"
            alt="SciBite Workbench Logo"
          />
        </span>
      </router-link>
    </v-toolbar-title>
    
    <v-spacer></v-spacer>
    {{ pageTitle }}
    <v-menu transition="slide-y-transition" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, i) in menuItems"
          :key="i"
          :[item.typeOfLink]="item.link"
        >
          <v-icon class="pr-3" color="secondary">{{ item.icon }}</v-icon>
          {{ item.text }}
        </v-list-item>
        <v-list-item @click="$emit('showMyJobs', $event)">
          <v-icon class="pr-3">mdi-file-multiple</v-icon>
          My Jobs
        </v-list-item>
        <v-list-item @click.prevent="logout">
          <v-icon class="pr-3">mdi-logout</v-icon>
          Log out
        </v-list-item>
        <v-list-item disabled>
          <v-icon disabled class="pr-3">mdi-theme-light-dark</v-icon>
          <v-switch disabled v-model="$vuetify.theme.dark" inset></v-switch>
        </v-list-item>
      </v-list>
    </v-menu>


      <v-menu transition="slide-y-transition" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-help</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item to="/releases">
            <v-icon class="pr-3"></v-icon>
            Releases
          </v-list-item>
        </v-list>
      </v-menu>
  </v-app-bar>
</template>

<script lang="ts">
import {
  defineComponent,
  getCurrentInstance,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  Ref,
  ref,
} from "vue";
import { API_TOKEN_NAME } from "@/globals";
import { useLoginState, useUser } from "@/compositions";
// import NavSearchBar from "./NavSearchBar.vue";
import { MenuItem } from "@/ts/types";
import { serverUrl } from "@/utils";
import { useKeycloak } from "@/compositions/useKeycloak";
import Axios from "axios";

export default defineComponent({
  name: "MainNavbar",
  props: {
    pageTitle: {
      type: String,
    },
  },
  // components: { NavSearchBar },
  setup() {
    const { setLoggedIn, getUserRole, setUserRole } = useUser();
    const root = getCurrentInstance();

    const swaggerUrl = serverUrl("swagger");

    const menuItems: Ref<Array<MenuItem>> = ref([
      {
        icon: "mdi-account",
        text: "My Profile",
        link: "/profile",
        typeOfLink: "to",
      },
      {
        icon: "mdi-code-braces",
        text: "Api Reference",
        link: swaggerUrl,
        target: "_blank",
        typeOfLink: "href",
      },
      {
        icon: "mdi-book-multiple",
        text: "Rules",
        link: "/rules",
        typeOfLink: "to",
      },
      {
        icon: "mdi-account-group",
        text: "Groups",
        link: "/groups",
        typeOfLink: "to",
      },
    ]);

    async function appendAdminMenuItems() {
      if ((await getUserRole()) !== "WORKBENCH_ADMIN") return;

      menuItems.value.push({
        icon: "mdi-account-multiple",
        text: "Users",
        link: "/users",
        typeOfLink: "to",
      });
      menuItems.value.push({
        icon: "mdi-tune",
        text: "Settings",
        link: "/settings",
        typeOfLink: "to",
      });
    }

    onMounted(async () => {
      await appendAdminMenuItems();
    });

    /**
     * Deletes the session token and redirects to the login page.
     */
    const logout = async (): Promise<void> => {
      localStorage.removeItem(API_TOKEN_NAME);
      delete Axios.defaults.headers.common["Authorization"];
      setLoggedIn(false);
      setUserRole("");
      useLoginState().setTokenVerified(false);
      useKeycloak().logout();
    };

    return {
      logout,
      swaggerUrl,
      menuItems,
    };
  },
});
</script>
<style scoped lang="scss"></style>
