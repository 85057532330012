import Axios from "axios";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const datasetUrl = `${baseUrl}/datasets`;

type downloadDatasetPath =
  operations["downloadDatasetExport"]["parameters"]["path"];
type exportDataResponse =
  operations["exportDataset"]["responses"]["200"]["content"]["application/hal+json"];

const downloadDataset = async (
  path: downloadDatasetPath
): Promise<exportDataResponse | undefined> => {
  const { id, job_id } = path;

  const url = `${datasetUrl}/${id}/download/${job_id}`;

  const res = await Axios.get(url, { responseType: "blob" });

  const contentDisposition = res.headers['content-disposition'];
  const filename = contentDisposition.split('filename=')[1]; 

  const blob = new Blob([res.data], { type: "application/octet-stream" });
  const link = document.createElement("a");

  link.href = window.URL.createObjectURL(blob);
  link.download = `${filename}`;
  link.click();

  return res.data || undefined;
};

export { downloadDataset };
