var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"annotations-panel-actions d-flex justify-center mt-2"},[_c('hover-buttons-menu',{attrs:{"loading":_vm.repeatOverTableLoading,"tooltip":"Repeat over table","icon-code":"mdi-repeat","disabled":_vm.isUserPermissionReadOnly(),"bottom":"","sub-actions":[
      {
        icon: 'mdi-repeat-off',
        tooltip: 'Repeat over table and delete others',
        name: 'repeat-and-delete',
      },
      {
        icon: 'mdi-repeat',
        tooltip: 'Repeat over table',
        name: 'repeat',
      },
    ]},on:{"click":_vm.startRepeatOverTableLoading,"click:repeat":_vm.handleRepeatOverTableClick,"click:repeat-and-delete":_vm.handleRepeatOverTableAndDelete}}),_c('action-button',{attrs:{"loading":_vm.saveRuleLoading,"bottom":"","icon":"","tooltip":"Save rule","iconCode":"mdi-book-plus","disabled":_vm.isUserPermissionReadOnly()},on:{"click":_vm.handleSaveRuleClick}}),_c('hover-buttons-menu',{attrs:{"loading":_vm.verifyLoading,"disabled":_vm.annotationType === 'MANUAL_VERIFIED' || _vm.isUserPermissionReadOnly(),"tooltip":"Confirm annotation","icon-code":"mdi-check-bold","bottom":"","sub-actions":[
      {
        icon: 'mdi-delete-sweep',
        tooltip: 'Confirm annotation, save as Rule and delete others',
        name: 'confirm-save-as-rule-and-delete',
      },
      {
        icon: 'mdi-delete-restore',
        tooltip: 'Confirm and delete others',
        name: 'confirm-and-delete',
      },
      {
        icon: 'mdi-check-all',
        tooltip: 'Confirm annotation and save as Rule',
        name: 'confirm-and-save-as-rule',
      },
      {
        icon: 'mdi-check',
        tooltip: 'Confirm annotation',
        name: 'confirm',
      },
    ]},on:{"click":_vm.startVerifyLoading,"click:confirm":function($event){return _vm.handleConfirmClick(false)},"click:confirm-and-save-as-rule":function($event){return _vm.handleConfirmClick(true)},"click:confirm-and-delete":function($event){return _vm.handleConfirmAndDeleteClick(false)},"click:confirm-save-as-rule-and-delete":function($event){return _vm.handleConfirmAndDeleteClick(true)}}}),_c('action-button',{attrs:{"icon":"","bottom":"","loading":_vm.deleteLoading,"tooltip":"Delete annotation","iconCode":"mdi-delete","disabled":_vm.isUserPermissionReadOnly()},on:{"click":_vm.handleDeleteClick}}),_c('action-button',{attrs:{"icon":"","bottom":"","tooltip":"Edit annotation","iconCode":"mdi-pen","disabled":_vm.isUserPermissionReadOnly()},on:{"click":_vm.handleEditClick}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }