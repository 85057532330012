import { trim } from "lodash";
import { serverUrl } from "@/utils/serverUrl";

const baseUrl = serverUrl("base");

/**
 * Checks if a given endpoint string pattern matches an HTTP request response url.
 * The endpoint uses asterisks (*) between slashes as eager selectors.
 * @param {XMLHttpRequest} request
 * @param {string} targetEndpoint
 * @return {boolean}
 */
export const isTargetEndpoint = (
  targetEndpoint: string | string[],
  request: XMLHttpRequest
) => {
  if (typeof targetEndpoint == "string") {
    targetEndpoint = [targetEndpoint];
  }

  const requestedEndpoint = trim(request.responseURL.replace(baseUrl, ""), "/");

  return targetEndpoint.some((endpoint:string) => {
    const handlerUrlPattern = endpoint.replaceAll("/*", "/[^/]+");
    const handlerUrlRegex = new RegExp(trim(handlerUrlPattern, "/"));
    if (!requestedEndpoint.match(handlerUrlRegex)) return false;

    return true;
  });
};
