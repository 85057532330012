<template>
  <div class="annotations-panel-actions d-flex justify-center mt-2">
    <hover-buttons-menu
      :loading="repeatOverTableLoading"
      tooltip="Repeat over table"
      icon-code="mdi-repeat"
      @click="startRepeatOverTableLoading"
      @click:repeat="handleRepeatOverTableClick"
      @click:repeat-and-delete="handleRepeatOverTableAndDelete"
      :disabled="isUserPermissionReadOnly()"
      bottom
      :sub-actions="[
        {
          icon: 'mdi-repeat-off',
          tooltip: 'Repeat over table and delete others',
          name: 'repeat-and-delete',
        },
        {
          icon: 'mdi-repeat',
          tooltip: 'Repeat over table',
          name: 'repeat',
        },
      ]"
    />

    <action-button
      :loading="saveRuleLoading"
      bottom
      icon
      tooltip="Save rule"
      iconCode="mdi-book-plus"
      @click="handleSaveRuleClick"
      :disabled="isUserPermissionReadOnly()"
    />

    <hover-buttons-menu
      :loading="verifyLoading"
      :disabled="annotationType === 'MANUAL_VERIFIED' || isUserPermissionReadOnly()"
      tooltip="Confirm annotation"
      icon-code="mdi-check-bold"
      @click="startVerifyLoading"
      @click:confirm="handleConfirmClick(false)"
      @click:confirm-and-save-as-rule="handleConfirmClick(true)"
      @click:confirm-and-delete="handleConfirmAndDeleteClick(false)"
      @click:confirm-save-as-rule-and-delete="handleConfirmAndDeleteClick(true)"
      bottom
      :sub-actions="[
        {
          icon: 'mdi-delete-sweep',
          tooltip: 'Confirm annotation, save as Rule and delete others',
          name: 'confirm-save-as-rule-and-delete',
        },
        {
          icon: 'mdi-delete-restore',
          tooltip: 'Confirm and delete others',
          name: 'confirm-and-delete',
        },
        {
          icon: 'mdi-check-all',
          tooltip: 'Confirm annotation and save as Rule',
          name: 'confirm-and-save-as-rule',
        },
        {
          icon: 'mdi-check',
          tooltip: 'Confirm annotation',
          name: 'confirm',
        },
      ]"
    />

    <action-button
      icon
      bottom
      :loading="deleteLoading"
      @click="handleDeleteClick"
      tooltip="Delete annotation"
      iconCode="mdi-delete"
      :disabled="isUserPermissionReadOnly()"
    />

    <action-button
      icon
      bottom
      @click="handleEditClick"
      tooltip="Edit annotation"
      iconCode="mdi-pen"
      :disabled="isUserPermissionReadOnly()"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, Ref, ref, toRef } from "vue";
import {
  useAppDialog,
  useDatasetTable,
  useNotifications,
  useObservers,
  useSelection,
  useToolboxPanels,
} from "@/compositions";
import {
  deleteAnnotationById,
  propagateAndDeleteAnnotation,
  propagateAnnotation,
  updateAnnotationById,
  verifyAnnotationAndDeleteById,
  verifyAnnotationById,
} from "@/api";

import { components } from "@/ts/interfaces/ApiSchemas";

import ActionButton from "@/components/buttons/ActionButton.vue";
import HoverButtonsMenu from "@/components/buttons/HoverButtonsMenu.vue";
import EditAnnotationDialogue from "@/components/dialogs/EditAnnotationDialogue.vue";
import { AppDialogAction } from "@/ts/types";
import { AnnotationPanelItem } from "@/ts/interfaces";
import { 
  MSG_REPEAT_ANNOTATION_SUCCESS, 
  MSG_REPEAT_AND_DELETE_OTHER_SUCCESS,
  MSG_UPDATE_ANNOTATION_SUCCESS,
  MSG_DELETE_ANNOTATION_SUCCESS } from "@/globals";

export default defineComponent({
  name: "AnnotationsPanelActions",
  components: { ActionButton, HoverButtonsMenu },
  props: {
    panelData: {
      type: Object,
      required: true,
    },
    annotation: {
      type: Object,
      required: true,
    },
    annotationValue: {
      type: String,
      required: true,
    },
    annotationId: {
      type: Number,
      required: true,
    },
    primaryId: {
      type: String,
      required: true,
    },
    annotationType: {
      type: String,
      required: true,
    },
    annotationVocab: {
      type: String,
      required: true,
    },
    allAnnotationIds: {
      type: Array,
      default: () => [],
    },
    userPermission: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const annotation =
      props.annotation as unknown as components["schemas"]["Annotation"];
    const annotationId = toRef(props, "annotationId") as Ref<number>;
    const primaryId = toRef(props, "primaryId") as Ref<string>;
    const annotationValue = toRef(props, "annotationValue") as Ref<string>;
    const annotationVocab = toRef(props, "annotationVocab") as Ref<string>;
    const panelData = props.panelData as AnnotationPanelItem;

    const showAnnotateSubActions: Ref<boolean> = ref(false);
    const verifyLoading: Ref<boolean> = ref(false);
    const deleteLoading: Ref<boolean> = ref(false);
    const repeatOverTableLoading: Ref<boolean> = ref(false);
    const saveRuleLoading: Ref<boolean> = ref(false);

    /**
     * On any of the verify actions button click it will set verifyLoading to true.
     */
    const startVerifyLoading = () => {
      verifyLoading.value = true;
    };

    /**
     * On any of the verify actions button click it will set repeatOverTableLoading to true.
     */
    const startRepeatOverTableLoading = () => {
      repeatOverTableLoading.value = true;
    };
    /**
     * Sends a request to verify the current annotation.
     * @return {Promise<void>}
     */
    const handleConfirmClick = async (saveAsRuleFlag: boolean) => {
      const selection = useSelection().getSelection.value;
      const dataset_id = selection?.datasetId as number;
      const value_id = selection?.data.selectedCell?.id as number;
      const annotation_id = annotationId.value;

      await verifyAnnotationById({ id:dataset_id, value_id, annotation_id }, saveAsRuleFlag);

      verifyLoading.value = false;
      useNotifications().pushSuccessNotification(MSG_UPDATE_ANNOTATION_SUCCESS);
      
      //Reset panel data
      return await useObservers().notifyObservers("ANNOTATION_VERIFIED", {
        datasetId: dataset_id,
        valueId: value_id,
        annotationId,
      });
    };

    /**
     * Sends requests to delete all annotations but the current one,
     * and deletes the others.
     * @return {Promise<void>}
     */
    const handleConfirmAndDeleteClick = async (saveAsRuleFlag: boolean) => {
      const selection = useSelection().getSelection.value;

      if (!selection?.data.selectedCell?.label) return;

      const dataset_id = selection.datasetId as number;
      const value_id = selection?.data.selectedCell?.id as number;
      const annotation_id = annotationId.value;

      await verifyAnnotationAndDeleteById({
        id: dataset_id,
        value_id,
        annotation_id,
      }, saveAsRuleFlag);

      verifyLoading.value = false;
      useSelection().refreshSelection();
      useNotifications().pushSuccessNotification(MSG_UPDATE_ANNOTATION_SUCCESS);
    };

    /**
     * Handles delete click by requesting the annotation deletion.
     * @return {Promise<void>}
     */
    const handleDeleteClick = async () => {
      deleteLoading.value = true;

      const selection = useSelection().getSelection.value;
      const dataset_id = selection?.datasetId as number;
      const value_id = selection?.data.selectedCell?.id as number;
      const annotation_id = annotationId.value;

      await deleteAnnotationById({ id: dataset_id, value_id, annotation_id });

      useToolboxPanels().removeAnnotationById(value_id, annotation_id);

      await useDatasetTable().loadAnnotationSummaries();

      deleteLoading.value = false;

      useSelection().refreshSelection();
      useNotifications().pushSuccessNotification(MSG_DELETE_ANNOTATION_SUCCESS);
    };

    /**
     * Handler for the Edit annotation dialogue confirm button: Save the updated annotation.
     * @return {Promise<void>}
     * @param hit
     */
    const handleEditAnnotationConfirm = async (
      hit: components["schemas"]["AnnotationHit"]
    ) => {
      const selection = useSelection().getSelection.value;
      const dataset_id = selection?.datasetId as number;
      const annotation_id = annotationId.value;
      const value_id = selection?.data.selectedCell?.id as number;

      await updateAnnotationById({ id:dataset_id, annotation_id }, hit);

      useNotifications().pushSuccessNotification(MSG_UPDATE_ANNOTATION_SUCCESS);

      return await useObservers().notifyObservers("ANNOTATION_VERIFIED", {
        datasetId: dataset_id,
        valueId: value_id,
        annotationId,
      });
    };

    /**
     * Handler for the edit annotation button click.
     */
    const handleEditClick = () => {
      useAppDialog().setProps({
        component: EditAnnotationDialogue,
        args: {
          annotationHit: props.annotation.hit,
          value: annotationValue,
        },
      });

      useAppDialog().setDialog(
        true,
        `Edit annotation for "${annotationValue.value}"`,
        {
          showCancelButton: true,
          confirmButtonText: "Confirm edits",
          confirmButtonDisabled: true,
          confirmAction: handleEditAnnotationConfirm as AppDialogAction,
        }
      );
    };

    /**
     * Handles the repeat over table button's click and requests annotation propagation.
     * @return {Promise<void>}
     */
    const handleRepeatOverTableClick = async () => {
      const selection = useSelection().getSelection.value;
      const cellValue = panelData.label;
      const datasetId = selection?.datasetId as number;
      const attribute_id = selection?.data.selectedCell?.attributeId;

      repeatOverTableLoading.value = false;

      await propagateAnnotation(datasetId, {
        value: cellValue,
        attribute_id: attribute_id ? attribute_id : -1,
        vocab_name: annotationVocab.value,
        primary_id: primaryId.value,
      });

      await useDatasetTable().loadAnnotationSummaries();

      useNotifications().pushSuccessNotification(MSG_REPEAT_ANNOTATION_SUCCESS);
    };

    /**
     * Sends requests to delete all annotations but the current one,
     * also sends a request to verify the current annotation.
     * @return {Promise<void>}
     */
    const handleRepeatOverTableAndDelete = async () => {
      repeatOverTableLoading.value = true;

      const selection = useSelection().getSelection.value;
      const cellValue = panelData.label;
      const dataset_id = selection?.datasetId as number;
      const attribute_id = selection?.data.selectedCell?.attributeId;

      await propagateAndDeleteAnnotation(dataset_id, {
        value: cellValue,
        attribute_id: attribute_id ? attribute_id : -1,
        vocab_name: annotationVocab.value,
        primary_id: primaryId.value,
      });

      repeatOverTableLoading.value = false;
      await useDatasetTable().loadAnnotationSummaries();

      useNotifications().pushSuccessNotification(MSG_REPEAT_AND_DELETE_OTHER_SUCCESS);
    };

    /**
     * Handles the save rule button's click and makes a request to save the current rule.
     * @return {Promise<void>}
     */
    const handleSaveRuleClick = async () => {
      const annotationHit =
        annotation.hit as components["schemas"]["AnnotationHit"];
      emit("saveRule", annotationHit);
    };


    const isUserPermissionReadOnly = (() => { return props.userPermission == "RO" });

    return {
      showAnnotateSubActions,
      handleConfirmClick,
      handleConfirmAndDeleteClick,
      handleDeleteClick,
      handleEditClick,
      handleRepeatOverTableClick,
      handleRepeatOverTableAndDelete,
      handleSaveRuleClick,
      startVerifyLoading,
      startRepeatOverTableLoading,
      verifyLoading,
      deleteLoading,
      repeatOverTableLoading,
      saveRuleLoading,
      isUserPermissionReadOnly,
    };
  },
});
</script>

<style lang="scss">
.annotations-panel-actions {
  width: 100%;
}
</style>
