import { computed, Ref, ref } from "vue";

const enabled: Ref<boolean> = ref(false);
const collapsed: Ref<boolean> = ref(false);

/**
 * Composition method that implements core functionality of the MainToolbox component.
 * @link /src/client/components/layout/MainToolbox.vue
 * @return {{isToolboxShowing: ComputedRef<boolean>, toggleToolboxCollapsed: () => void, isToolboxCollapsed: ComputedRef<boolean>}}
 */
export const useToolbox = () => {
  const showToolbox = computed(() => {
    return enabled.value;
  });

  const toolboxCollapsed = computed(() => {
    return collapsed.value;
  });

  const toggleToolboxCollapsed = () => {
    collapsed.value = !collapsed.value;
  };

  const setCollapsed = () => {
    collapsed.value = true;
  };

  const getCollapsedAndEnabled = computed(() => {
    return collapsed.value && enabled.value;
  });

  const setExpanded = () => {
    collapsed.value = false;
  };

  const show = () => {
    enabled.value = true;
  };

  const hide = () => {
    enabled.value = false;
  };

  return {
    showToolbox,
    toolboxCollapsed,
    toggleToolboxCollapsed,
    setCollapsed,
    getCollapsedAndEnabled,
    setExpanded,
    show,
    hide,
  };
};
