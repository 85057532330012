<template>
  <div class="panel-content-container panel-attributes">
    <template v-if="panelData.length">
      <div
        v-for="annotation in sortedPanelData"
        :key="annotation.attributeId"
        class="panel-item"
      >
        <h3 v-if="annotation.value" class="panel-item__title subtitle-2">
          {{ annotation.value }}
        </h3>

        <v-autocomplete
          placeholder="Search vocab"
          dense
          :items="allActiveVocabs"
          item-text="name"
          item-value="id"
          @change="addVocab($event, annotation.attributeId)"
        >
          <template v-slot:selection><span></span></template>
          <template v-slot:item="data">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <div>
                  <v-list-item-action>
                    <v-icon small>mdi-plus-circle</v-icon>
                  </v-list-item-action>
                  <span
                    class="panel-item-chip__text"
                    v-bind="attrs"
                    v-on="on"
                    >{{ data.item.name }}</span
                  >
                </div>
              </template>
              {{ data.item.name }}
            </v-tooltip>
          </template>

          <template v-slot:append-outer>
            <v-menu offset-y :close-on-content-click="false" open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                  :content="activeFiltersCount(annotation.attributeId)"
                  :value="hasCustomFilters(annotation.attributeId)"
                  color="primary"
                  overlap
                >
                  <v-btn small icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-tune</v-icon>
                  </v-btn>
                </v-badge>
              </template>

              <v-list>
                <v-list-item>
                  <v-checkbox
                    label="Include ambiguous"
                    v-model="
                      termiteOptions.includeAmbiguous[annotation.attributeId]
                    "
                    @change="
                      updateAnnotationRunConfig(
                        annotation.attributeId,
                        'includeAmbiguous',
                        $event
                      )
                    "
                  />
                </v-list-item>
                <v-list-item>
                  <v-checkbox
                    label="Subsume"
                    v-model="termiteOptions.subsume[annotation.attributeId]"
                    @change="
                      updateAnnotationRunConfig(
                        annotation.attributeId,
                        'subsume',
                        $event
                      )
                    "
                  />
                </v-list-item>
                <v-list-item>
                  <v-checkbox
                    label="Exact match"
                    v-model="termiteOptions.exactMatch[annotation.attributeId]"
                    @change="
                      updateAnnotationRunConfig(
                        annotation.attributeId,
                        'exactMatch',
                        $event
                      )
                    "
                  />
                </v-list-item>
                <v-list-item>
                  <v-checkbox
                    label="Include cells containing numbers"
                    v-model="
                      termiteOptions.includeCellsThatAreNumbers[
                        annotation.attributeId
                      ]
                    "
                    @change="
                      updateAnnotationRunConfig(
                        annotation.attributeId,
                        'includeCellsThatAreNumbers',
                        $event
                      )
                    "
                  />
                </v-list-item>
                <v-list-item>
                  <v-checkbox
                    label="Include annotation rules"
                    v-model="
                      termiteOptions.includeAnnotationRulesAndStopwords[
                        annotation.attributeId
                      ]
                    "
                    @change="
                      updateAnnotationRunConfig(
                        annotation.attributeId,
                        'includeAnnotationRulesAndStopwords',
                        $event
                      )
                    "
                  />
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-autocomplete>

        <div
          v-if="annotation.labels.length"
          class="panel-item-labels panel-item__labels"
        >
          <template v-for="(label, index) in annotation.labels">
            <vocab-chip
              class="panel-item-labels__item panel-item-chip"
              :label="label.name"
              :key="`summaries-${annotation.selectionId}-${index}`"
              @click:close="
                removeVocabFromAttribute(annotation.attributeId, label.id)
              "
            >
              >
            </vocab-chip>
          </template>
        </div>

        <span
          v-else
          class="
            panel-annotations-item__labels
            panel-annotations-item__labels--not-found
          "
          >No vocab assigned</span
        >
      </div>
    </template>

    <div v-else class="panel-item">
      <span
        class="
          panel-annotations-item__labels
          panel-annotations-item__labels--not-selected
        "
        >Please select an attribute</span
      >
    </div>
  </div>
</template>

<script lang="ts">
import { sortBy } from "lodash";
import { computed, defineComponent, onMounted, PropType, watch } from "vue";
import { OntologySelectionPanelItem, TableSelection } from "@/ts/interfaces";
import {
  useAttributes,
  useOntologySelection,
  useSelection,
  useVocabs,
} from "@/compositions";
import { components } from "@/ts/interfaces/ApiSchemas";
import { TermiteConfigOption } from "@/ts/types/TermiteConfigOption";

import VocabChip from "@/components/common/VocabChip.vue";

// TODO when clicking on close button remove the ontology from the selector
export default defineComponent({
  components: {
    VocabChip,
  },
  name: "OntologySelectionPanel",
  props: {
    panelData: {
      type: Array as PropType<OntologySelectionPanelItem[]>,
    },
  },
  setup(props) {
    const selector = useSelection();

    /**
     * Sorts panel data by the sortOrder attribute of each element if available;
     * @type {ComputedRef<any[] | unknown[]>}
     */
    const sortedPanelData = computed(() => {
      if (!props.panelData || !props.panelData.length) return [];

      return sortBy(props.panelData, ["sortOrder"]);
    });

    const {
      termiteOptions,
      hasCustomFilters,
      initTermiteOptions,
      activeFiltersCount,
    } = useOntologySelection();

    const selection = useSelection().getSelection;

    const updateTermiteConfigOptions = (newSelection: TableSelection) => {
      if (!newSelection || newSelection.type !== "column") return;

      const attributeIds =
        newSelection.data.selectedColumns?.map((column) => {
          return column.id;
        }) || [];

      initTermiteOptions(attributeIds);
    };

    watch(selection, (newSelection) => {
      if (!newSelection) return;
      updateTermiteConfigOptions(newSelection);
    });

    onMounted(() => {
      if (!selection.value) return;
      updateTermiteConfigOptions(selection.value);
    });

    const updateAnnotationRunConfig = (
      attributeId: number,
      configName: TermiteConfigOption,
      configValue: boolean
    ) => {
      useAttributes().updateAttributeTermiteConfig(
        attributeId,
        configName,
        configValue,
        true
      );
    };

    /**
     * Gets the selected vocabs ids of a given attribute.
     * @param {number} attributeId
     * @return {number[]}
     */
    const getAttributeSelectedVocabs = (attributeId: number) => {
      if (!props.panelData) return [];

      let vocabIds: number[] = [];

      props.panelData.forEach((attribute) => {
        if (attributeId === attribute.attributeId && attribute.labels?.length) {
          vocabIds = attribute.labels.map((label) => label.id);
        }
      });

      return vocabIds;
    };

    /**
     * All non-empty active vocabs.
     * @type {components["schemas"]["Vocab"][]}
     */
    const allActiveVocabs = computed(() => {
      return useVocabs()
        .getActiveVocabs()
        .filter((vocab) => {
          return vocab.name;
        });
    });

    /**
     * Retrieves all non-selected active vocabs for a give attribute.
     * @param {Number} attributeId
     * @return {components["schemas"]["Vocab"][]}
     * @todo Make this reactive so that can be used instead of allAtiveVocabs computed reference to populate vocabs
     * autocomplete.
     */
    const getAttributeUnselectedVocabs = (
      attributeId: number
    ): components["schemas"]["Vocab"][] => {
      const attributeSelectedVocabs = getAttributeSelectedVocabs(attributeId);

      return useVocabs()
        .getActiveVocabs()
        .filter((vocab) => {
          return (
            vocab.name &&
            vocab.id &&
            !attributeSelectedVocabs.includes(vocab.id)
          );
        });
    };

    /**
     * Removes a vocab from a specified attribute configuration.
     * @param {number} attributeId
     * @param {targetVocabId} number
     */
    const removeVocabFromAttribute = (
      attributeId: number,
      targetVocabId: number
    ) => {
      const attribute = useAttributes().getAttributeById(attributeId);

      if (attribute?.termiteConfig?.vocabIds?.length) {
        const datasetId = selector.datasetId.value;
        attribute.termiteConfig.vocabIds =
          attribute.termiteConfig.vocabIds.filter((vocabId) => {
            return vocabId !== targetVocabId;
          });

        datasetId && useAttributes().updateAttribute(datasetId, attribute);
      }
    };

    /**
     * Adds a vocab to the termiteConfig property of all selected attributes.
     * @param {number} vocabId
     */
    const addVocab = (vocabId: number, attributeId: number) => {
      useAttributes().addVocabToAttribute(attributeId, vocabId, true);
    };

    return {
      removeVocabFromAttribute,
      addVocab,
      getAttributeUnselectedVocabs,
      updateAnnotationRunConfig,
      activeFiltersCount,
      allActiveVocabs,
      termiteOptions,
      sortedPanelData,
      hasCustomFilters,
    };
  },
});
</script>

<style scoped lang="scss">
.panel-attributes {
  .panel-item {
    .panel-item-labels {
      &__item {
        margin: 0 8px 8px 0;
      }
    }
  }

  ::v-deep {
    .v-select__selections {
      > span {
        display: none;
      }
    }

    .v-list-item__action {
      margin: 0;
    }
  }
}
</style>
