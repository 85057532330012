import { computed, ref, Ref } from "vue";
import useObservers from "@/compositions/useObservers";
import { AppContext } from "@/ts/interfaces";
import { DataTableHeader } from "vuetify";
import { components } from "@/ts/interfaces/ApiSchemas";

const context: Ref<AppContext | undefined> = ref();
const updating: Ref<boolean> = ref(false);
const currentDatasetId: Ref<number | undefined> = ref();
const currentAttributes: Ref<AttributeVuetifyColumn[]> = ref([]);

type AttributeColumn = components["schemas"]["Attribute"];
interface AttributeVuetifyColumn extends AttributeColumn, DataTableHeader {}

export const useContext = () => {
  const getContext = computed(() => {
    return context.value;
  });

  const getCurrentDatasetId = computed(() => {
    return currentDatasetId.value;
  });

  const getCurrentAttributes = computed(() => {
    return currentAttributes.value;
  });

  const setCurrentDatasetId = (datasetId: number) => {
    currentDatasetId.value = datasetId;
  };

  const setCurrentAttributes = (attributes: AttributeVuetifyColumn[]) => {
    currentAttributes.value = attributes;
  };

  /**
   * Gets the context after context has finished to update.
   * @return {Promise<AppContext>}
   */
  const getContextAsync = async (): Promise<AppContext> => {
    return new Promise((resolve, reject) => {
      if (!updating.value && context.value) return resolve(context.value);

      const waiting = setInterval(() => {
        if (!updating.value && getContext.value) {
          clearInterval(waiting);
          resolve(getContext.value);
          return;
        }
      }, 50);

      if (waiting > 999) {
        clearInterval(waiting);
        reject("Context taking too long to update.");
        return;
      }
    });
  };

  /**
   * Sets the updating status of the context.
   * @param {boolean} isUpdating
   */
  const setUpdating = (isUpdating: boolean) => {
    updating.value = isUpdating;
  };

  /**
   * Sets the context value;
   * @param {AppContext} updatedContext
   */
  const setContext = (updatedContext: AppContext) => {
    context.value = updatedContext;
    setUpdating(false);
  };

  const unsetContext = () => {
    context.value = undefined;
    setUpdating(false);
  };

  const buildContextObject = async (target: HTMLElement) => {
    const context: AppContext = { name: "", activeElement: target };

    switch (true) {
      case !!target.closest(".dataset-table"):
        context.name = "Dataset/Data/Table";
        break;
      case !!target.closest(".main-toolbox"):
        context.name = "MainToolbox";
        break;

      case !!target.closest(".main-sidebar"):
        context.name = "MainSidebar";
        break;

      case !!target.closest(".dataset-table-data-actions"):
        context.name = "datasetTableActionButtons";
        break;
      default:
        return;
    }

    return context;
  };

  const notifyObserversContextChanged = async (e: Event) => {
    useContext().setUpdating(true);

    const target = e.target as HTMLElement;

    const context = await buildContextObject(target);

    if (context) setContext(context);

    await useObservers().notifyObservers("CONTEXT_CHANGED", { context });

    useContext().setUpdating(false);
  };

  const initContext = () => {
    document.addEventListener(
      "focusin",
      (e) => {
        notifyObserversContextChanged(e);
      },
      false
    );

    document.addEventListener(
      "click",
      (e) => {
        notifyObserversContextChanged(e);
      },
      false
    );
  };

  /**
   * Updates the current context and notify observers.
   * @param {string} targetSelector
   * @param {string} contextName
   */
  const updateContext = (targetSelector: string, contextName: string) => {
    const activeElement = document.querySelector(targetSelector) as HTMLElement;

    if (!activeElement) {
      console.error("targetSelector must be an existing DOM element");
      return;
    }

    const context: AppContext = { activeElement, name: contextName };

    setContext(context);
    void useObservers().notifyObservers("CONTEXT_CHANGED", { context });
  };

  return {
    getContext,
    getCurrentDatasetId,
    getCurrentAttributes,
    setCurrentDatasetId,
    setCurrentAttributes,
    setContext,
    unsetContext,
    initContext,
    setUpdating,
    getContextAsync,
    updateContext,
  };
};
