import Axios from "axios";
import qs from "qs";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const annotationsUrl = `${baseUrl}/datasets`;

type query = operations["propagateAnnotations"]["parameters"]["query"];

const propagateAnnotation = async (datasetId: number, query: query) => {
  const queryString = qs.stringify(query, { arrayFormat: "comma" });
  const url = `${annotationsUrl}/${datasetId}/propagateAnnotation?${queryString}`;

  const res = await Axios.post(url);

  return res.data || [];
};

export { propagateAnnotation };
