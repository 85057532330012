import Axios from "axios";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const annotationsUrl = `${baseUrl}/datasets`;

type getVocabLookupResponse = Promise<
  operations["searchByLabelAndPrimaryId"]["responses"]["200"]["content"]["application/hal+json"]
>;

/**
 * Makes the request to the attributes lookup endpoint
 * @param {datasetId} The id of the dataset to get the attributes from
 * @param {keyword} Search term used for the query
 * @return {Promise<getVocabLookupResponse>}
 */
const getVocabLookup = async (
  datasetId: number,
  keyword: string
): Promise<getVocabLookupResponse> => {
  const url = `${annotationsUrl}/${datasetId}/annotated/vocabLookup?keyword=${keyword}`;

  const response = await Axios.get(url);

  return response.data || [];
};

export { getVocabLookup };
