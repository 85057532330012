import { computed, ref, Ref } from "vue";
import { components } from "@/ts/interfaces/ApiSchemas";
import { getCurrentUserRole, getUserInfo } from "@/api";

const currentUser: Ref<components["schemas"]["EntityModelUser"] | undefined> =
  ref();
const isLoggedIn: Ref<boolean> = ref(false);
const tokenVerificationComplete: Ref<boolean> = ref(false);
const userAuthorisationComplete: Ref<boolean> = ref(false);
const userRole: Ref<"WORKBENCH_ADMIN" | "WORKBENCH_USER" | ""> = ref("");

export const useUser = () => {
  const setCurrentUser = (user: components["schemas"]["User"]) => {
    currentUser.value = user;
  };

  const loadCurrentUser = async () => {
    const user = await getUserInfo();
    user && setCurrentUser(user);
  };

  const setLoggedIn = (loggedIn: boolean): void => {
    isLoggedIn.value = loggedIn;
  };

  const setUserRole = (
    newRole: "WORKBENCH_ADMIN" | "WORKBENCH_USER" | ""
  ): void => {
    userRole.value = newRole;
  };

  const setTokenVerificationComplete = (): void => {
    tokenVerificationComplete.value = true;
  };

  const setUserAuthorisationComplete = (): void => {
    userAuthorisationComplete.value = true;
  };

  const getUserRole = async (): Promise<
    "WORKBENCH_ADMIN" | "WORKBENCH_USER" | ""
  > => {
    if (userRole.value === "") {
      userRole.value = await getCurrentUserRole();
    }

    return userRole.value;
  };

  const getCurrentUser = computed(() => {
    return currentUser.value;
  });

  const getLoginState = computed((): "logged-in" | "logged-out" => {
    return isLoggedIn.value ? "logged-in" : "logged-out";
  });

  const getTokenVerificationState = computed((): "completed" | "pending" => {
    return tokenVerificationComplete.value ? "completed" : "pending";
  });

  const getUserAuthorisationState = computed((): "completed" | "pending" => {
    return userAuthorisationComplete.value ? "completed" : "pending";
  });

  return {
    loadCurrentUser,
    setCurrentUser,
    setLoggedIn,
    setUserRole,
    setTokenVerificationComplete,
    setUserAuthorisationComplete,
    getCurrentUser,
    getLoginState,
    getTokenVerificationState,
    getUserAuthorisationState,
    getUserRole,
  };
};
