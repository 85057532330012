export const API_TOKEN_NAME = "sbwb-token";
export const AUTH_HEADER_PREFIX = "Bearer ";

//account
export const MSG_PASSWORD_MISSING = "You must enter a password";
export const MSG_USERNAME_MISSING = "You must enter a username";
export const MSG_USERNAME_AND_PASSWORD_MISSING =
  "You must enter username and password";
export const MSG_LOGIN_SUCCESSFUL = "You have successfully logged in";
export const MSG_LOGIN_UNSUCCESSFUL = "Incorrect user name or password";
export const MSG_REGISTER_SUCCESSFUL = "Account created successfully";

export const MSG_INTERNAL_SERVER_ERROR = "Unable to process request at this time";
export const MSG_FORBIDDEN_ERROR = "You have insufficient permissions to perform this action";
export const MSG_REQUEST_TIMEOUT_ERROR = "Unable to process request, request is timed out";
export const MSG_REPEAT_ANNOTATION_SUCCESS = "Annotation repeated over the table successfully"
export const MSG_REPEAT_AND_DELETE_OTHER_SUCCESS = "Annotation repeated over the table and deleted other annotations successfully";
export const MSG_UPDATE_ANNOTATION_SUCCESS = "Annotation(s) updated";
export const MSG_DELETE_ANNOTATION_SUCCESS = "Annotation(s) deleted";
export const MSG_ADDED_ANNOTATION_SUCCESS = "Annotation(s) added successfully";
export const MSG_DELETE_MAPPING_SUCCESS = "Mapping(s) deleted";
export const MSG_UPDATE_MAPPING_SUCCESS = "Mapping(s) updated successfully";