import Axios from "axios";
import { Dataset } from "@/ts/interfaces";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");

type DatasetResponse = Promise<Dataset[] | []>;

/**
 * Get a datasets for a specified dataset name.
 * @param {number} datasetId
 * @returns {DatasetResponse}
 */

const findDatasetsByName = async (searchTerm: string): DatasetResponse => {
  const url = `${baseUrl}/datasets/filterByName`;
  const response = await Axios.get(url, {
    params: { filter_by_name: searchTerm },
  });
  if (!("_embedded" in response.data)) return [];
  return response.data._embedded.datasets;
};

export { findDatasetsByName };
