import Axios from "axios";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");
const groupsURL = `${baseUrl}/groups`;

type changeUserPermissionResponse =
  operations["editGroupMemberPermission"]["responses"]["200"];

/**
 * Gets groups from the group api
 */
const changeUserPermission = async (
  groupId: number,
  userId: number,
  permission: string
): Promise<boolean> => {
  const url = `${groupsURL}/${groupId}/members/${userId}`;

  const response = await Axios.patch(url, { groupRole: permission });

  return (
    response.status === 200 || Promise.reject("Cannot change user permission")
  );
};

export { changeUserPermission };
