import Axios from "axios";
import qs from "qs";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const annotationsUrl = `${baseUrl}/datasets`;

type AnnotationSummariesParams =
  operations["getAnnotationSummaries"]["parameters"]["query"];
type AnnotationSummariesResponse = Promise<
  operations["getAnnotationSummaries"]["responses"]["200"]["content"]["application/hal+json"]
>;

/**
 * Retrieves annotations for a single value.
 * @param {AnnotationsForValueRequest} pathParams
 * @return {AnnotationsForValueResponse}
 */
const getAnnotationSummaries = async (
  datasetId: number,
  queryParams: AnnotationSummariesParams
): AnnotationSummariesResponse => {
  const queryString = qs.stringify(queryParams, {
    arrayFormat: "repeat",
    encode: false,
  });

  const url = `${annotationsUrl}/${datasetId}/annotatedByRowId?${queryString}`;

  const response = await Axios.post(url);

  return response.data || [];
};

export { getAnnotationSummaries };
