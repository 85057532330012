import { useNotifications } from "@/compositions";
import { ContextualTypes, Observer, NotificationStatus } from "@/ts/types";
import { MessageEventEncoded } from "@/ts/interfaces";

/**
 * Registers an action to show show a notification when a dataset upload has started and when is ended.
 * @param {Record<string, unknown>} args
 * @param {MessageEventEncoded} args.event
 * @return {boolean}
 */
export const displayUploadNotifications: Observer = (args) => {
  const event = args.event as MessageEventEncoded;
  const { pushNotification } = useNotifications();

  const datasetStatus: NotificationStatus | undefined =
    event.data.datasetStatus || undefined;

  if (!datasetStatus) return true;

  if (["DATA_IMPORT_STARTED", "DATA_IMPORT_COMPLETE", "DATA_IMPORT_ERROR"].includes(datasetStatus)) {
    let notificationType: ContextualTypes = "success";
    if (datasetStatus === "DATA_IMPORT_STARTED")  notificationType = "info";
    if (datasetStatus === "DATA_IMPORT_ERROR") notificationType = "error";

    pushNotification({
      title: event.data.title,
      text: event.data.message || "",
      type: notificationType,
      timeout: true,
    });
  }

  return true;
};

export default displayUploadNotifications;
