import { components } from "@/ts/interfaces/ApiSchemas";
import { AttributeVuetifyColumn } from "@/ts/interfaces";
import useObservers from "@/compositions/useObservers";

interface VuetifyDatasetAttributeHeaderLookupTable {
  [key: number]: AttributeVuetifyColumn[];
}

interface DatasetAttributeHeaderLookupTable {
  [key: number]: AttributeColumn[];
}

type AttributeColumn = components["schemas"]["Attribute"];

export const useCachedAttributes = () => {
  /**
   * Gets the hidden attribute columns from the local storage
   * @param datasetId The Dataset id that the hidden attributes have been set too
   */
  function getHiddenAttributes(
    datasetId: number
  ): AttributeVuetifyColumn[] | AttributeColumn[] {
    const localStoredAttributes =
      window.localStorage.getItem("hiddenAttributes");

    if (!localStoredAttributes) return [];

    const storedAttributes:
      | DatasetAttributeHeaderLookupTable
      | VuetifyDatasetAttributeHeaderLookupTable
      | undefined = JSON.parse(localStoredAttributes);

    if (storedAttributes && datasetId in storedAttributes) {
      return storedAttributes[datasetId];
    } else {
      return [];
    }
  }

  /**
   * Sets the hidden attributes into local storage under the key of hiddenAttributes
   * @param attributes Attribute columns that will be hidden
   * @param datasetId The dataset id that will be used to look up the hidden attribute local storage
   */
  async function setHiddenAttributes(
    attributes: AttributeColumn[],
    datasetId: number
  ) {
    const storedAttributes = window.localStorage.getItem("hiddenAttributes");
    let attributeObj: DatasetAttributeHeaderLookupTable | undefined;

    if (storedAttributes) {
      attributeObj = JSON.parse(storedAttributes);
    } else {
      attributeObj = {};
    }

    if (typeof attributeObj !== "undefined") {
      attributeObj[datasetId] = attributes;
    } else {
      console.error(`Could not parse attribute json ${attributeObj}`);
    }

    const jsonifiedAttributes = JSON.stringify(attributeObj);
    window.localStorage.setItem("hiddenAttributes", jsonifiedAttributes);
    await useObservers().notifyObservers("HIDDEN_ATTRIBUTES_CHANGED");
  }

  return {
    getHiddenAttributes,
    setHiddenAttributes,
  };
};
