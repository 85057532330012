import Axios from "axios";
import qs from "qs";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const vocabsUrl = `${baseUrl}/vocabs/active`;

type VocabResponse = Promise<
  operations["getActive"]["responses"]["200"]["content"]["application/hal+json"]
>;

/**
 * Retrieves all the active vocabs from the api.
 * @param excludeEmpty
 * @returns Promise<(VocabResponse | undefined)>
 */
const getAllActiveVocabs = async (excludeEmpty?: boolean): Promise<VocabResponse | undefined> => {
  const queryString = qs.stringify({ excludeEmpty: excludeEmpty }, {
    arrayFormat: "comma",
    encode: false,
  });
  const response = await Axios.get(`${vocabsUrl}?${queryString}`);

  return response.data || undefined;
};

export { getAllActiveVocabs };
