import { isArray, isNumber } from "lodash";

/**
 * Checks if an handler/interceptor's target statuses match the current error status.
 * @param {number|number[]} targetStatus
 * @param {number|undefined} status
 * @return {boolean}
 */
export const isTargetStatus = (
  targetStatus: number | number[],
  status: number | undefined
) => {
  if (!status) return false;

  if (isNumber(targetStatus) && status !== targetStatus) return false;
  if (isArray(targetStatus) && !targetStatus.includes(status)) return false;

  return true;
};
