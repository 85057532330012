import Axios from "axios";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");

/**
 * Split an attribute/column into multiple attributes/columns
 * @param {number} datasetId
 * @param {number} attributeId
 * @param {("LEFT" | "RIGHT")}direction
 * @return {Promise<void>}
 */
const moveAttribute = async (
  datasetId: number,
  attributeId: number,
  direction: "LEFT" | "RIGHT"
): Promise<boolean> => {
  const endpoint = `${baseUrl}/datasets/${datasetId}/attributes/${attributeId}/move?direction=${direction}`;
  const response = await Axios.put(endpoint);
  return response.status == 200;
};

export { moveAttribute };
