import * as actionButtons from "@/compositions/actionButtons";

/**
 * Centralised composition to handle button actions.
 * @return {{performAction: (actionName: string, e: Event, args?: Record<string, unknown>) => void}}
 */
export const useActionButtons = () => {
  const actions = actionButtons as unknown as Record<
    string,
    (args: Record<string, unknown>) => void
  >;

  /**
   * Displays an error log.
   * @param {string} actionName
   */
  const logError = (actionName: string) => {
    console.error(
      `Action "${actionName}" is not an available action. To be able to use it add a a property named "${actionName}" to the actions object`
    );
  };

  /**
   * Given an action name calls a function with that name declared in the actionButtons directory.
   * @param {string} actionName
   * @param {Event} e
   * @param {Record<string, unknown>} args
   */
  const performAction = (
    actionName: string,
    e: Event,
    args: Record<string, unknown> = {}
  ) => {
    if (!actions[actionName]) return logError(actionName);

    actions[actionName](args);
  };

  return { performAction };
};
