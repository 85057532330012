import Axios, { AxiosResponse } from "axios";
import { API_TOKEN_NAME } from "@/globals";
import { useObservers } from "@/compositions";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("base");
const loginUrl = `${baseUrl}/login`;

/**
 * Pings the login API and, if successful sets the session token and returns true,
 * otherwise returns false.
 * @param {string} username
 * @param {string} password
 * @returns {Promise<boolean>}
 */
export const login = async (
  username: string,
  password: string
): Promise<boolean> => {
  try {
    const response: AxiosResponse = await Axios.post(loginUrl, {
      username,
      password,
    });

    if (response.headers && response.headers.authorization) {
      localStorage.setItem(API_TOKEN_NAME, response.headers.authorization);
      Axios.defaults.headers.common["Authorization"] =
        response.headers.authorization;

      const { notifyObservers } = useObservers();

      await notifyObservers("LOGGED_IN", {
        token: response.headers.authorization,
      });

      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
};
