import Axios from "axios";
import { operations } from "@/ts/interfaces/ApiSchemas";
import { serverUrl } from "@/utils";

const baseUrl: string = serverUrl("api");
const mappingUrl = `${baseUrl}/mappings`;

type downloadMappingPath =
  operations["downloadMappingExport"]["parameters"]["path"];
type downloadMappingResponse =
  operations["downloadMappingExport"]["responses"]["200"]["content"]["application/octet-stream"];

const downloadMapping = async (
  path: downloadMappingPath,
  fileName: string
): Promise<downloadMappingResponse | undefined> => {
  const { id, job_id } = path;

  const url = `${mappingUrl}/${id}/download/${job_id}`;

  const res = await Axios.get(url, { responseType: "blob" });

  const blob = new Blob([res.data], { type: "application/octet-stream" });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = `${fileName}.csv`;
  link.click();

  return res.data || undefined;
};

export { downloadMapping };
