import { Observer } from "@/ts/types";
import { useDatasetTable } from "@/compositions";

/**
 * Registers an action to show show a notification when a dataset upload has started and when is ended.
 * @param {Record<string, unknown>} args
 * @return {Promise<boolean>}
 */
export const updateAnnotatedMarkers: Observer = async (args) => {
  await useDatasetTable().loadAnnotationSummaries();

  return true;
};

export default updateAnnotatedMarkers;
