import Axios from "axios";
import { DatasetTable } from "@/ts/interfaces";
import { operations } from "@/ts/interfaces/ApiSchemas";
import qs from "qs";
import { serverUrl } from "@/utils";

const baseUrl = serverUrl("api");

type TableRequest = operations["findAllById"]["parameters"]["query"];

type TableResponse = {
  page?: {
    number: number;
    size: number;
    totalElements: number;
    totalPages: number;
  };
  tableRows: Array<DatasetTable | null>;
};

/**
 * Retrieves datasets from the API dataset controller.
 * @param datasetId
 * @param parameters
 */
const getTable = async (
  datasetId: number,
  parameters: TableRequest | null
): Promise<TableResponse> => {
  parameters = parameters || {};

  const queryString = qs.stringify(parameters, { arrayFormat: "repeat" });

  const url = `${baseUrl}/datasets/${datasetId}/table?${queryString}`;
  const response = await Axios.get(url);

  if (
    !response.data._embedded ||
    !response.data._embedded.tableRows ||
    !response.data.page
  )
    return { tableRows: [] };

  return {
    tableRows: response.data._embedded.tableRows,
    page: response.data.page,
  };
};

export { getTable };
