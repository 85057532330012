import { components } from "@/ts/interfaces/ApiSchemas";

const useAnnotationStyles = () => {
  type AnnotationType = components["schemas"]["Annotation"]["type"];

  /**
   * Used for identifying the icon needed for the annotation method
   * @param annotationType - The annotation type that needs the corresponding icon
   */
  function decorateAnnotationType(annotationType: AnnotationType): string {
    let icon: string;
    switch (annotationType) {
      case "AUTOMATED":
        icon = "mdi-robot";
        break;
      case "INFERRED_FROM_RULE":
        icon = "mdi-book-plus";
        break;
      case "MANUAL_VERIFIED":
        icon = "mdi-account-check";
        break;
      default:
        icon = "";
        break;
    }
    return icon;
  }

  /**
   * Used for identifying the colour needed for the annotation method
   * @param annotationType - The annotation type that needs the corresponding icon
   * @returns the color used for the cell
   */
  function changeAnnotationTypeColour(annotationType: AnnotationType): string {
    let colour: string;
    switch (annotationType) {
      case "AUTOMATED":
        colour = "teal";
        break;
      case "INFERRED_FROM_RULE":
        colour = "green accent-2";
        break;
      case "MANUAL_VERIFIED":
        colour = "green darken-2";
        break;
      default:
        colour = "";
        break;
    }
    return colour;
  }

  return { decorateAnnotationType, changeAnnotationTypeColour };
};

export { useAnnotationStyles };
