import { Observer } from "@/ts/types";
import { useJobsQueue } from "@/compositions";
import { MessageEventEncoded } from "@/ts/interfaces";

/**
 * Changes the status of a specific job to complete in the queuedJobs array.
 * @see /client/src/components/common/JobsQueue
 * @param {Record<string, unknown>} args
 * @param {MessageEventEncoded} args.event
 * @return {boolean}
 */
export const markQueuedJobComplete: Observer = (args) => {
  const event = args.event as MessageEventEncoded;
  const { updateJobStatus } = useJobsQueue();

  const jobId = event.data.job && event.data.job.jobId;

  if (jobId) updateJobStatus(jobId, "complete");

  return true;
};
